import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Api from './Services/ApiCall';
import toast, { Toaster } from 'react-hot-toast';
import JobseekerBase from './Jobseeker/JobseekerBase';
import { Button, Modal } from 'react-bootstrap';

export default function Jobseekerdashboard() {
  const [walletAmount, setWalletAmount] = useState("Loading...");
  const [district, setDistrict] = useState("");
  const [joblist, setJoblist] = useState([]);
  const [applyjobcount, setApplyjobCount] = useState("Loading...");
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    openPopup();
    fetchWalletAmount();
    fetchJobList();
    bindgrid();
  }, []);

  async function fetchWalletAmount() {
    try {
      const Id = sessionStorage.getItem("MUserID");
      const Param = { Mstjobseekerid: Id, AccessType: 'Jobseeker' };
      const url = "/JobSeekerTable/BindJobSeekerTable";

      const _response = await Api.BindGrid(Param, url);
      setWalletAmount(_response[0]?.Recharge_amount || "0");
      setDistrict(_response[0]?.District || "");
      sessionStorage.setItem("district", _response[0]?.District);
    } catch (error) {
      toast.error("Failed to fetch wallet amount");
      setWalletAmount("0");
    }
  }

  async function fetchJobList() {
    try {
      const url = "/DropDown/GetJobs_With_Count";
      const _response = await Api.NoDirect(url);
      const jobData = Array.isArray(_response.values) ? _response.values : JSON.parse(_response.values || '[]');
      setJoblist(jobData);
    } catch (error) {
      toast.error("Failed to fetch job list");
    }
  }

  async function bindgrid() {
    var id = sessionStorage.getItem('MUserID');
    var url = "/DropDown/GetApplyJobs?id=" + id;
    try {
      let _response1 = await Api.BindDropdown(url);
      setApplyjobCount(_response1.length);
    } catch (error) {
      toast.error("Failed to fetch applied job count");
    }
  }
  const openPopup = () => {
    if (sessionStorage.getItem("Popup") != "Opened") {
      setShowPopup(true);
    }
  };

  const closePopup = () => {
    sessionStorage.setItem("Popup", "Opened");
    setShowPopup(false);
  };
  const GetJobData = (country) => {
    navigate('/AdvanceJobSearch', {
      state: { Jobname: country }
    });
  }
  const Appliedjobs = () => {
    navigate('/Appliedjobs');
  }
  const Jobseekerledgerreport = () => {
    navigate('/Jobseekerledgerreport');
  }

  return (
    <>
      <JobseekerBase />
      <div id="main" className="main">
        <Toaster toastOptions={{ position: "top-right" }} />
        <h1>Dashboard</h1>
        <div className="row">
          <div className="col-xl-4" style={{cursor:'pointer'}} onClick={() => Jobseekerledgerreport()}>
            <div className="card">
              <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">
                <h2>Your Wallet Amount is</h2>
                <h3>RS:{walletAmount}</h3>
              </div>
            </div>
          </div>
          <div className="col-xl-4" style={{cursor:'pointer'}} onClick={() => Appliedjobs()}>
            <div className="card">
              <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">
                <h2>No of Applied Jobs Count</h2>
                <h3>{applyjobcount}</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          {joblist.length === 0 ? (
            <div className="col-xl-12 text-center">
              <p>No job data available</p>
            </div>
          ) : (
            joblist.map((i, index) => (
              <div className="col-xl-4" style={{cursor:'pointer'}} key={index} onClick={() => GetJobData(i.Country)}>
                <div className="card fixed-card">
                  <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">
                    <h2>{i.Country || "Unknown Job"} Count</h2>
                    <h3>{i.JobCount || 0}</h3>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>

        {showPopup && (
          <Modal show={true} onHide={closePopup} dialogClassName="custom-modal">
            <Modal.Header closeButton>
              <Modal.Title style={{ textAlign: 'center' }}>Announcement</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row g-3">
                <h6>Please Note: Refer your friend to get a bonus.</h6>
              </div>
            </Modal.Body>
          </Modal>
        )}


      </div>
    </>
  );
}
