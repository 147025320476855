import React,{useState,useEffect} from 'react'
import Base from '../../Base';
import Api from '../../Services/ApiCall';
import { Link ,Navigate,useNavigate,useLocation} from "react-router-dom";
import toast,{Toaster} from 'react-hot-toast';
import Swal from 'sweetalert2';

export default function Employmenttypeform() {

    const[employmenttype,setEmploymenttype]=useState("")
    const navigate= useNavigate();
 const location = useLocation();
    const { ID,Flag } = location.state;
    ////console.log('%%%', ID, Flag);

    useEffect(() => {
        if (ID != 0){
        Get(ID);
    }else { 
        //Get(0) 
    }
}, []);


   async function IUDOperation (flag,id){

      
       var Employmenttype = employmenttype;
      
       
 
       if(Employmenttype ==""){
           toast.error('Please enter Employment Type')
           return false;
       }
       var Param ={ 
        "mstemployeId": ID,
       "employeCode": "",
       "employmentType": Employmenttype,
       "active": "",
       "searchField": "",
       "createdBy": 0,
       "createdDate": "2022-12-02T08:33:20.974Z",
       "flag": Flag
    };
       var url = "/EmploymentType/IUDEmploymentType";
       let _response = await Api.IUD(Param,url);
      // //console.log(_response);
        showAlert(_response);
       setTimeout(()=>{
        navigate('/Employmenttypetable')
       },2000)
       
   }
   const showAlert = (_response) => {
    Swal.fire({
        title: "Success",
        text: _response,
        icon: "success",
        confirmButtonText: "OK",
    });
};
async function Get(id){
    var Param ={mstemployeId:id}
    var url = "/EmploymentType/BindEmploymentType";
    ////console.log("Param",Param,"URL",url)
    let _response = await Api.BindGrid(Param,url) 
  

    setEmploymenttype(_response[0].EmploymentType);
   

  

 }


  return (
    <div>
        <Toaster toastOptions={{ position: "top-right" }} />
         <Base />
          <div id="main" class="main">
              <div class="pagetitle">
                
                  <nav>
                      <ol class="breadcrumb">
                          <li class="breadcrumb-item"><Link to="/dash">Home</Link></li>
                          <li class="breadcrumb-item">Employment Type</li>
                         
                      </ol>
                  </nav>
              </div>
             
              <div class="card">
                  <div class="card-body">
                      <h5 class="card-title">Department Creation</h5>

              
                      <div class="row g-3">
                          {/* <div class="col-md-6">
                          <label for="departmentcode">Department Code</label>
                              <input type="text" class="form-control"  disabled={Flag == "D" || Flag == "null" ? true : false} placeholder="Department Code" value={departmentcode}  onChange={(e) => setDepcode(e.target.value)}/>
                          </div> */}
                          <div class="col-md-6">
                          <label for="employmenttype">Employment Type</label>
                              <input type="text" class="form-control" disabled={Flag == "D" || Flag == "null" ? true : false}  placeholder="Employment Type"value={employmenttype} onChange={(e) => setEmploymenttype(e.target.value)}/>
                          </div>
                          
                          <div class="text-center">
                              <button type="button" class="btn btn-primary" onClick={IUDOperation}>Submit</button>
                              <button type="reset" class="btn btn-info">
                              <Link to='/Employmenttypetable'>
                                Back
                                </Link>
                                </button>
                          </div>
                      </div>

                  </div>
              </div>

           
          </div>

    </div>
   
  )
}
