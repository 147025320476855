import React, { useState, useEffect } from "react";
import Base from '../Base'
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import Api from '../Services/ApiCall'
import { useTable, useSortBy, usePagination, useGlobalFilter } from "react-table";
import { Globalfilter } from "./Admin_form/Filter/Globalfilter";
import '../../Css/Pagination.css'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { Modal, Button } from 'react-bootstrap';


export default function Officetypecreation(any, i) {

    const navigate = useNavigate();
    const [rowData, setRowData] = useState([]);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [showPopup1, setShowPopup1] = useState(false);

    useEffect(() => {
        fetchDataAndSetState();
    }, []);

    const fetchDataAndSetState = async () => {
        try {
            var Param = { mstofficetypecreationid: 0 }
            var url = "/OfficeTypeCreation/BindOfficeTypeCreation";
            let _response1 = await Api.BindGrid(Param, url);
            setRowData(_response1);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const openPopup = (rowData) => {
        setSelectedRowData(rowData);
        Get(rowData.id)
        setShowPopup(true);
    };
    const openPopup1 = (rowData) => {
        setShowPopup(false);
        setShowPopup1(true);
    };

    const closePopup1 = () => {
        setSelectedRowData(null);
        setShowPopup1(false);
    };
    const closePopup = () => {
        setSelectedRowData(null);
        setShowPopup(false);
    };

    const onCellClicked = (params) => {
        openPopup({ id: params.data.Mstofficetypecreationid, code: params.data.Officetypecreationcode });
    };

    const columnDefs = [
        // { headerName: 'Id', field: 'Mstofficetypecreationid', filter: 'agNumberColumnFilter', enableFilter: true },
        { headerName: 'Code', field: 'Officetypecreationcode', filter: true, enableFilter: true },
        { headerName: 'Grade', field: 'SelectOfficeType', filter: true, enableFilter: true },
        { headerName: 'Location', field: 'Location', filter: true, enableFilter: true },
        { headerName: 'Contact Number', field: 'Contactnumber', filter: true, enableFilter: true },
        { headerName: 'Incharge Name', field: 'Name', filter: true, enableFilter: true },
    ];

    const [quickFilterText, setQuickFilterText] = useState("");

    const onQuickFilterChange = (event) => {
        setQuickFilterText(event.target.value);
    };



    async function IUD_Delete() {
        openPopup1();
    }


    const [Listemployee, setListemployee] = useState([]);
    const [ListemployeeGrade, setListemployeeGrade] = useState([]);
    const [ListemployeeCode, setListemployeeCode] = useState([]);
    const [ListemployeeDesignation, setListemployeeDesignation] = useState("");
    const [SelectOfficegrade, setSelectOfficegrade] = useState("");
    const [SelectOFficeEmpCode, setSelectOFficeEmpCode] = useState("");
    const [SelectOFficeDesignation, setSelectOFficeDesignation] = useState("");
    const [grade, setGrade] = useState("");
    const [autograde, setAutograde] = useState("");
    const [autograde1, setAutograde1] = useState("");
    const [OfficeLocation, setOfficeLocation] = useState("");
    const [selectOfficeGrade, setselectOfficeGrade] = useState("");
    const [OfficeGradeType, setOfficeGradeType] = useState("");
    const [phonenumber, setPhonenumber] = useState("");
    const [address, setAddress] = useState("");
    const [pincode, setPincode] = useState("");
    const [emailId, setEmailId] = useState("");
    const [empgrade, setEMPgrade] = useState("");
    const [designation, setDesignation] = useState("");
    const [selectedOfficelocation, setselectedOfficelocation] = useState("");
    const [offGrade, setoffGrade] = useState("");
    const [offGradeType, setOffGradeType] = useState("");
    const [selectedOffGradeType, setselectedOffGradeType] = useState("");
    const [Inchargename, setSelectinchargename] = useState("");
    const [selectedreportingoffice, setselectedReportingoffice] = useState("");
    const [reportingoffice, setReportingoffice] = useState("");

    useEffect(() => {

        bindOffGradeDdl(0);
        bindEmpGradeDdl(0);
        bindStaffGradeType(0)
        BindGrid()
    }, []);

    async function BindGrid() {
        let _a = [];
        _a.push("Employee")
        var Param = { mstuserregid: 0, rolecab: JSON.stringify(_a) }
        var url = "/UserReg/GetUserRegtable";
        let _response1 = await Api.BindGrid(Param, url)
        setListemployee(_response1)
        // setListemployeeGrade(([...new Set(_response1.map(value => value.AgGrade))]))
        BindGrid1();
    }

    function SetOfficeGrade(id, Flag) {
        try {
            if (Flag === 1) {
                setSelectOfficegrade(id)
                let _x = Listemployee.filter(function (e) {
                    return e.AgGrade === id;
                })
                setListemployeeCode(([...new Set(_x.map(value => value.Msusertregcode))]))
            }
            if (Flag === 2) {
                setSelectOFficeEmpCode(id)
                let _x = Listemployee.filter(function (e) {
                    return e.Msusertregcode === id;
                })
                setSelectOFficeDesignation(_x[0].AgDesignation)
                setSelectinchargename(_x[0].Name)
            }
        } catch {
        }
    }
    const [offGradeTypeId, setoffGradeTypeId] = useState("");
    const [empGrade, setEmpGrade] = useState("");
    const [selectedEmpGrade, setselectedEmpGrade] = useState("");
    const [staffGrade, setStaffGrade] = useState("");
    async function bindOffGradeDdl(id) {
        var Param = { Id: id }
        var url = "/OfficeGradeCreation/BindOfficeGradeCreation";
        let _response = await Api.BindGrid(Param, url)
        setoffGrade(_response);
    }
    async function bindEmpGradeDdl(id) {
        var Param = { Rolecab: 'Employee' }
        var url = "/UserReg/GetUserRegtable";
        let _response = await Api.BindGrid(Param, url)
        setEmpGrade(_response);
    }
    async function bindStaffGradeType(id) {
        var Param = { mststaffgradedesignationid: id }
        var url = "/StaffGradeType/BindStaffGradeType";
        try {
            let _response1 = await Api.BindGrid(Param, url);
            setStaffGrade(_response1);
        } catch {
        }
    }
    async function Getreportingcode(e) {
        var id = (e.target.value)
        var url = "/OfficeTypeCreation/RepotingOffice?id=" + id;
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setReportingoffice(_response)
    }


    async function getSelectedoffgiceGradeDdl(e) {
        setGrade(e.target.value);
        setoffGradeTypeId(e.target.value);
        var offGrade = e.target.options[e.target.selectedIndex].text;
        setGrade(offGrade)
        var Param = { Selectofficegrade: offGrade }
        var url = "/OfficeGradeCreation/BindOfficeGradeCreation";
        let _response = await Api.BindGrid(Param, url)
        setOffGradeType(_response);
    }
    async function OFficefunction(id) {
        var Param = { Selectofficegrade: id }
        var url = "/OfficeGradeCreation/BindOfficeGradeCreation";
        let _response = await Api.BindGrid(Param, url)
        setOffGradeType(_response);
    }
    async function IUDOperation() {
        var Grade = grade;
        var Autograde = selectedOffGradeType;
        var Officelocation = selectedOfficelocation;
        var Phonenumber = phonenumber;
        var Address = address;
        var Pincode = pincode;
        var EmailId = emailId;
        var Selectofficegrade = SelectOfficegrade;
        var InchargeName = Inchargename;
        var SelectofficeEmpCode = SelectOFficeEmpCode;
        var EMPgrade = empgrade;
        var Designation = designation;
        var Selectedreportingoffice = selectedreportingoffice;
        var ID = selectedRowData.id;

        var Param = {
            "mstofficetypecreationid": ID,
            "officetypecreationcode": "",
            "location": "",
            "selectOfficeGrade": "",
            "selectOfficeType": "",
            "contactnumber": Phonenumber,
            "location": Officelocation,
            "selectOfficeGrade": selectedOffGradeType,
            "selectOfficeType": grade,
            "email": emailId,
            "address": Address,
            "pincode": Pincode,
            "empcode": SelectOFficeEmpCode,
            "empgrade": SelectOfficegrade,
            "reportingOffice": Selectedreportingoffice,
            "designation": SelectOFficeDesignation,
            "flag": "U",
            "name": InchargeName,
        };
        var url = "/OfficeTypeCreation/IUDOfficeTypeCreation";
        let _response = await Api.IUD(Param, url);
        showAlert(_response);
        setShowPopup(false);

    }
    async function IUDOperationDelete() {
        var Grade = grade;
        var Autograde = selectedOffGradeType;
        var Officelocation = selectedOfficelocation;
        var Phonenumber = phonenumber;
        var Address = address;
        var Pincode = pincode;
        var EmailId = emailId;
        var Selectofficegrade = SelectOfficegrade;
        var InchargeName = Inchargename;
        var SelectofficeEmpCode = SelectOFficeEmpCode;
        var EMPgrade = empgrade;
        var Designation = designation;
        var Selectedreportingoffice = selectedreportingoffice;
        var ID = selectedRowData.id;

        var Param = {
            "mstofficetypecreationid": ID,
            "officetypecreationcode": "",
            "location": "",
            "selectOfficeGrade": "",
            "selectOfficeType": "",
            "contactnumber": Phonenumber,
            "location": Officelocation,
            "selectOfficeGrade": selectedOffGradeType,
            "selectOfficeType": grade,
            "email": emailId,
            "address": Address,
            "pincode": Pincode,
            "empcode": SelectOFficeEmpCode,
            "empgrade": SelectOfficegrade,
            "reportingOffice": Selectedreportingoffice,
            "designation": SelectOFficeDesignation,
            "flag": "D",
            "name": InchargeName,
        };
        var url = "/OfficeTypeCreation/IUDOfficeTypeCreation";
        let _response = await Api.IUD(Param, url);
        showAlert(_response);
        setShowPopup1(false);

    }
    const showAlert = (_response) => {
        Swal.fire({
            title: "Success",
            text: _response,
            icon: "success",
            confirmButtonText: "OK",
        });
    };
    async function BindGrid1(){
        var url = "/DropDown/GetAdminDeptStaff";
        let _response = await Api.BindDropdown(url)
        setListemployeeGrade(_response)
    }

    async function Get(id) {
        BindGrid1()
        var Param = { mstofficetypecreationid: id }
        var url = "/OfficeTypeCreation/BindOfficeTypeCreation";
        let _response = await Api.BindGrid(Param, url)
        setselectedOffGradeType(_response[0].SelectOfficeGrade)
        await OFficefunction(_response[0].SelectOfficeType)
        var url1 = "/OfficeTypeCreation/RepotingOffice?id=" + _response[0].SelectOfficeType;
        let _response1 = await Api.BindDropdown(url1)
        setReportingoffice(_response1)
        setTimeout(function () {
            setselectedReportingoffice(_response[0].Reportoffice)
            setSelectOFficeDesignation(_response[0].Designation)
        });
        setSelectOFficeEmpCode(_response[0].Empcode)
        setSelectOFficeEmpCode(_response[0].Msusertregcode)
        setSelectOfficegrade(_response[0].Empgrade)
        SetOfficeGrade(_response[0].Empgrade)
        setPincode(_response[0].Pincode)
        setEmailId(_response[0].Email)
        setAddress(_response[0].Address)
        setPhonenumber(_response[0].Contactnumber)
        setGrade(_response[0].SelectOfficeType)
        setSelectinchargename(_response[0].Name)
        setselectedOfficelocation(_response[0].Location)
    }


    return (
        <div>
            <Base Officecreation={true} />
            <div id="main" class="main">
                <div class="pagetitle">
                    <nav>
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <Link to="/dash">Home</Link>
                            </li>

                            <li class="breadcrumb-item">General Setting</li>
                            <li class="breadcrumb-item active">Office Creation</li>
                        </ol>
                    </nav>
                </div>
                <input
                    type="text"
                    placeholder="Search..."
                    onChange={onQuickFilterChange}
                    style={{ marginBottom: '10px' }}
                />
                {Api.CheckAdd(8) == 1 ?
                    <>
                        <button type="button" class="btn btn-primary Add" style={{ background: '#0782d0ed' }}>
                            <Link to={'/officetypecreationform'}
                                state={{ ID: 0, Flag: 'I' }}
                                style={{ color: "white" }}
                            >
                                Add
                            </Link>
                        </button>
                    </>
                    : null}
                <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={columnDefs}
                        pagination={true}
                        paginationPageSize={10}
                        domLayout='autoHeight'
                        floatingFilter={true}
                        enableBrowserTooltips={true}
                        onCellClicked={onCellClicked}
                        quickFilterText={quickFilterText}
                    />
                </div>
                {showPopup && (
                    <Modal show={true} onHide={closePopup} dialogClassName="custom-modal">
                        <Modal.Header closeButton>
                            <Modal.Title> {selectedRowData.code}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div class="row g-3">

                                <div class="col-md-6">
                                    <label for="officegrade">Office Grade</label>
                                    <select id="inputState" class="form-select" value={grade} onChange={(e) => { getSelectedoffgiceGradeDdl(e); Getreportingcode(e) }}>
                                        <option selected>Select Office Grade</option>
                                        {offGrade ? (
                                            offGrade.map((i, index) => (
                                                <option value={i.Selectofficegrade}>{i.Selectofficegrade}</option>
                                            ))) : (null)}

                                    </select>
                                </div>

                                <div class="col-md-6">
                                    <label for="gradetype">Grade Type</label>
                                    <select id="inputState" class="form-select" value={selectedOffGradeType} onChange={(e) => setselectedOffGradeType(e.target.value)}>
                                        <option selected>Select Office Type</option>
                                        {offGradeType ? (
                                            offGradeType.map((i, index) => (
                                                <option value={i.Mstofficegradecreationid}>{i.Ogc}</option>
                                            ))) : (null)}

                                    </select>
                                </div>
                                <div class="col-md-6">
                                    <label for="reportingcode">Reporting Office</label>
                                    <select id="inputState" class="form-select" value={selectedreportingoffice} onChange={(e) => setselectedReportingoffice(e.target.value)}>
                                        <option selected>Select Reporting Office</option>
                                        {reportingoffice ? (
                                            reportingoffice.map((i, index) => (
                                                <option value={i.id}>{i.Text}</option>
                                            ))) : (null)}

                                    </select>
                                </div>
                                <div class="col-md-6">
                                    <label for="location">Location</label>
                                    <input type="text" class="form-control" placeholder="Location" value={selectedOfficelocation} onChange={(e) => setselectedOfficelocation(e.target.value)} />
                                </div>
                                <div class="col-md-6">
                                    <label for="conactnumber">Contact Number</label>
                                    <input type="number" class="form-control no-spinner" placeholder="Contact Number" value={phonenumber} onChange={(e) => setPhonenumber(e.target.value)} />
                                </div>
                                <div class="col-6">
                                    <label for="address">Address</label>
                                    <input type="text" class="form-control" placeholder="Address" value={address} onChange={(e) => setAddress(e.target.value)} />
                                </div>
                                <div class="col-md-6">
                                    <label for="pincode">Pincode</label>
                                    <input type="text" class="form-control" placeholder="PinCode" value={pincode} onChange={(e) => setPincode(e.target.value)} />
                                </div>
                                <div class="col-md-6">
                                    <label for="email">Email Id</label>
                                    <input type="text" class="form-control" placeholder="Email Id" value={emailId} onChange={(e) => setEmailId(e.target.value)} />
                                </div>
                                <h5 class="card-title">Office Incharge Details</h5>
                                {/* <div class="col-md-6">
                                    <label for="officetype">Staff Type</label>
                                    <select class="form-select" value={SelectOfficegrade} placeholder="Grade" onChange={(e) => SetOfficeGrade(e.target.value, 1)} >
                                        <option selected>Select Staff Type</option>
                                        {ListemployeeGrade ? (
                                            ListemployeeGrade.map((i, index) => (
                                                <option value={i}>{i}</option>
                                            ))) : (null)}
                                    </select>


                                </div> */}
                                <div class="col-md-6">
                                    <label for="employeecode">Employee Code</label>
                                    <select class="form-select" value={SelectOFficeEmpCode} onChange={(e) => SetOfficeGrade(e.target.value, 2)} >
                                        <option selected>Select Employee Code </option>
                                        {ListemployeeGrade ? (
                                            ListemployeeGrade.map((i, index) => (
                                                <option value={i.Text}>{i.Text}</option>
                                            ))) : (null)}
                                    </select>

                                </div>
                                <div class="col-md-6">
                                    <label for="designation">Designation</label>
                                    <input type="text" class="form-control" disabled={true} placeholder="Designation" value={SelectOFficeDesignation} />
                                </div>
                                <div class="col-md-6">
                                    <label for="designation">Incharge Name</label>
                                    <input type="text" class="form-control" disabled={true} placeholder="Name" value={Inchargename} />
                                </div>
                                <div class="text-center">
                                    {Api.CheckUpdate(8) == 1 ?
                                        <button type="button" class="btn btn-primary" onClick={IUDOperation}>Update</button>
                                        : null}
                                    {Api.CheckDelete(8) == 1 ?
                                        <button type="reset" class="btn btn-info" onClick={IUD_Delete} >Delete</button>
                                        : null}
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                )}
                {showPopup1 && (
                    <Modal show={true} onHide={closePopup1} >
                        <Modal.Header closeButton>
                            <Modal.Title> {selectedRowData.code}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Are You sure want to Delete?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={closePopup1}>
                                Close
                            </Button>
                            <Button variant="secondary" onClick={IUDOperationDelete}>
                                Delete
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )}
            </div>
        </div>
    )
}
