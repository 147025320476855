import React from 'react'
import Base from '../../Base';
import { Link,useLocation,Navigate,useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import Api from '../../Services/ApiCall'
import toast, { Toaster } from 'react-hot-toast';
import Swal from 'sweetalert2';

export default function Brokercommisionform() {
    const navigate=useNavigate();
    const location = useLocation();
    const { ID,Flag } = location.state;
    ////console.log('%%%', ID, Flag);

   
    useEffect(() => {
        if (ID != 0) {
            // GetMenu(0)  
            //  GetUserType()
            Get(ID)
         
        }
        else { 
            //Get(0) 
            // GetUserType()
        }
    }, []);


    const[creationdetail,setCreationdetail] =useState("");
    const [commission, setCommission] = useState("");
    const [packageupdate, setPackageupdate] = useState("");
    const [brokercommission, setBroker] = useState("");

    

    async function IUDOperation(flag, id)
    {
       
       var Creation = creationdetail;
       var Commission = commission;
       var Packageupdate = packageupdate;
       var Broker = brokercommission;
     

      

       if (Creation == "") {
           toast.error('Please select Creation detail')
           return false;
       }
       

       if (Commission == "") {
           toast.error('Please select Commission')
           return false;
       }
       if (Packageupdate == "") {
        toast.error('Please select Packageupdate commission')
        return false;
    }
    if (Broker == "") {
        toast.error('Please select Broker Commission')
        return false;
    }
    

   
   var Param = { 
      "mstbrokercommissioncreationid": ID,
   "brokercommissioncreationcode": Broker,
   "creationdetail": Creation,
   "commission": Commission,
   "packageupdatecommission": Packageupdate,
   "brokercommission": "Broker",
   "active": "",
   "flag": Flag,
   "searchField": "",
//    "createdBy": 0,
   "createdDate": "2022-10-14T11:42:11.626Z" 
 };
   var url = "/Broker/IUDBroker";
   let _response = await Api.IUD(Param, url);
  // //console.log(_response,"iiiiiii");
  showAlert(_response);
   setTimeout(()=>{
    navigate('/brokercommisiontable')
   },2000)

}
const showAlert = (_response) => {
    Swal.fire({
        title: "Success",
        text: _response,
        icon: "success",
        confirmButtonText: "OK",
    });
};

async function Get(id) {
   var Param = { Mstbrokercommissioncreationid: id }
   var url = "/Broker/BindBroker";
    
   let _response1 = await Api.BindGrid(Param, url)

   setCreationdetail(_response1[0].Creationdetail);
   setCommission(_response1[0].Commission);
   setPackageupdate(_response1[0].Packageupdatecommission);
   setBroker(_response1[0].Brokercommissioncreationcode);
   ////console.log(_response1,"ssssssssss");
}





  return (
    <div>
        <Toaster toastOptions={{ position: "top-right" }} />

         <Base Brokercommission={true}/>
          <div id="main" class="main">
              <div class="pagetitle">
                
                  <nav>
                      <ol class="breadcrumb">
                          <li class="breadcrumb-item"><Link to="/dash">Home</Link></li>
                          <li class="breadcrumb-item">Commision</li>
                          <li class="breadcrumb-item active">Broker Commission</li>
                      </ol>
                  </nav>
              </div>
             
              <div class="card">
                  <div class="card-body">
                      <h5 class="card-title">Broker Commision Creation</h5>

              
                      <div class="row g-3">
                             <div class="col-md-6">
                             <label for="creationdetails">Creation Details</label>
                              <input type="text" class="form-control" placeholder="Creation Details" disabled={Flag == "D" || Flag == "null" ? true : false} value={creationdetail} onChange={(e) => setCreationdetail(e.target.value)}></input>
                                  
                          </div>
                          <div class="col-md-6">
                            <label for="commission">Commission</label>
                              <input type="text" class="form-control"  placeholder=" commission" disabled={Flag == "D" || Flag == "null" ? true : false} value={commission} onChange={(e) => setCommission(e.target.value)}></input>
                                 
                            
                          </div>
                          <div class="col-md-6">
                          <label for="commission">Package Update Commission </label>
                              <input type="text" class="form-control"  placeholder="Package update Commission" disabled={Flag == "D" || Flag == "null" ? true : false} value={packageupdate} onChange={(e) => setPackageupdate(e.target.value)}></input>
                                 
                          </div>
                          <div class="col-md-6">
                          <label for="brokercommission">Broker Commission </label>
                              <input type="text" class="form-control"  placeholder="Broker Commission" disabled={Flag == "D" || Flag == "null" ? true : false} value={brokercommission} onChange={(e) => setBroker(e.target.value)}></input>
                                 
                          </div>
                         
                          <div class="text-center">
                              <button type="button" class="btn btn-primary"onClick={IUDOperation}>Submit</button>
                              <button type="reset" class="btn btn-info">
                              <Link to='/brokercommisiontable'>
                                Back
                                </Link>
                                </button>
                          </div>
                      </div>

                  </div>
              </div>

           
          </div>


    </div>
  )
}
