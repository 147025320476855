import React, { useState, useEffect } from 'react';
import Api from '../Services/ApiCall';
import Base from '../Base';
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import '../../App.css';
import { Button, Modal } from 'react-bootstrap';
import CloseButton from 'react-bootstrap/CloseButton';
import { useTable, useSortBy, usePagination, useGlobalFilter } from "react-table";
import { Globalfilter } from "../Admin/Admin_form/Filter/Globalfilter";
import toast, { Toaster } from 'react-hot-toast';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { BeatLoader } from "react-spinners";

export default function Otherusers(i, any) {

    const navigate = useNavigate();
    const [rowData, setRowData] = useState([]);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [showPopup1, setShowPopup1] = useState(false);

    const [quickFilterText, setQuickFilterText] = useState("");

    const onQuickFilterChange = (event) => {
        setQuickFilterText(event.target.value);
    };

    useEffect(() => {
        fetchDataAndSetState();
    }, []);

    const [_Usertype, set_Usertype] = useState("Employer");

    const fetchDataAndSetState = async () => {
        try {
            let _a1 = [];
            _a1.push(_Usertype)
            var Param = { mstuserregid: 0, rolecab: JSON.stringify(_a1) }
            var url = "/UserReg/GetBOpendingData";
            let _response1 = await Api.BindGrid(Param, url);
            setRowData(_response1);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const openPopup = (rowData) => {
        setSelectedRowData(rowData);
        Get(rowData.id)
        setShowPopup(true);
    };
    const openPopup1 = (rowData) => {
        setShowPopup(false);
        setShowPopup1(true);
    };

    const closePopup1 = () => {
        setSelectedRowData(null);
        setShowPopup1(false);
    };
    const closePopup = () => {
        setSelectedRowData(null);
        setShowPopup(false);
    };

    const onCellClicked = (params) => {
        openPopup({ id: params.data.Mstuserregid, code: params.data.Msusertregcode, subscribe: params.data.Subscribe });
    };

    const columnDefs = [
        // { headerName: 'Id', field: 'Mstuserregid', filter: 'agNumberColumnFilter', enableFilter: true },
        { headerName: 'Code', field: 'Msusertregcode', filter: true, enableFilter: true },
        { headerName: 'Name', field: 'Name', filter: true, enableFilter: true },
        { headerName: 'Aadhaar Number', field: 'Aadhaarnumber', filter: true, enableFilter: true },
        { headerName: 'Email', field: 'Emailid', filter: true, enableFilter: true },
        // { headerName: 'District', field: 'District', filter: true, enableFilter: true },
        { headerName: 'Wallet Amount', field: 'Recharge_amount', filter: true, enableFilter: true },
        { headerName: 'Company Name', field: 'Companyname', filter: true, enableFilter: true },
        { headerName: 'Company PhoneNo', field: 'CompanyphoneNo', filter: true, enableFilter: true },
        { headerName: 'CreatedStatus', field: 'CreatedStatus', filter: true, enableFilter: true },
        { headerName: 'CreatedBy', field: 'CreatedBy1', filter: true, enableFilter: true },
        { headerName: 'CreatedDate', field: 'CreatedDate' },
        { headerName: 'Status', field: 'Activestatus', filter: true, enableFilter: true },
    ];
    const UserT = "Employer"
    useEffect(() => {
        set_Usertype(UserT)
        GetMenu(0)
        GetQualification()
        bindStaffGradeDdl(0)
        getEmployeeCode()
        GetPlandetail()
        GetAmount()
        GetDistrict()
        GetDepartment()
        GetCountryList()
        GetCityList()
        GetDepartment()
    }, []);


    const [name, setName] = useState("");
    const [phone, setPhonenum] = useState("");
    const [pannumber, setPannum] = useState("");
    const [aadharnumber, setAadhaarnum] = useState("");
    const [email, setEmail] = useState("");
    const [qualification, setQualification] = useState([]);
    const [usertype, setUsertype] = useState([]);
    const [department, setDepartment] = useState([]);
    const [selectdepartment, setselectDepartment] = useState("");

    const [CountryList, setCountryList] = useState([]);
    const [StateList, setStateList] = useState([]);
    const [CityList, setCityList] = useState([]);
    const [selectedGradeType, setselectedGradeType] = useState("");
    const [selectedGrade, setselectedGrade] = useState("");
    const [user, setUser] = useState("");
    const [address, setAddress] = useState("");
    const [grade, setGrade] = useState("");
    const [designation, setDesignation] = useState("");


    const [agentcode, setAgentcode] = useState("");

    const [officecode, setOfficecode] = useState("");
    // const [_Usertype, set_Usertype] = useState("");

    const [ListMenu, setListMenu] = useState([]);
    const [reportempcode, setReportempcode] = useState("")
    const [state, setState] = useState("")
    const [country, setCountry] = useState("")
    const [city, setCity] = useState("")
    const [pincode, setPincode] = useState("")
    const [acholder, setAcholder] = useState("")
    const [accountno, setAccountno] = useState("")
    const [ifsccode, setIfsccode] = useState("")
    const [branchname, setBranchname] = useState("")
    const [bankname, setbankname] = useState("")
    const [reportingempcode, setreportingempcode] = useState("")
    const [accountholdername, seaccountholdername] = useState("")
    const [selectedQualiify, setselectedQualiify] = useState([]);
    const [gradetype, setgradetype] = useState([]);
    const [staffGrade, setStaffGrade] = useState([]);
    const [selectedStaffGrade, setSelectedStaffGrade] = useState([]);
    const [staffGradeType, setStaffGradeType] = useState([]);
    const [staffDesignation, setStaffDesignation] = useState("");

    const [selectedPlan, setselectedPlan] = useState("");
    const [plan, setPlan] = useState("");
    const [amount, setAmount] = useState([]);
    const [employeebranch, setEmployeebranch] = useState("");
    const [incharge, setIncharge] = useState("");
    const [companyname, setCompanyname] = useState("");
    const [companynumber, setCompanynumber] = useState("");
    const [companyaddress, setCompanyaddress] = useState("");
    const [companygst, setCompanygst] = useState("");



    const [ListemployeeGrade, setListemployeeGrade] = useState([]);
    const [ListemployeeCode, setListemployeeCode] = useState([]);
    const [ListemployeeDesignation, setListemployeeDesignation] = useState("");

    const [SelectOfficegrade, setSelectOfficegrade] = useState("");
    const [SelectOFficeEmpCode, setSelectOFficeEmpCode] = useState("");
    const [SelectOFficeDesignation, setSelectOFficeDesignation] = useState("");
    const [officeinchargename, setOfficeinchargename] = useState("");

    async function getSelectedStaffGradeTypeDdl(e) {
        setselectedGradeType(e.target.value);
        var Param = { Mststaffgradetypeid: e.target.value }
        var url = "/StaffGradeType/BindStaffGradeType";
        let _response = await Api.BindGrid(Param, url);
        setStaffDesignation(_response[0].Designation);
    }
    async function getEmployeeCode() {
        var url = "/DropDown/GetOfficetypecode";
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setListemployeeCode(_response)
    }
    async function GetCountryList() {
        let _response = await Api.DirectApiCall('country', "", "")
        setCountryList(_response)
    }
    async function GetStateList(value) {
        setCountry(value)
        var Param = { country: value }
        let _response = await Api.DirectApiCall('state', value)
        setStateList(_response.states)
    }
    async function GetCityList(value) {
        setState(value)
        // var url = "/DropDown/GetCities";
        // let _response = await Api.BindDropdown(url)
        // let Arry_Title1 = [];
        // setCityList(_response)
    }
    const [district, setDistrict] = useState("");
    const [districtlist, setDistrictlist] = useState("")
    async function GetDistrict() {
        var url = "/DropDown/GetDistrict";
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setDistrictlist(_response)
    }
    async function getSelectedStaffGradeDdl(e) {
        setGrade(e.target.value);
        setSelectedStaffGrade(e.target.value);
        var staffGrade = e.target.options[e.target.selectedIndex].text;
        var Param = { mststaffgradedesignationid: 0 }
        var url = "/StaffGradeType/BindStaffGradeType";
        let _response1 = await Api.BindGrid(Param, url)
        _response1 = _response1.filter(function (el) {
            return el.Staffgradetypecode == e.target.value;
        });
        var sGrade = [...new Set(_response1.map(q => {
            var x = {
                Automaticbaseedongradetype: q.Automaticbaseedongradetype,
                Mststaffgradetypeid: q.Mststaffgradetypeid
            }
            return x;
        }))]
        setStaffGradeType(sGrade);
    }
    async function GetStaffGrademannual(id) {
        try {
            var Param = { mststaffgradedesignationid: 0 }
            var url = "/StaffGradeType/BindStaffGradeType";
            let _response121 = await Api.BindGrid(Param, url);
            _response121 = _response121.filter(function (el) {
                return el.Role == id;
            });
            var sGrade = [...new Set(_response121.map(q => q.Staffgradetypecode))]
            setStaffGrade(sGrade);
        } catch {
        }
    }
    async function GetStaffGradeTypemannual(id) {
        var Param = { mststaffgradedesignationid: 0 }
        var url = "/StaffGradeType/BindStaffGradeType";
        let _response1 = await Api.BindGrid(Param, url)
        _response1 = _response1.filter(function (el) {
            return el.Staffgradetypecode == id;
        });
        var sGrade = [...new Set(_response1.map(q => {
            var x = {
                Automaticbaseedongradetype: q.Automaticbaseedongradetype,
                Mststaffgradetypeid: q.Mststaffgradetypeid
            }
            return x;
        }))]
        setStaffGradeType(sGrade);
    }
    async function GetPlandetail() {
        var url = "/DropDown/GetEmployerdetail";
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setPlan(_response)
    }
    async function GetAmount(e) {
        setselectedPlan(e.target.value)
        var id = (e.target.value)
        var url = "/DropDown/GetEmployeramount?id=" + id;
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setAmount(_response)
    }
    async function GetQualification() {
        var url = "/DropDown/GetQualification";
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setQualification(_response)
    }
    async function GetMenu(id) {
        var Param = { mstmenuid: id }
        var url = "/Menu/BindMenu";
        let _response = await Api.BindGrid(Param, url)
        setListMenu(_response);
    }
    async function GetRoleType(id) {
        var Param = { Mstroleid: id }
        var url = "/Role/BindRole";
        let _response1 = await Api.BindGrid(Param, url);
        setListMenu(JSON.parse(_response1[0].RoleList));
        await bindStaffGradeDdl(id)
    }

    async function GetDepartment() {
        var url = "/DropDown/GetDepartment";
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setDepartment(_response)
    }

    async function GetGradeType(id) {
        setselectedGrade(id)
        var url = "/DropDown/GetGradeType?GradeCode=" + id;
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        try {
            setgradetype(_response[0].Text.split(","))
        }
        catch { }
    }

    async function bindStaffGradeDdl(id) {
        var Param = { mststaffgradedesignationid: 0 }
        var url = "/StaffGradeType/BindStaffGradeType";
        try {
            let _response1 = await Api.BindGrid(Param, url);
            _response1 = _response1.filter(function (el) {
                return el.Role == id;
            });
            var sGrade = [...new Set(_response1.map(q => q.Staffgradetypecode))]
            setStaffGrade(sGrade);
        } catch {
        }
    }
    async function GetGradeTypesManual(ID) {
        var Param = { Staffgradetypecode: ID }
        var url = "/StaffGradeType/BindStaffGradeType";
        let _response = await Api.BindGrid(Param, url)
        setStaffGradeType(_response);
    }
    async function getSelectedStaffGradeTypeDdl(e) {
        setselectedGradeType(e.target.value);
        var Param = { Mststaffgradetypeid: e.target.value }
        var url = "/StaffGradeType/BindStaffGradeType";
        let _response = await Api.BindGrid(Param, url);
        setStaffDesignation(_response[0].Designation);
    }
    async function Getofficemployeename(e) {
        setSelectOFficeEmpCode(e);
        var Param = { Mstofficetypecreationid: e }
        var url = "/DropDown/GetInchargeNmae?id=" + e;
        let _response = await Api.BindGrid(Param, url);
        setOfficeinchargename(_response[0].Text);
    }
    async function IUDOperation(flag, id) {
        var Name = name;
        var Mobile = phone;
        var Pan = pannumber;
        var Qualify = selectedQualiify;
        var Email = email;
        var Aadhar = aadharnumber;
        var Address = address;
        var State = state;
        var Country = country;
        var City = city;
        var Pincode = pincode;
        var Acholder = acholder;
        var Accountno = accountno;
        var Ifsccode = ifsccode;
        var Branchname = branchname;
        var SelectedStaffGrade = selectedStaffGrade;
        var StaffGradeType = staffGradeType;
        var Department = selectdepartment;
        var Reportempcode = reportingempcode;
        var Bankname = bankname;
        var CompanyName = companyname;
        var Companynumber = companynumber;
        var Companyaddress = companyaddress;
        var CompanyGST = companygst;
        var SelectedPlan = selectedPlan;
        var Amount = amount;
        var ID = selectedRowData.id;
        var Flag = "U"

        var Param =
        {
            "mstuserregid": ID,
            "msusertregcode": "",
            "name": name,
            "mobilenumber": Mobile,
            "pannumber": pannumber,
            "qualification": "1",
            "usertype": 0,
            "plandetails": 0,
            "amount": 0,
            "empbranch": "",
            "incharge": "",
            "emailid": email,
            "aadhaarnumber": aadharnumber,
            "rolecab": "Employer",
            "agGradetype": "",
            "agGrade": "",
            "agDesignation": "",
            "department": "",
            "agAgentcode": "",
            "officecode": "",
            "reportingempcode": "",
            "city": city,
            "state": state,
            "country": country,
            "address": address,
            "pincode": pincode,
            "accountholdername": "",
            "accountnumber": "",
            "ifsccode": "",
            "branchname": "",
            "bankname": "",
            "password": "",
            "companyname": CompanyName,
            "companyphoneNo": Companynumber,
            "companyaddress": Companyaddress,
            "companygst": CompanyGST,
            "confirmpassword": "",
            "rolevscab": "Employer",
            "active": "",
            "flag": Flag,
            "searchField": "Employer",
            "subscribe": "",
            "plantype": "",
            "planperiod": "",
            "activestatus": "",
            "planamount": 0,
            "district": district,
        }
        var url = "/UserReg/IUDUserRegtable";
        let _response = await Api.IUD(Param, url);
        showAlert(_response);
        setShowPopup(false);
    }
    async function IUDOperationDelete(flag, id) {
        var Name = name;
        var Mobile = phone;
        var Pan = pannumber;
        var Qualify = selectedQualiify;
        var Email = email;
        var Aadhar = aadharnumber;
        var Address = address;
        var State = state;
        var Country = country;
        var City = city;
        var Pincode = pincode;
        var Acholder = acholder;
        var Accountno = accountno;
        var Ifsccode = ifsccode;
        var Branchname = branchname;
        var SelectedStaffGrade = selectedStaffGrade;
        var StaffGradeType = staffGradeType;
        var Department = selectdepartment;
        var Reportempcode = reportingempcode;
        var Bankname = bankname;
        var CompanyName = companyname;
        var Companynumber = companynumber;
        var Companyaddress = companyaddress;
        var CompanyGST = companygst;
        var SelectedPlan = selectedPlan;
        var Amount = amount;
        var ID = selectedRowData.id;

        var Param =
        {
            "mstuserregid": ID,
            "msusertregcode": "",
            "name": name,
            "mobilenumber": Mobile,
            "pannumber": pannumber,
            "qualification": "1",
            "usertype": 0,
            "plandetails": 0,
            "amount": 0,
            "empbranch": "",
            "incharge": "",
            "emailid": email,
            "aadhaarnumber": aadharnumber,
            "rolecab": "Employer",
            "agGradetype": "",
            "agGrade": "",
            "agDesignation": "",
            "department": "",
            "agAgentcode": "",
            "officecode": "",
            "reportingempcode": "",
            "city": city,
            "state": state,
            "country": country,
            "address": address,
            "pincode": pincode,
            "accountholdername": "",
            "accountnumber": "",
            "ifsccode": "",
            "branchname": "",
            "bankname": "",
            "password": "",
            "companyname": CompanyName,
            "companyphoneNo": Companynumber,
            "companyaddress": Companyaddress,
            "companygst": CompanyGST,
            "confirmpassword": "",
            "rolevscab": "Employer",
            "active": "",
            "flag": "D",
            "searchField": "Employer",
            "subscribe": "",
            "plantype": "",
            "planperiod": "",
            "activestatus": "",
            "planamount": 0,
            "district": district,
        }
        var url = "/UserReg/IUDUserRegtable";
        let _response = await Api.IUD(Param, url);
        showAlert(_response);
        setShowPopup1(false);
    }
    const showAlert = (_response) => {
        Swal.fire({
            title: "Success",
            text: _response,
            icon: "success",
            confirmButtonText: "OK",
        });
    };

    async function Get(id) {

        var Param = { Mstuserregid: id }
        var url = "/UserReg/GetBOpendingData";
        let _response = await Api.BindGrid(Param, url)
        setName(_response[0].Name)
        setPhonenum(_response[0].Mobilenumber)
        setPannum(_response[0].Pannumber)
        setselectedQualiify(_response[0].Qualification)
        setEmail(_response[0].Emailid)
        setAadhaarnum(_response[0].Aadhaarnumber)
        set_Usertype(_response[0].Rolecab)
        await GetStaffGrademannual(_response[0].Rolecab)
        setSelectedStaffGrade(_response[0].AgGrade)
        await GetGradeTypesManual(_response[0].AgGrade)
        await GetStaffGradeTypemannual(_response[0].AgGrade)
        setselectedGradeType(_response[0].AgGradetype)
        setStaffDesignation(_response[0].AgDesignation)
        setselectDepartment(_response[0].Department)
        setAgentcode(_response[0].AgAgentcode)
        setOfficecode(_response[0].Officecode)
        setSelectOFficeEmpCode(_response[0].Reportingempcode)
        Getofficemployeename(_response[0].Reportingempcode)
        setCity(_response[0].City)
        setCountry(_response[0].Country)
        setState(_response[0].State)
        setAddress(_response[0].Address)
        setPincode(_response[0].Pincode)
        setAcholder(_response[0].Accountholdername)
        setAccountno(_response[0].Accountnumber)
        setIfsccode(_response[0].Ifsccode)
        setDistrict(_response[0].District)
        setCompanyname(_response[0].Companyname)
        setCompanynumber(_response[0].CompanyphoneNo)
        setCompanyaddress(_response[0].Companyaddress)
        setCompanygst(_response[0].CompanyGst)
        setselectedPlan(_response[0].Plantype)
        setBranchname(_response[0].Branchname)
        setbankname(_response[0].Bankname)
        setIfsccode(_response[0].Ifsccode)
        set_Usertype(_response[0].Rolecab)
        setBranchname(_response[0].Branchname)
        setListMenu(JSON.parse(_response[0].Rolevscab))
        setDistrict(_response[0].District)
    }

    async function GetCityValue(value) {
        setDistrict(value)
        var Param = { districtName: value, cityName: "" }
        var url = "/CommanAPI/GetCity";
        let _response = await Api.BindGrid(Param, url)
        setCityList(_response)
    }
    const [isLoading, setIsLoading] = useState(false);

    async function Sync() {
        setIsLoading(true);
        var Param =
        {
            "flag": "Employer"
        }
        var url = "/UserReg/AproveBOpendingData";
        let _response = await Api.IUD(Param, url);
        setIsLoading(false);
        showAlert(_response);
    }

    return (
        <div>
            <Base BoEmployerApproval={true} />
            <div id="main" class="main">
                <div class="pagetitle">

                    <nav>
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <Link to="/dash">Home</Link>
                            </li>

                            <li class="breadcrumb-item">Services</li>
                            <li class="breadcrumb-item active">Employer Approval</li>
                        </ol>
                    </nav>
                </div>
                <input
                    type="text"
                    placeholder="Search..."
                    onChange={onQuickFilterChange}
                    style={{ marginBottom: '10px' }}
                />
                <button type="button" class="btn btn-primary Add" style={{ background: '#0782d0ed' }} onClick={() => Sync()}>
                    Sync
                </button>
                {isLoading ? (
                    <div className="d-flex justify-content-center my-5 pt-5">
                        <div className='pt-5 mt-5'>
                            <BeatLoader className='mt-5' />
                        </div>
                    </div>
                ) : (
                    <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
                        <AgGridReact
                            rowData={rowData}
                            columnDefs={columnDefs}
                            pagination={true}
                            paginationPageSize={10}
                            domLayout='autoHeight'
                            floatingFilter={true}
                            enableBrowserTooltips={true}
                            onCellClicked={onCellClicked}
                            quickFilterText={quickFilterText}
                        />
                    </div>
                )}
                {showPopup && (
                    <Modal show={true} onHide={closePopup} dialogClassName="custom-modal">
                        <Modal.Header closeButton>
                            <Modal.Title> {selectedRowData.code}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <h5 class="card-title"> Company Details</h5>
                            <div class="row g-3">
                                <div class="col-md-6">
                                    <label for="companyname">Company Name</label>
                                    <input type="text" class="form-control" placeholder="Company Name" value={companyname} onChange={(e) => setCompanyname(e.target.value)} />
                                </div>
                                <div class="col-md-6">
                                    <label for="companycontactnumber">Company Contact Number</label>
                                    <input type="number" class="form-control no-spinner" placeholder="Company Contact Number" value={companynumber} onChange={(e) => setCompanynumber(e.target.value)} />
                                </div>
                                <div class="col-md-6">
                                    <label for="gstnumber">GST Number</label>
                                    <input type="text" class="form-control" placeholder="Company  GST" value={companygst} onChange={(e) => setCompanygst(e.target.value)} />
                                </div>
                                <div class="col-md-6">
                                    <label for="pannumber">Pan Number</label>
                                    <input type="text" class="form-control" placeholder="Pan Number" value={pannumber} onChange={(e) => setPannum(e.target.value)} />
                                </div>
                                <div class="col-md-6">
                                    <label for="email">Email</label>
                                    <input type="email" class="form-control" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                </div>
                                {/* <div class="col-md-6">
                                <label for="companyaddress">Company Address</label>
                                <textarea class="form-control" placeholder="Company Address" value={companyaddress} onChange={(e) => setCompanyaddress(e.target.value)} ></textarea>
                            </div> */}
                                <div class="col-md-6">
                                    <label for="country">Country</label>
                                    <select id="inputState" class="form-select" value={country} onChange={(e) => GetStateList(e.target.value)}>
                                        <option selected>Country</option>
                                        {CountryList.map((i, index) => (
                                            <option value={i.country.toLowerCase()}>{i.country}</option>
                                        ))}

                                    </select>
                                </div>
                                <div class="col-md-6">
                                    <label for="state">State</label>
                                    <select id="inputState" class="form-select" value={state} onChange={(e) => GetCityList(e.target.value)}>
                                        <option selected >State</option>
                                        {StateList.map((i, index) => (
                                            <option value={i.name.toLowerCase()}>{i.name}</option>
                                        ))}

                                    </select>
                                </div>
                                <div class="col-md-6">
                                    <label for="district">District</label>
                                    <select id="inputState" class="form-select" value={district} onChange={(e) => GetCityValue(e.target.value)}>
                                        <option selected >District</option>
                                        {districtlist &&
                                            districtlist.map((i, index) => (
                                                <option value={i.Text}>{i.Text}</option>
                                            ))}
                                    </select>
                                </div>
                                <div class="col-md-6">
                                    <label for="city">City</label>
                                    <select id="inputState" class="form-select" value={city} onChange={(e) => setCity(e.target.value)}>

                                        <option >City</option>
                                        {CityList.map((i, index) => (
                                            <option value={i.CityName}>{i.CityName}</option>
                                        ))}
                                    </select>
                                </div>
                                <div class="col-md-6">
                                    <label for="pincode">Pincode</label>
                                    <input type="number" class="form-control no-spinner" placeholder="Pincode" value={pincode} onChange={(e) => setPincode(e.target.value)} />
                                </div>
                                <div class="col-md-6">
                                    <label for="address">Address</label>
                                    <textarea class="form-control" placeholder="Address" value={address} onChange={(e) => setAddress(e.target.value)} ></textarea>
                                </div>


                                <h5 class="card-title"> Incharge Details</h5>
                                <div class="col-md-6">
                                    <label for="name">Name</label>
                                    <input type="text" class="form-control" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
                                </div>
                                <div class="col-md-6">
                                    <label for="phonenumber">Phone Number</label>
                                    <input type="number" maxlength="15" minlength="10" class="form-control no-spinner" placeholder="Phone Number" value={phone} onChange={(e) => setPhonenum(e.target.value)} />
                                </div>
                                <div class="col-md-6">
                                    <label for="aadhar">Designation</label>
                                    <input type="text" class="form-control no-spinner" placeholder="Designation" value={aadharnumber} onChange={(e) => setAadhaarnum(e.target.value)} />
                                </div>

                                {/* <div class="text-center"> 
                                        <button type="button" class="btn btn-primary" style={{ background: 'green' }} onClick={IUDOperation}>Approve</button>
                                       
                                        <button type="reset" class="btn btn-info" style={{ background: 'red', color: '#ffffff' }} >Reject</button>
                                       

                                </div> */}
                            </div>
                        </Modal.Body>
                    </Modal>
                )}
                {showPopup1 && (
                    <Modal show={true} onHide={closePopup1} >
                        <Modal.Header closeButton>
                            <Modal.Title>{selectedRowData.code}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Are You sure want to Delete?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={closePopup1}>
                                Close
                            </Button>
                            <Button variant="secondary" onClick={IUDOperationDelete}>
                                Delete
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )}
            </div>

        </div>
    )
}