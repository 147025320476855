import { param } from "jquery";
import Base from "./Base";
import EmployerBase from "./Employer/EmployerBase";
import JobseekerBase from "./Jobseeker/JobseekerBase";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Api from "./Services/ApiCall";
import toast, { Toaster } from 'react-hot-toast';
import Swal from 'sweetalert2';
import { Images } from "../Images/Index";

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { Amount, Code, Plan, Planperiod,Tax, Coupon, Coupon_Percent } = location.state; 
  const tax_percent = parseInt(Tax) ?? 0;
  const newAmountTextAsNumber = parseFloat(Amount) || 0;
  const finEL_amount = (newAmountTextAsNumber * Tax) / 100 || 0; 
  const FINAL_AMOUNT = newAmountTextAsNumber + finEL_amount;
  let bill = sessionStorage.getItem('AccessType') == 'Employer' ? JSON.parse(sessionStorage.getItem('userdetails'))[0]['Msusertregcode'] : JSON.parse(sessionStorage.getItem('userdetails'))[0]['Mstjobseekercode']
  const [billingaddress, setBillingaddress] = useState(sessionStorage.getItem('AccessType') == 'Employer' || sessionStorage.getItem('AccessType') == 'jobseeker' ? bill : Code);
  const [billingaddress1, setBillingaddress1] = useState("");
  const [billingaddress11, setBillingaddress11] = useState("");
  const [customerusertype, setCustomerusertype] = useState("");
  const [customerid, setCustomerid] = useState("");

  const [FINAL_Tax_AMOUNT, setFINAL_Tax_AMOUNT] = useState(finEL_amount);
  const [FINAL_Payment_AMOUNT, setFINAL_Payment_AMOUNT] = useState(FINAL_AMOUNT);
  const [finEL_amount1, setfinEL_amount1] = useState(0);
  const [dicAmt, setDicAmt] = useState(newAmountTextAsNumber);

  const [code1, setCode1] = useState("");
  const [customername, setCustomername] = useState("");
  const [customerMob, setCustomerMob] = useState("");
  const [customerAddress, setCustomerAddress] = useState("");
  const [paymenttype, setPaymenttype] = useState("");
  const [comisionvalue, setComisionvalue] = useState("");
  const [couponcode, setCouponcode] = useState("");
  const [billnumber, setBillNumber] = useState("");
  async function InvoiceGet(id) {
    var id = 1;
    var url = "/DropDown/GetBillno?id=" + id;
    let _response = await Api.BindDropdown(url)
    setBillNumber(_response.Text1);
  }
  async function GetCommission(_response1) {
    var usertype = _response1.Text1;
    var code = _response1.Text4;
    var url = `/DropDown/GetCommisionAmount?usertype=${usertype}&code=${code}`;
    let _response = await Api.BindDropdown(url)
    setComisionvalue(_response.Text);
  }
  async function GetAddress1(value) {
    var id = value;
    var url = "/DropDown/GetAddress?id=" + id;
    let _response = await Api.BindDropdown(url)
    let Arry_Title1 = [];
    setBillingaddress11(_response)
    GetCommission(_response)
  }

  

  async function GetAddress(value) {
    var id = value;
    var url = "/DropDown/GetAddress?id=" + id;
    let _response = await Api.BindDropdown(url)
    let Arry_Title1 = [];
    setBillingaddress1(_response)
    setCustomerusertype(_response.Text1)
    setCustomername(_response.Text2)
    setCustomerMob(_response.Text3)
    setCustomerAddress(_response.Text)
    setCustomerid(_response.id)
    setCode1(_response.Text4)
    setTimeout(Payment2(_response), 5 * 1000);
  }
  const options = {
    key: "rzp_live_cXSwY1gVVBVYzB",
    amount: (() => {

      return FINAL_Payment_AMOUNT * 100;
    })(),


    name: "Elakiyaa Solution",
    description: Amount['Plan'],
    handler: function (response) {
      Payment(response.razorpay_payment_id, customerusertype, customerid, code1
        , customername, customerMob, customerAddress);

    },
    prefill: {
      name: "",
      contact: "",
      email: ""
    },
    notes: {
      address: billingaddress1.Text
    },
    theme: {
      color: "#F37254",
      hide_topbar: false
    }
  };
  const openPayModal = options => {
    var rzp1 = new window.Razorpay(options);
    rzp1.open();
  };
  useEffect(() => {
    if (sessionStorage.getItem('AccessType') == 'Agent') {
      GetCommission();
    }
  }, [])
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
    InvoiceGet();
    GetAddress1(billingaddress);
  }, []);
  async function Payment1() {
    var Billingaddress = billingaddress;
    GetAddress(Billingaddress);
  }
  async function Payment2(response) {
    var PaymentType = paymenttype;
    var Billingaddress = billingaddress;
    if (PaymentType == "") {
      toast.error('Please Choose Payment Type')
      return false;
    }
    if (Billingaddress == "") {
      toast.error('Please Enter Customer Code')
      return false;
    }
    {
      PaymentType == "Online" ?
        openPayModal(options) : openPayModal1(response)
    }
  }
  async function openPayModal1(response) {
    var Code = billingaddress1.Text4;
    var Param =
    {
      "mstpaymentid": 0,
      "paymentcode": billnumber,
      "paymentid": "Cash",
      "paymenttype": "Cash",
      "usertype": response.Text1,
      "userid": response.id.toString(),
      "approveusertype": sessionStorage.getItem('AccessType'),
      "approveuserid": sessionStorage.getItem('MUserID'),
      "plan": Plan,
      "months": Planperiod,
      "expiremonth": "",
      "amount": newAmountTextAsNumber.toString(),
      "flag": "I",
      "searchField": response.Text4,
    };
    var url = "/Payment/IUDPayment";
    let _response1 = await Api.IUD(Param, url);
    showAlert(_response1);
    setTimeout(() => {
      const accessType = sessionStorage.getItem('AccessType');
      
      if (accessType === "Employer") {
        navigate('/Employerdashboard');
      } else if (accessType === "jobseeker") {
        navigate('/Jobseekerdashboard');
      } else {
        navigate('/dash');
      }
    }, 2000);
  }
  const showAlert = (_response) => {
    Swal.fire({
      title: "Success",
      text: _response,
      icon: "success",
      confirmButtonText: "OK",
    });
  };
  async function Payment(id, customerusertype, customerid, code1, customername, customerMob, customerAddress) {
    var PaymentId = id;
    var Plan = options.description;
    var Amount1 = options.amount / 100;
    var d = new Date;
    var Code = code1;
    var Param =
    {
      "mstpaymentid": 0,
      "paymentcode": billnumber,
      "paymentid": PaymentId,
      "paymenttype": "Online",
      "usertype": billingaddress11.Text1,
      "userid": billingaddress11.id.toString(),
      "approveusertype": sessionStorage.getItem('AccessType'),
      "approveuserid": sessionStorage.getItem('MUserID'),
      "plan": Plan,
      "months": Planperiod,
      "expiremonth": "",
      "amount": newAmountTextAsNumber.toString(),
      "flag": "I",
      "searchField": billingaddress11.Text4,
      "createdDate": d.getDate() + '-' + (d.getMonth() + 1) + '-' + d.getFullYear() + '@' + d.getHours() + ":"
        + d.getMinutes() + ":" + d.getSeconds()
    };
    var url = "/Payment/IUDPayment";
    let _response = await Api.IUD(Param, url);
    showAlert(_response);

    setTimeout(() => {
      const accessType = sessionStorage.getItem('AccessType');
      
      if (accessType === "Employer") {
        navigate('/Employerdashboard');
      } else if (accessType === "jobseeker") {
        navigate('/Jobseekerdashboard');
      } else {
        navigate('/dash');
      }
    }, 2000);
  }

  async function GetCoupon() {
    var Entered_coupon = couponcode;
    var Coupon_Percentage = Coupon_Percent;

    if (Entered_coupon == Coupon) {

      const discountAmount = (newAmountTextAsNumber * Coupon_Percentage) / 100 || 0;

      setfinEL_amount1(discountAmount);

      var ammmount = newAmountTextAsNumber - discountAmount;

      setDicAmt(ammmount);

      var fiNEL_amount = (ammmount * Tax) / 100 || 0;

      setFINAL_Tax_AMOUNT(fiNEL_amount);

      var Amountt = ammmount + fiNEL_amount;

      setFINAL_Payment_AMOUNT(Amountt);

    } else {
      Swal.fire({
        title: "Error",
        text: "Please Enter Valid Coupon Code",
        icon: "error",
        confirmButtonText: "OK",
      });
    }

  }

  return (
    <>
      <Toaster toastOptions={{ position: "top-right" }} />
      {sessionStorage.getItem('AccessType') == "Employer" ?
        <EmployerBase /> : null}
      {sessionStorage.getItem('AccessType') == "jobseeker" ?
        <JobseekerBase /> : null}
      {sessionStorage.getItem('AccessType') == "Employer"
        || sessionStorage.getItem('AccessType') == "jobseeker" ? null : <Base />}
      <div id="main" class="main">
        <div class="row g-3">
          <div class="col-md-6">
            <label for="deposit Type">Payment Type</label>
            <select id="inputState" class="form-select" value={paymenttype} onChange={(e) => { setPaymenttype(e.target.value) }} >
              <option selected>Payment Type</option>
              {sessionStorage.getItem('AccessType') == "User"
                || sessionStorage.getItem('AccessType') == "Employee" ?
                <>
                  <option value="Cash">Cash</option>
                  <option value="Online">Online</option>
                </>
                :
                <option value="Online">Online</option>}
            </select>
          </div>
          <div class="col-md-6">
            <label>Customer Code</label>
            <input type="text" class="form-control" disabled={true} placeholder="Enter Customer Code"
              value={billingaddress} onChange={(e) => setBillingaddress(e.target.value)} />
          </div>
          <div class="col-md-6">
            <label>Enter Coupon Code</label>
            <input type="text" class="form-control" placeholder="Enter Coupon Code"
              value={couponcode} onChange={(e) => setCouponcode(e.target.value)} />
            <button class="btn btn-primary mt-2" onClick={() => { GetCoupon() }}>Apply</button>
          </div>
        </div>
        <div class="container123 mt-4">
          <div class="container-left">
            <div class="title title-left">Elakiyaa Best Jobs</div>
            <div class="circle">
              <img src={Images.logo} alt="logo" className="userlg_img" />
            </div>
            <div class="container-left-bottom">
              <div class="container-left-bottom-description">
                <p>
                  Near to the Needs.
                </p>
              </div>
            </div>
          </div>
          <div class="container-right-box"></div>
          <div class="container-right">
            <div class="title title-right">Summary</div>
            <div class="stat-box">
              <div class="stat stat-reaction">
                <div class="stat-part-left red">
                  <img src="/assets/images/icon-reaction.svg" alt="" />Plan Amount
                </div>
                <div class="stat-part-right">
                  <span class="blue">{newAmountTextAsNumber}</span>
                </div>
              </div>
              <div class="stat stat-memory">
                <div class="stat-part-left yellow">
                  <img src="/assets/images/icon-memory.svg" alt="" />Discount Amount
                </div>
                <div class="stat-part-right">
                  <span class="blue">{finEL_amount1}</span>
                </div>
              </div>
              <div class="stat stat-reaction">
                <div class="stat-part-left red">
                  <img src="/assets/images/icon-reaction.svg" alt="" />Net Amount
                </div>
                <div class="stat-part-right">
                  <span class="blue">{dicAmt}</span>
                </div>
              </div>
              <div class="stat stat-memory">
                <div class="stat-part-left yellow">
                  <img src="/assets/images/icon-memory.svg" alt="" />Tax Amount
                </div>
                <div class="stat-part-right">
                  <span class="blue">{FINAL_Tax_AMOUNT}</span>
                </div>
              </div>
              <div class="stat stat-verbal">
                <div class="stat-part-left green">
                  <img src="/assets/images/icon-verbal.svg" alt="" />Total Amount
                </div>
                <div class="stat-part-right">
                  <span class="blue">{FINAL_Payment_AMOUNT}</span>
                </div>
              </div>
            </div>
            <button class="btn btn-primary" onClick={() => { Payment1() }} style={{ width: '150px' }} >Pay</button>
          </div>
        </div>
      </div>
    </>
  );
};
export default App;  