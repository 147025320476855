import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import '../Css/Jobs.css';
import JobseekerBase from './Jobseeker/JobseekerBase';
import Api from './Services/ApiCall';
import Swal from 'sweetalert2';
import { BeatLoader } from "react-spinners";
import { useTable, useSortBy, usePagination, useGlobalFilter } from "react-table";
import { Globalfilter } from './Admin/Admin_form/Filter/Globalfilter';
import { format } from 'date-fns';

function Table({ columns, data, walletAmount, applyAmount }) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state,
        setGlobalFilter,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize }
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 }
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    return (
        <div>
            {/* Search Bar */}
            <Globalfilter
                className="form-control"
                filter={state.globalFilter}
                setFilter={setGlobalFilter}
            />

            {/* Table */}
            <table className="table table-striped" {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render("Header")}
                                    <span>
                                        {column.isSorted ? (
                                            column.isSortedDesc ? <i className="fa fa-sort-asc"></i> : <i className="fa fa-sort-desc"></i>
                                        ) : ''}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => (
                                    <td {...cell.getCellProps()} className={cell.column.className ?? ""}>
                                        {cell.render("Cell")}
                                    </td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>

            {/* Pagination */}
            <div className="pagination">
                <nav className="paginate page_numbers" aria-label="Page navigation">
                    <ul className="pagination first_last">
                        <li className="page-item">
                            <a className="page-link" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                                First
                            </a>
                        </li>
                        <li className="page-item">
                            <a className="page-link" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                {"<"}
                            </a>
                        </li>
                        <li className="page-item">
                            <a className="page-link" onClick={() => nextPage()} disabled={!canNextPage}>
                                {">"}
                            </a>
                        </li>
                        <li className="page-item">
                            <a className="page-link" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                                Last
                            </a>
                        </li>
                    </ul>
                </nav>
                <span className="pageno">
                    Page <strong>{pageIndex + 1} of {pageOptions.length}</strong>
                </span>
                <div className='showpagesize'>
                    Show:
                    <select
                        className='showpagecss'
                        value={pageSize}
                        onChange={e => setPageSize(Number(e.target.value))}
                    >
                        {[2, 10, 20, 30, 40, 50].map(size => (
                            <option key={size} value={size}>
                                {size}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </div>
    );
}

export default function Jobs() {
    const [listData, setListData] = useState([]);
    const [walletAmount, setWalletAmount] = useState("0");
    const [applyAmount, setApplyAmount] = useState("0");
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const columns = [
        {
            Header: "ID",
            Cell: ({ row }) => <div>{row.index + 1}</div>,
        },
        { Header: "Job Name", accessor: "Jobtitle" },
        { Header: "Vacancy", accessor: "Vacancy" },
        { Header: "Location", accessor: "Location" },
        {
            Header: "Posted Date",
            accessor: "CreatedDate",
            Cell: ({ value }) => format(new Date(value), 'yyyy-MM-dd')
        },
        { Header: "Qualification", accessor: "Education" },
        { Header: "Role", accessor: "Role" },
        { Header: "Salary", accessor: "SearchField" },
        { Header: "CurrentStatus", accessor: "CurrentStatus" },
        {
            Header: "Apply",
            Cell: ({ row }) => {
                const handleButtonClick = async () => {
                    const Param = { mstjobseekerid: sessionStorage.getItem("MUserID") };
                    const url = "/JobSeekerTable/BindJobSeekerTable2";
                    const _response = await Api.BindGrid(Param, url);
                    const Verified = _response[0].Verified;
                    sessionStorage.setItem("Verified", Verified);

                    if (Verified !== "Verified") {
                        showAlert("Please Complete Your Profile");
                    } else {
                        const jobId = row.original.Mstjoblistid;
                        const intWalletAmount = parseInt(walletAmount);
                        const intApplyAmount = parseInt(applyAmount);

                        if (intWalletAmount >= intApplyAmount) {
                            navigate('/Jobdescription', {
                                state: { ID: jobId, WalletAmount: intWalletAmount, intapplyamount: intApplyAmount }
                            });
                        } else {
                            Swal.fire({
                                title: "Failed",
                                text: "Insufficient Balance",
                                icon: "error",
                                confirmButtonText: "OK",
                            });
                        }
                    }
                };

                return (
                    <button
                        type="button"
                        className="btn btn-info"
                        onClick={handleButtonClick}
                    >
                        View
                    </button>
                );
            }
        }
    ];

    const showAlert = (message) => {
        Swal.fire({
            title: "",
            text: message,
            icon: "error",
            confirmButtonText: "OK",
        }).then(result => {
            if (result.isConfirmed) {
                navigate('/Myprofile', {
                    state: { ID: sessionStorage.getItem("MUserID"), Flag: 'JobProf' }
                });
            }
        });
    };

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            await Promise.all([bindGrid(), get(), bindJobSeeker()]);
            setIsLoading(false);
        };

        fetchData();
    }, []);

    const get = async () => {
        const url = "/EmployerPlanCreation/BindEmployerPlanCreation";
        const response = await Api.BindGrid({ mstemployerplancreationid: 6 }, url);
        setApplyAmount(response[0].Employerplancreationcode);
    };

    const bindGrid = async () => {
        const category = sessionStorage.getItem("AccessType");
        const url = "/Postjobs/BindPostJobs";
        const response = await Api.BindGrid({ mstjoblistid: 0, flag: category }, url);
        setListData(response);
    };

    const bindJobSeeker = async () => {
        const userId = sessionStorage.getItem("MUserID");
        const url = "/JobSeekerTable/BindJobSeekerTable";
        const response = await Api.BindGrid({ Mstjobseekerid: userId, AccessType: 'Jobseeker' }, url);
        setWalletAmount(response[0].Recharge_amount);
    };

    return (
        <>
            <JobseekerBase />
            <div id="main" className="main">
                <div className="pagetitle">
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/Jobseekerdashboard">Home</Link>
                            </li>
                            <li className="breadcrumb-item">Jobs</li>
                        </ol>
                    </nav>
                </div>
                {isLoading ? (
                    <div className="d-flex justify-content-center my-5 pt-5">
                        <div className="pt-5 mt-5">
                            <BeatLoader className="mt-5" />
                        </div>
                    </div>
                ) : (
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body alignleft">
                                <h5 className="card-title">Jobs</h5>
                                <Table
                                    columns={columns}
                                    data={listData}
                                    walletAmount={walletAmount}
                                    applyAmount={applyAmount}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
