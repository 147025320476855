import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import EmployerBase from './Employer/EmployerBase';
import Api from './Services/ApiCall';
import { MDBDataTable } from 'mdbreact';
import { Button, Modal } from 'react-bootstrap';

export default function Employerdashboard() {

  const [popup, setpopup] = useState(false);

  const navigate = useNavigate()
  const [listdata, setlistdata] = useState([]);



  const [jobseekerId, setJobseekerId] = useState(null);
  const [employerId, setEmployerId] = useState(null);
  const [applycandidateReport, setapplycandidateReport] = useState("");
  const [postcount, setPostcount] = useState("Loading...");

  const openPopup = (jobseekerId) => {
    setJobseekerId(jobseekerId);
    setEmployerId(JSON.parse(sessionStorage.getItem('userdetails'))[0]['Mstuserregid']);
    setpopup(true);
  }

  function closeModal2() {
    setpopup(false);
  }
  function post() {
    navigate("/Postjobstable")
  }
  function subscribe() {
    navigate("/Rechargeform")
  }

  async function impressJobseeker() {
    var Param = {
      "mstjobseekerid": jobseekerId,
      "searchField": employerId.toString(),

    };
    var url = "/JobSeekerTable/JobseekerImpress";
    let _response = await Api.IUD(Param, url);
    toast.success(_response);
    closeModal2();
  }

  async function GetApplycandidateReport() {
    var id = sessionStorage.getItem('MUserID');
    var url = "/DropDown/GetApplycandidateReport?id=" + id;
    let _response = await Api.BindDropdown(url)
    let Arry_Title1 = [];
    setapplycandidateReport(_response)
  }
  const [walletAmount, setWalletAmount] = useState("Loading...");
  useEffect(() => {
    // BindGrid(0)
    BindGrid1(0)
    GetApplycandidateReport();
    getGrid()
  }, []);


  async function BindGrid(id) {
    var Param = { mstjobseekerid: id }
    var url = "/JobSeekerTable/BindJobSeekerTable";
    let _response1 = await Api.BindGrid(Param, url)

    if (Array.isArray(_response1)) {
      setlistdata(_response1)
    } else {
      setlistdata([])
    }
  }

  async function BindGrid1(id) {
    var Param = { flag: "" }
    var url = "/Postjobs/BindPostJobs1";
    let _response1 = await Api.BindGrid(Param, url)
    setPostcount(_response1.length)
  }

  async function getGrid() {
    var Id = sessionStorage.getItem("MUserID")
    var Param = { Mstuserregid: Id }
    var url = "/UserReg/GetUserRegtable1";
    let _response = await Api.BindGrid(Param, url)
    setWalletAmount(_response[0].Recharge_amount)
  }



  return (

    <>
      <EmployerBase />
      <div id="main" class="main">
        <Toaster toastOptions={{ position: "top-right" }} />
        <h1>Employer Dashboard</h1>
        <div></div>
        <div className='row'>
          <div className="col-xl-4">
            <div className="card">
              <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">
                <h2>Your Wallet Amount is</h2>
                <h3>Rs: {walletAmount}</h3>
              </div>
            </div>
          </div>
          <div className="col-xl-4">
            <div className="card">
              <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">
                <h2>Your Job Post Count is</h2>
                <h3>{postcount}</h3>
              </div>
            </div>
          </div>
        </div>
        <section class="section profile">
          <div class="row mt-5">
            <div className="col-xl-2">
            </div>
            <div className="col-xl-8" >
              <div className="card">
                <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">

                  <h2>Please Post the Jobs here.</h2>
                  <button class="btn btn-primary Add" onClick={post}>Post Job</button>

                </div>
              </div>
            </div>
            <div className="col-xl-2">
            </div>
          </div>
        </section>


      </div>
    </>
  )
}
