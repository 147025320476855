import { useLocation } from 'react-router-dom';

function ErrorPage() {
    const location = useLocation();
    const errorMessage = location.state?.message || "An unexpected error occurred. Please try again later.";

    return (
        <div className="error-page">
            <h1>Error</h1>
            <p>{errorMessage}</p>
            <button onClick={() => window.location.reload()}>Retry</button>
        </div>
    );
}

export default ErrorPage;
