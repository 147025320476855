import React, { useState, useEffect } from 'react'
import Base from '../../Base';
import Api from '../../Services/ApiCall';
import { Link, useLocation, useNavigate } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import Swal from 'sweetalert2';

export default function Bankaccountform() {
    const location = useLocation();
    const { ID, Flag } = location.state;


    useEffect(() => {

        GetCountryList()
        GetCityList()
        if (ID != 0) {
            // GetMenu(0) 
            Get(ID)
        }
    }, []);
    const navigate = useNavigate();

    const [name, setName] = useState("");
    const [address, setAddress] = useState("");
    const [country, setCountry] = useState("");
    const [state, setState] = useState("");
    const [district, setDistrict] = useState("");
    const [city, setCity] = useState("");
    const [pincode, setPincode] = useState("");
    const [accountnumber, setAccountnumber] = useState("");
    const [bankname, setBankname] = useState("");
    const [branchname, setBranchname] = useState("");
    const [ifsccode, setIfsccode] = useState("");
    const [upiid, setUpiid] = useState("");
    const [CountryList, setCountryList] = useState([]);
    const [StateList, setStateList] = useState([]);
    const [CityList, setCityList] = useState([]);







    async function GetCountryList() {
        let _response = await Api.DirectApiCall('country', "", "")
        setCountryList(_response)
    }
    async function GetStateList(value) {
        setCountry(value)
        var Param = { country: value }
        let _response = await Api.DirectApiCall('state', value)
        setStateList(_response.states)
    }
    async function GetCityList(value) {
        setState(value)
        // let _response = await Api.DirectApiCall('city',country,value)
        // setCityList(_response)       
        var url = "/DropDown/GetCities";
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setCityList(_response)
    }






    async function IUDOperation(flag, id) {
        var Name = name;
        var Address = address;
        var State = state;
        var District = district;
        var City = city;
        var Pincode = pincode;
        var Accountnumber = accountnumber;
        var Bankname = bankname;
        var Branchname = branchname;
        var IFSCCode = ifsccode;
        var UPIID = upiid;

        if (Name == "") {
            toast.error('Please enter a Name')
            return false;
        }
        else {
            if (Name.length < 3) {
                toast.error('Please enter a valid Name')
                return false;
            }
        }

        if (Address == "") {
            toast.error('Please enter Address')
            return false;
        }
        if (State == "") {
            toast.error('Please Select state')
            return false;
        }
        // if(District ==""){
        //  toast.error('Please Select District')
        //  return false;
        // }
        if (City == "") {
            toast.error('Please Select City')
            return false;
        }
        if (Pincode == "") {
            toast.error('Please enter Pincode')
            return false;
        }


        if (Accountnumber == "") {
            toast.error('Please enter Accountnumber')
            return false;
        }
        if (Bankname == "") {
            toast.error('Please enter Bankname')
            return false;
        }
        if (Branchname == "") {
            toast.error('Please enter Branch name')
            return false;
        }
        if (IFSCCode == "") {
            toast.error('Please enter IFSC')
            return false;
        }

        var Param = {
            "mstbankaccountcreationid": ID,
            "bankaccountcreationcode": "",
            "accountholdername": Name,
            "address": Address,
            "active": "",
            "searchField": "",
            "flag": Flag,
            "createdBy": 0,
            "createdDate": "2022-11-24T07:02:00.522Z",
            "selectState": State,
            "selectDistrict": District,
            "selectCity": City,
            "ifscCode": IFSCCode,
            "pincode": Pincode,
            "accountNumber": Accountnumber,
            "bankName": Bankname,
            "branchName": Branchname,
            "upiid": UPIID
        }
        var url = "/BankAccount/IUDBankAccount";
        let _response = await Api.IUD(Param, url)
        showAlert(_response)
        setTimeout(() => {
            navigate('/bankaccounttable')
        }, 2000)
    }
    const showAlert = (_response) => {
        Swal.fire({
            title: "Success",
            text: _response,
            icon: "success",
            confirmButtonText: "OK",
        });
    };


    async function Get(id) {
        var Param = { Mstbankaccountcreationid: id }
        var url = "/BankAccount/BindBankAccount";
        // //console.log("Param",Param,"URL",url)
        let _response = await Api.BindGrid(Param, url)

        setName(_response[0].Accountholdername);
        setAddress(_response[0].Address);
        setPincode(_response[0].Pincode);
        setAccountnumber(_response[0].AccountNumber);
        setBankname(_response[0].BankName);
        setBranchname(_response[0].BranchName);
        setIfsccode(_response[0].IfscCode);
        setState(_response[0].SelectState)
        setDistrict(_response[0].SelectDistrict)
        setCity(_response[0].SelectCity)
        setUpiid(_response[0].Upiid)

    }

    return (
        <div>
            <Toaster toastOptions={{ position: "top-right" }} />
            <Base Bankaccount={true} />
            <div id="main" class="main">
                <div class="pagetitle">

                    <nav>
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><Link to="/dash">Home</Link></li>
                            <li class="breadcrumb-item">Bank/Cash</li>
                            <li class="breadcrumb-item active">Bank Account</li>
                        </ol>
                    </nav>
                </div>

                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">Bank Account Creation</h5>


                        <div class="row g-3">
                            <div class="col-md-6">
                                <label for="holdername">Account Holder Name</label>
                                <input type="text" class="form-control" disabled={Flag == "D" || Flag == "null" ? true : false} value={name} placeholder="Account Holder Name" onChange={(e) => setName(e.target.value)} />
                            </div>
                            <div class="col-6">
                                <label for="accountnumber">Account Number</label>
                                <input type="text" class="form-control" disabled={Flag == "D" || Flag == "null" ? true : false} value={accountnumber} placeholder="Account Number" onChange={(e) => setAccountnumber(e.target.value)} />
                            </div>


                            <div class="col-6">
                                <label for="bankname">Bank Name</label>
                                <input type="text" class="form-control" disabled={Flag == "D" || Flag == "null" ? true : false} value={bankname} placeholder="Bank Name" onChange={(e) => setBankname(e.target.value)} />
                            </div>
                            <div class="col-6">
                                <label for="branch name">Branch Name</label>
                                <input type="text" class="form-control" disabled={Flag == "D" || Flag == "null" ? true : false} value={branchname} placeholder="Branch name" onChange={(e) => setBranchname(e.target.value)} />
                            </div>
                            <div class="col-6">
                                <label for="ifsccode">IFSC Code</label>
                                <input type="text" class="form-control" disabled={Flag == "D" || Flag == "null" ? true : false} value={ifsccode} placeholder="IFSC Code" onChange={(e) => setIfsccode(e.target.value)} />
                            </div>

                            <div class="col-6">
                                <label for="upiid">UPI Id</label>
                                <input type="text" class="form-control" disabled={Flag == "D" || Flag == "null" ? true : false} value={upiid} placeholder="UPI Id" onChange={(e) => setUpiid(e.target.value)} />
                            </div>


                            <div class="col-md-6">
                                <label for="country">Country</label>
                                <select id="inputState" class="form-select" disabled={Flag == "D" || Flag == "null" ? true : false} value={country} onChange={(e) => GetStateList(e.target.value)}>
                                    <option selected>Country</option>
                                    {CountryList.map((i, index) => (
                                        <option value={i.country.toLowerCase()}>{i.country}</option>
                                    ))}

                                </select>
                            </div>

                            <div class="col-md-6">
                                <label for="state">State</label>
                                <select id="inputState" class="form-select" disabled={Flag == "D" || Flag == "null" ? true : false} value={state} onChange={(e) => GetCityList(e.target.value)}>
                                    <option selected >State</option>
                                    {StateList.map((i, index) => (
                                        <option value={i.name.toLowerCase()}>{i.name}</option>
                                    ))}

                                </select>
                            </div>
                            <div class="col-md-6">
                                <label for="city">City</label>
                                <select id="inputState" class="form-select" disabled={Flag == "D" || Flag == "null" ? true : false} value={city} onChange={(e) => setCity(e.target.value)}>

                                    <option >City</option>
                                    {CityList.map((i, index) => (
                                        <option value={i.Text}>{i.Text}</option>
                                    ))}
                                </select>
                            </div>
                            <div class="col-md-6">
                                <label for="address">Address</label>
                                <textarea class="form-control" disabled={Flag == "D" || Flag == "null" ? true : false} value={address} placeholder="Address" onChange={(e) => setAddress(e.target.value)}>
                                </textarea>
                            </div>

                            <div class="col-6">
                                <label for="pincode">Pincode</label>
                                <input type="text" class="form-control" disabled={Flag == "D" || Flag == "null" ? true : false} value={pincode} placeholder="Pincode" onChange={(e) => setPincode(e.target.value)} />
                            </div>



                            <div class="text-center">
                                <button type="button" class="btn btn-primary" onClick={IUDOperation}>Submit</button>
                                <button type="reset" class="btn btn-info">
                                    <Link to='/bankaccounttable'>
                                        Back
                                    </Link>
                                </button>
                            </div>
                        </div>

                    </div>
                </div>


            </div>
        </div>
    )
}
