import React, { useState, useEffect } from 'react'
import Api from '../Services/ApiCall';
import Base from '../Base';

export default function EmployeeDashboard() {

    const [jobseekerdata, setJobseekerdata] = useState([]);
    const [userDatedata, setUserDatedata] = useState([]);



    useEffect(() => {
        bindCommision();
        bindDateCommision();
    }, []);

    async function bindDateCommision() {
        var Id = sessionStorage.getItem("MUserID");
        const Param={"mstuserregid": Id,
        "msusertregcode": "",
        "name": "",
        "mobilenumber": "",
        "pannumber": "",
        "qualification": "",
        "plandetails": 0,
        "usertype": 0,
        "amount": 0,
        "empbranch": "",
        "incharge": "",
        "emailid": "",
        "aadhaarnumber": "",
        "rolecab": "",
        "agGradetype": "",
        "agGrade": "",
        "agDesignation": "",
        "department": "",
        "agAgentcode": "",
        "officecode": "",
        "reportingempcode": "",
        "city": "",
        "state": "",
        "country": "",
        "address": "",
        "pincode": "",
        "accountholdername": "",
        "accountnumber": "",
        "ifsccode": "",
        "branchname": "",
        "bankname": "",
        "password": "",
        "companyname": "",
        "companyphoneNo": "",
        "companyaddress": "",
        "companygst": "",
        "confirmpassword": "",
        "rolevscab": "",
        "active": "",
        "flag": "",
        "searchField": "",
        "createdBy": 0,
        "createdDate": "2024-05-20T15:24:25.824Z",
        "subscribe": "",
        "plantype": "",
        "planperiod": "",
        "activestatus": "",
        "planamount": 0,
        "accessType": "",
        "organizationtype": "",
        "industrytype": "",
        "designation": "",
        "idproof": "",
        "district": "",
        "aadharImage": "",
        "panImage": "",
        "bankImage": ""}
        const url = `/Dashboard/GetUserRegisteredData`;
        let _response1 = await Api.BindGrid(Param, url)
        setUserDatedata(_response1)
    }
    async function bindCommision() {
        var Id = sessionStorage.getItem("MUserID");
        const Param={"mstuserregid": Id,
        "msusertregcode": "",
        "name": "",
        "mobilenumber": "",
        "pannumber": "",
        "qualification": "",
        "plandetails": 0,
        "usertype": 0,
        "amount": 0,
        "empbranch": "",
        "incharge": "",
        "emailid": "",
        "aadhaarnumber": "",
        "rolecab": "",
        "agGradetype": "",
        "agGrade": "",
        "agDesignation": "",
        "department": "",
        "agAgentcode": "",
        "officecode": "",
        "reportingempcode": "",
        "city": "",
        "state": "",
        "country": "",
        "address": "",
        "pincode": "",
        "accountholdername": "",
        "accountnumber": "",
        "ifsccode": "",
        "branchname": "",
        "bankname": "",
        "password": "",
        "companyname": "",
        "companyphoneNo": "",
        "companyaddress": "",
        "companygst": "",
        "confirmpassword": "",
        "rolevscab": "",
        "active": "",
        "flag": "",
        "searchField": "",
        "createdBy": 0,
        "createdDate": "2024-05-20T15:24:25.824Z",
        "subscribe": "",
        "plantype": "",
        "planperiod": "",
        "activestatus": "",
        "planamount": 0,
        "accessType": "",
        "organizationtype": "",
        "industrytype": "",
        "designation": "",
        "idproof": "",
        "district": "",
        "aadharImage": "",
        "panImage": "",
        "bankImage": ""}
        var url = "/Dashboard/GetUserRegisteredJobseekerData";
        let _response1 = await Api.BindGrid(Param, url)
        setJobseekerdata(_response1)
    }


    return (
        <div>
            <Base />
            <div id="main" class="main">
                <h1>Employee Dashboard</h1>
                <div></div>
                <div className='row'>
                    <div className="col-xl-4">
                        <div className="card">
                            <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">
                                <h2>Total Employer and Agent Registration Count</h2>
                                <h3>{userDatedata.Item2 ? userDatedata.Item2 : 0}</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4">
                        <div className="card">
                            <div style={{height: '205px'}} className="card-body profile-card pt-4 d-flex flex-column align-items-center">
                                <h2>Total Jobseeker Registration Count</h2>
                                <h3>{jobseekerdata.Item2 ? jobseekerdata.Item2 : 0}</h3>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}