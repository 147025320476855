import React, { useState, useEffect } from 'react'
import Base from '../../Base';
import Api from '../../Services/ApiCall';
import { Link, useLocation, useNavigate } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import '../../../App.css';
import { Button, Modal } from 'react-bootstrap';
import EmployerBase from '../../Employer/EmployerBase';
import JobseekerBase from '../../Jobseeker/JobseekerBase';
import Swal from 'sweetalert2';
export default function Subscribeform() {
    const navigate = useNavigate();
    const location = useLocation();
    const { ID, Flag, Code } = location.state;
    useEffect(() => {
        GetTax();
    }, []);
    const [choosePlan, setchoosePlan] = useState("");
    const [selectedPlan, setselectedPlan] = useState("");
    const [selectedPlan1, setselectedPlan1] = useState("");
    const [selectedPlan2, setselectedPlan2] = useState("");
    const [plan, setPlan] = useState("");
    const [plan1, setPlan1] = useState("");
    const [plan2, setPlan2] = useState("");
    const [plantype, setPlantype] = useState("");
    const [plandetail, setPlandetail] = useState("");
    const [partner_Registration, setPartner_Registration] = useState("");
    const [jobseeker_Registration, setJobseeker_Registration] = useState("");
    const [employer_Registration, setEmployer_Registration] = useState("");
    const [plan_Amount, setPlan_Amount] = useState("");
    const [amount, setAmount] = useState("");
    const [amount1, setAmount1] = useState("");
    const [amount2, setAmount2] = useState("");
    const [basic, setBasic] = useState("");
    const [standard, setStandard] = useState("");
    const [premium, setPremium] = useState("");
    const [Tax, setTax] = useState("");
    const [disabled, setDisabled] = useState(true);
    const [coupon, setCoupon] = useState("");
    const [coupon_Percent, setCoupon_Percent] = useState("");
    const [plan_id, setPlan_id] = useState("");

    function handleChange(e) {
        const { name, value } = e.target;

        this.setState({
            [name]: value
        });
    };
    async function GetPlanPerioid(value) {
        setchoosePlan(value)
        setAmount([])
        var id = value;
        var url = "/DropDown/GetPlanForJobSeeker?id=" + id;
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setPlan(_response);
        setPlan_id(_response[0].Id);
        setPartner_Registration(_response[0].Text);
        setJobseeker_Registration(_response[0].Text3);
        setEmployer_Registration(_response[0].Text1);
        setPlan_Amount(_response[0].Text2);
        setCoupon(_response[0].Text4);
        setCoupon_Percent(_response[0].Text5);
    }
    async function GetAmount(e, name) {
        setselectedPlan(e.target.value)
        var id = (e.target.value)
        var url = "/DropDown/GetPlandetailamount?id=" + id;
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setAmount(_response)
        setTax(_response[0].Text1);
    }
    const [popup, setpopup] = useState(false);
    const [Approved, setApproved] = useState("");
    const [AppUserid, setAppUserid] = useState('');
    const [Rejected, setRejected] = useState("");
    const openPopup = (id) => {
        setAppUserid(id)
        setpopup(true);
    }
    function closeModal2() {
        setpopup(false);
    }
    async function IUDOperation(flag, id) {
        var Plantype = plantype;
        var Plandetail = plandetail;
        var Amount = amount;
        var Param = {
            "mstemployerplancreationid": ID,
            "employerplancreationcode": "",
            "plantype": Plantype,
            "plandetail": Plandetail,
            "amount": Amount,
            "active": "",
            "searchField": "",
            "flag": Flag,
            "createdBy": 0,
            "createdDate": "2022-10-14T13:36:31.656Z"
        };
        var url = "/EmployerPlanCreation/IUDEmployerPlanCreation";
        let _response = await Api.IUD(Param, url);
        showAlert(_response);
        setTimeout(() => {
            navigate('/employerplancreationtable')
        }, 1000)
    }
    const showAlert = (_response) => {
        Swal.fire({
            title: "Success",
            text: _response,
            icon: "success",
            confirmButtonText: "OK",
        });
    };
    async function Get(id) {
        var Param = { mstemployerplancreationid: id }
        var url = "/EmployerPlanCreation/BindEmployerPlanCreation";
        let _response = await Api.BindGrid(Param, url)
        setPlantype(_response[0].Plantype);
        setPlandetail(_response[0].Plandetail);
        setAmount(_response[0].Amount);
    }

    async function GetTax() {
        var Param = { msttaxid: 0 }
        var url = "/Notification/BindNotification";
        let _response = await Api.BindGrid(Param, url)
        setTax(_response[0].Messages);
      }

    return (
        <div>
            <Toaster toastOptions={{ position: "top-right" }} />
            {sessionStorage.getItem('AccessType') == "jobseeker" ?
                <JobseekerBase /> : <Base />}
            <div id="main" class="main">
                <div class="pagetitle">
                    <nav>
                        <ol class="breadcrumb">
                            {Flag == "SUB" ?
                                <li class="breadcrumb-item"><Link to="/dash">Home</Link></li>
                                : null}
                            {Flag == "EmpSUB" ?
                                <li class="breadcrumb-item"><Link to="/Employerdashboard">Home</Link></li>
                                : null}
                            {Flag == "JobSUB" ?
                                <li class="breadcrumb-item"><Link to="/Jobseekerdashboard">Home</Link></li>
                                : null}

                            <li class="breadcrumb-item">Subscribe Form</li>
                        </ol>
                    </nav>
                </div>
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">Plan Subscription</h5>
                        <div class="row g-3">
                            <section >
                                <div class="container">
                                    <div class="outer-box">
                                        <div class="row">
                                            <div class="pricing-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
                                                <div class="inner-box">
                                                    <div class="icon-box">
                                                        <div class="icon-outer"><i class="fas fa-paper-plane"></i></div>
                                                    </div>
                                                    <div class="price-box">
                                                        <div class="form-check subscribe">
                                                            <input class="form-check-input" type="radio" name="flexRadioDefault" value="Diamond Partner" onChange={(e) => { GetPlanPerioid(e.target.value) }} />
                                                            <label class="form-check-label sub1" for="Diamond Partner" >
                                                                Diamond Partner
                                                            </label>
                                                        </div>
                                                        <label for="plandetails">Plan Amount</label>
                                                        {choosePlan == "Diamond Partner" ?
                                                            <>
                                                                {plan && plan.map((i, index) => (
                                                                    <input
                                                                        type="number"
                                                                        disabled={true}
                                                                        className="form-control"
                                                                        placeholder="Amount"
                                                                        value={i.Text2}
                                                                    />
                                                                ))}
                                                                <input
                                                                    type="button"
                                                                    className="btn btn-primary mar"
                                                                    onClick={() => openPopup()}
                                                                    value="Buy plan"
                                                                />
                                                            </>
                                                            :
                                                            <input type="number" disabled={true} class="form-control" placeholder="Amount" value={""} />
                                                        }
                                                        {/* <input type="button" class="btn btn-primary mar" onClick={() => openPopup()} value="Buy plan" /> */}
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="pricing-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="400ms">
                                                <div class="inner-box">
                                                    <div class="icon-box">
                                                        <div class="icon-outer"><i class="fas fa-gem"></i></div>
                                                    </div>
                                                    <div class="price-box">
                                                        <div class="form-check subscribe">
                                                            <input class="form-check-input" type="radio" name="flexRadioDefault" value="Silver Partner"
                                                                onChange={(e) => { GetPlanPerioid(e.target.value) }} />
                                                            <label class="form-check-label sub1" for="Silver Partner" >
                                                                Silver Partner
                                                            </label>

                                                        </div>

                                                        <label for="plandetails">Plan Amount</label>
                                                        {choosePlan == "Silver Partner" ?
                                                            <>
                                                                {plan && plan.map((i, index) => (
                                                                    <input
                                                                        type="number"
                                                                        disabled={true}
                                                                        className="form-control"
                                                                        placeholder="Amount"
                                                                        value={i.Text2}
                                                                    />
                                                                ))}
                                                                <input
                                                                    type="button"
                                                                    className="btn btn-primary mar"
                                                                    onClick={() => openPopup()}
                                                                    value="Buy plan"
                                                                />
                                                            </>
                                                            :
                                                            <input type="number" disabled={true} class="form-control" placeholder="Amount" value={""} />
                                                        }
                                                        {/* <input type="button" class="btn btn-primary mar" onClick={() => openPopup()} value="Buy plan" /> */}
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="pricing-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="800ms">
                                                <div class="inner-box">
                                                    <div class="icon-box">
                                                        <div class="icon-outer"><i class="fas fa-rocket"></i></div>
                                                    </div>
                                                    <div class="price-box">
                                                        <div class="form-check subscribe">
                                                            <input class="form-check-input" type="radio" name="flexRadioDefault"
                                                                value="Gold Partner"
                                                                onChange={(e) => { GetPlanPerioid(e.target.value) }} />
                                                            <label class="form-check-label sub1" for="Gold Partner" >
                                                                Gold Partner</label>

                                                        </div>

                                                        <label for="plandetails">Plan Amount</label>
                                                        {choosePlan === "Gold Partner" ? (
                                                            <>
                                                                {plan && plan.map((i, index) => (
                                                                    <input
                                                                        type="number"
                                                                        disabled={true}
                                                                        className="form-control"
                                                                        placeholder="Amount"
                                                                        value={i.Text2}
                                                                    />
                                                                ))}
                                                                <input
                                                                    type="button"
                                                                    className="btn btn-primary mar"
                                                                    onClick={() => openPopup()}
                                                                    value="Buy plan"
                                                                />
                                                            </>
                                                        ) : (
                                                            <input
                                                                type="number"
                                                                disabled={true}
                                                                className="form-control"
                                                                placeholder="Amount"
                                                                value=""
                                                            />
                                                        )}

                                                        {/* <input type="button" class="btn btn-primary mar" onClick={() => openPopup()} value="Buy plan" /> */}

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={popup} className='model-popup-design' style={{ marginBottom: "100px" }}>
                <Modal.Header classname="Userlgnmodalhead">
                    <Modal.Title>
                        Plan confirmation
                    </Modal.Title>
                    <button type="button" class="btn-close" data-dismis="modal" onClick={closeModal2} arial-label="Close"></button>
                </Modal.Header>
                <div className='loginmodalbody'>
                    <Modal.Body className='testing-test' >
                        <div>
                            {choosePlan == "Diamond Partner" ?
                                <>
                                    <h5>Dear Agent You Selected the Diamond Partner Plan.</h5>
                                    <div></div>
                                    <h6>Your Plan Amount is : {plan_Amount}</h6>
                                    <h6>You Get the Partner Registraion Amount is : {partner_Registration}</h6>
                                    <h6>You Get the Jobseeker Registraion Amount is : {jobseeker_Registration}</h6>
                                    <h6>You Get the Employer Registraion Amount is : {employer_Registration}</h6>
                                </>
                                : null}
                            {choosePlan == "Silver Partner" ?
                                <>
                                    <h5>Dear Agent You Selected the Silver Partner Plan.</h5>
                                    <div></div>
                                    <h6>Your Plan Amount is : {plan_Amount}</h6>
                                    <h6>You Get the Partner Registraion Amount is : {partner_Registration}</h6>
                                    <h6>You Get the Jobseeker Registraion Amount is : {jobseeker_Registration}</h6>
                                    <h6>You Get the Employer Registraion Amount is : {employer_Registration}</h6>
                                </>
                                : null}
                            {choosePlan == "Gold Partner" ?
                                <>
                                    <h5>Dear Agent You Selected the Gold Partner Plan.</h5>
                                    <div></div>
                                    <h6>Your Plan Amount is : {plan_Amount}</h6>
                                    <h6>You Get the Partner Registraion Amount is : {partner_Registration}</h6>
                                    <h6>You Get the Jobseeker Registraion Amount is : {jobseeker_Registration}</h6>
                                    <h6>You Get the Employer Registraion Amount is : {employer_Registration}</h6>
                                </>
                                : null}
                        </div>
                    </Modal.Body>
                </div>
                <Modal.Footer>
                    <Button variant="secondary" >
                        <Link to="/Payment"
                            state={{ Amount: plan_Amount, Code: Code, Planperiod: plan_id, Plan: choosePlan, ID: 0, Tax: Tax ,Coupon:coupon,Coupon_Percent:coupon_Percent}}>
                            Ok
                        </Link>
                    </Button>
                    <Button variant="secondary" onClick={closeModal2}  >
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}


