import React, { useState, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import Base from '../Base';
import Api from '../Services/ApiCall';
import { Modal, Button } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';


const GridExample = () => {
  const navigate = useNavigate();
  const [rowData, setRowData] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [showPopup1, setShowPopup1] = useState(false);

  useEffect(() => {
    fetchDataAndSetState();
  }, []);

  const fetchDataAndSetState = async () => {
    try {
      var Param = { mstemployerplancreationid: 0 }
      var url = "/EmployerPlanCreation/BindEmployerPlancreation";
      let _response1 = await Api.BindGrid(Param, url);
      setRowData(_response1);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const openPopup = (rowData) => {
    setSelectedRowData(rowData);
    Get(rowData.id)
    setShowPopup(true);
  };
  const openPopup1 = (rowData) => {
    setShowPopup(false);
    setShowPopup1(true);
  };

  const closePopup1 = () => {
    setSelectedRowData(null);
    setShowPopup1(false);
  };
  const closePopup = () => {
    setSelectedRowData(null);
    setShowPopup(false);
  };

  const onCellClicked = (params) => {
    openPopup({ id: params.data.Mstemployerplancreationid, code: params.data.Plantype });
  };

  const columnDefs = [
    // { headerName: 'Id', field: 'Mstemployerplancreationid', filter: 'agNumberColumnFilter', enableFilter: true },
    { headerName: 'Plan Type', field: 'Plantype', filter: true, enableFilter: true },
    { headerName: 'Plan Detail', field: 'Plandetail', filter: true, enableFilter: true },
    { headerName: 'Amount', field: 'Amount', filter: true, enableFilter: true },
    { headerName: 'Plan Date', field: 'SearchField', filter: true, enableFilter: true },
  ];

  const [quickFilterText, setQuickFilterText] = useState("");

  const onQuickFilterChange = (event) => {
    setQuickFilterText(event.target.value);
  };



  async function IUD_Delete() {
    openPopup1();
  }


  const [plantype, setPlantype] = useState("");
  const [plandetail, setPlandetail] = useState("");
  const [amount, setAmount] = useState("");
  const [plandate, setPlandate] = useState("");
  const [tax, setTax] = useState("");


  async function IUDOperation() {
    var Plantype = plantype;
    var Plandetail = plandetail;
    var Amount = amount;
    var Plandate = plandate;
    var Tax = tax;

    var ID = selectedRowData.id;

    var Param = {
      "mstemployerplancreationid": ID,
      "employerplancreationcode": Tax,
      "plantype": Plantype,
      "plandetail": Plandetail,
      "amount": Amount,
      "active": "",
      "searchField": Plandate,
      "flag": "U",
    };
    var url = "/EmployerPlanCreation/IUDEmployerPlanCreation";
    let _response = await Api.IUD(Param, url);
    showAlert(_response);
    setTimeout(() => {
      navigate('/officetypecreationtable')
    }, 1000)

  }
  const showAlert = (_response) => {
    Swal.fire({
      title: "Success",
      text: _response,
      icon: "success",
      confirmButtonText: "OK",
    });
  };

  async function Get(id) {
    var Param = { mstemployerplancreationid: id }
    var url = "/EmployerPlanCreation/BindEmployerPlanCreation";
    let _response = await Api.BindGrid(Param, url)
    setPlantype(_response[0].Plantype);
    await setPlandetail(_response[0].Plandetail);
    setAmount(_response[0].Amount);
    setPlandate(_response[0].SearchField);
    setTax(_response[0].Employerplancreationcode);
  }

  return (
    <div>
      <Base />
      <div id="main" className="main">
        <input
          type="text"
          placeholder="Search..."
          onChange={onQuickFilterChange}
          style={{ marginBottom: '10px' }}
        />
        <button type="button" class="btn btn-primary Add" style={{ background: '#0782d0ed' }}>
          <Link to={'/employerplancreationform'}
            state={{ ID: 0, Flag: 'I' }}
            style={{ color: "white" }}
          >
            Add
          </Link>
        </button>
        <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
          <AgGridReact
            rowData={rowData}
            columnDefs={columnDefs}
            pagination={true}
            paginationPageSize={10}
            domLayout='autoHeight'
            floatingFilter={true}
            enableBrowserTooltips={true}
            onCellClicked={onCellClicked}
            quickFilterText={quickFilterText}
          />
        </div>
        {showPopup && (
          <Modal show={true} onHide={closePopup} dialogClassName="custom-modal">
            <Modal.Header closeButton>
              <Modal.Title> {selectedRowData.code}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div class="row g-3">

                <div class="col-md-6">
                  <label for="plantype">Plan Type</label>
                  <select id="inputstate" class="form-select" value={plantype} onChange={(e) => setPlantype(e.target.value)}>
                    <option>Plan Type</option>
                    <option>Basic</option>
                    <option>Standard</option>
                    <option>Premium</option>
                  </select>
                </div>
                <div class="col-md-6">
                  <label for="plandetails">Plan Detaill</label>
                  <select id="inputstate" class="form-select" value={plandetail} onChange={(e) => setPlandetail(e.target.value)}>
                    <option>Select Plan Detail</option>
                    <option>Custom</option>
                    <option>1 Month</option>
                    <option>3 Month</option>
                    <option>6 Month</option>
                    <option>9 Month</option>
                    <option>1 Year</option>
                  </select>
                </div>
                <div class="col-md-6">
                  <label for="amount">Amount</label>
                  <input type="number" class="form-control no-spinner" value={amount} placeholder="Amount" onChange={(e) => setAmount(e.target.value)} />
                </div>
                {plandetail == "Custom" ?
                  <div class="col-md-6">
                    <label for="plandate">Plan Date</label>
                    <input type="text" class="form-control" value={plandate} placeholder="Enter Plan Date" onChange={(e) => setPlandate(e.target.value)} />
                  </div>
                  : null}
                <div class="col-md-6">
                  <label for="tax">Tax Percent</label>
                  <input type="number" class="form-control no-spinner" valuetax={tax} placeholder="Tax" onChange={(e) => setTax(e.target.value)} />
                </div>

                <div class="text-center">
                  <button type="button" class="btn btn-primary" onClick={IUDOperation}>Update</button>
                  <button type="reset" class="btn btn-info" onClick={IUD_Delete} >Delete</button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        )}
        {showPopup1 && (
          <Modal show={true} onHide={closePopup1} >
            <Modal.Header closeButton>
              <Modal.Title> {selectedRowData.code}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Are You sure want to Delete?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary">
                Close
              </Button>
              <Button variant="secondary" >
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    </div >
  );
};

export default GridExample;