import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom";
import Api from '../Services/ApiCall';
import EmployerBase from '../Employer/EmployerBase'
import { useLocation } from "react-router-dom";
import {
    validPhoneNumberRegex,
    validPasswordRegex,
    validEmailRegex,
    validOnlyEmailRegex,
    maxLength,
    numberMethod,
} from '../CommanRules';
import toast, { Toaster } from 'react-hot-toast';
import Swal from 'sweetalert2';
import { Button, Modal } from 'react-bootstrap';

export default function Employer_profile() {
    const location = useLocation();
    const { UserT, Page } = location.state;
    const Flag = 'U';
    const ID = sessionStorage.getItem('MUserID')
    useEffect(() => {
        set_Usertype(UserT)
        GetMenu(0)
        GetQualification()
        bindStaffGradeDdl(0)
        getEmployeeCode()
        GetPlandetail()
        GetAmount()
        GetDistrict()
        GetDepartment()
        GetCountryList()
        GetCityList()
        if (ID != 0) {
            Get(ID)
        }
        else {
            GetDepartment()
        }
    }, []);
    const navigate = useNavigate();
    const [name, setName] = useState("");
    const [phone, setPhonenum] = useState("");
    const [pannumber, setPannum] = useState("");
    const [aadharnumber, setAadhaarnum] = useState("");
    const [email, setEmail] = useState("");
    const [qualification, setQualification] = useState([]);
    const [usertype, setUsertype] = useState([]);
    const [department, setDepartment] = useState([]);
    const [selectdepartment, setselectDepartment] = useState("");
    const [CountryList, setCountryList] = useState([]);
    const [StateList, setStateList] = useState([]);
    const [CityList, setCityList] = useState([]);
    const [selectedGradeType, setselectedGradeType] = useState("");
    const [selectedGrade, setselectedGrade] = useState("");
    const [user, setUser] = useState("");
    const [address, setAddress] = useState("");
    const [grade, setGrade] = useState("");
    const [designation, setDesignation] = useState("");
    const [agentcode, setAgentcode] = useState("");
    const [officecode, setOfficecode] = useState("");
    const [_Usertype, set_Usertype] = useState("");
    const [ListMenu, setListMenu] = useState([]);
    const [reportempcode, setReportempcode] = useState("")
    const [state, setState] = useState("")
    const [country, setCountry] = useState("")
    const [city, setCity] = useState("")
    const [pincode, setPincode] = useState("")
    const [acholder, setAcholder] = useState("")
    const [accountno, setAccountno] = useState("")
    const [ifsccode, setIfsccode] = useState("")
    const [branchname, setBranchname] = useState("")
    const [bankname, setbankname] = useState("")
    const [reportingempcode, setreportingempcode] = useState("")
    const [accountholdername, seaccountholdername] = useState("")
    const [selectedQualiify, setselectedQualiify] = useState([]);
    const [gradetype, setgradetype] = useState([]);
    const [staffGrade, setStaffGrade] = useState([]);
    const [selectedStaffGrade, setSelectedStaffGrade] = useState([]);
    const [staffGradeType, setStaffGradeType] = useState([]);
    const [staffDesignation, setStaffDesignation] = useState("");
    const [selectedPlan, setselectedPlan] = useState("");
    const [plan, setPlan] = useState("");
    const [amount, setAmount] = useState([]);
    const [employeebranch, setEmployeebranch] = useState("");
    const [incharge, setIncharge] = useState("");
    const [companyname, setCompanyname] = useState("");
    const [companynumber, setCompanynumber] = useState("");
    const [companyaddress, setCompanyaddress] = useState("");
    const [companygst, setCompanygst] = useState("");
    const [ListemployeeGrade, setListemployeeGrade] = useState([]);
    const [ListemployeeCode, setListemployeeCode] = useState([]);
    const [ListemployeeDesignation, setListemployeeDesignation] = useState("");
    const [SelectOfficegrade, setSelectOfficegrade] = useState("");
    const [SelectOFficeEmpCode, setSelectOFficeEmpCode] = useState("");
    const [SelectOFficeDesignation, setSelectOFficeDesignation] = useState("");
    const [officeinchargename, setOfficeinchargename] = useState("");
    const [usercode, setUserCode] = useState(JSON.parse(sessionStorage.getItem('userdetails'))[0]['Msusertregcode']);



    async function getSelectedStaffGradeTypeDdl(e) {
        setselectedGradeType(e.target.value);
        var Param = { Mststaffgradetypeid: e.target.value }
        var url = "/StaffGradeType/BindStaffGradeType";
        let _response = await Api.BindGrid(Param, url);
        setStaffDesignation(_response[0].Designation);
    }
    async function getEmployeeCode() {
        var url = "/DropDown/GetOfficetypecode";
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setListemployeeCode(_response)
    }
    async function GetCountryList() {
        let _response = await Api.DirectApiCall('country', "", "")
        setCountryList(_response)
    }
    async function GetStateList(value) {
        setCountry(value)
        var Param = { country: value }
        let _response = await Api.DirectApiCall('state', value)
        setStateList(_response.states)
    }
    async function GetCityList(value) {
        setState(value)
        // var url = "/DropDown/GetCities";
        // let _response = await Api.BindDropdown(url)
        // let Arry_Title1 = [];
        // setCityList(_response)
    }
    const [district, setDistrict] = useState("");
    const [districtlist, setDistrictlist] = useState("")
    async function GetDistrict() {
        var url = "/DropDown/GetDistrict";
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setDistrictlist(_response)
    }
    async function getSelectedStaffGradeDdl(e) {
        setGrade(e.target.value);
        setSelectedStaffGrade(e.target.value);
        var staffGrade = e.target.options[e.target.selectedIndex].text;
        var Param = { mststaffgradedesignationid: 0 }
        var url = "/StaffGradeType/BindStaffGradeType";
        let _response1 = await Api.BindGrid(Param, url)
        _response1 = _response1.filter(function (el) {
            return el.Staffgradetypecode == e.target.value;
        });
        var sGrade = [...new Set(_response1.map(q => {
            var x = {
                Automaticbaseedongradetype: q.Automaticbaseedongradetype,
                Mststaffgradetypeid: q.Mststaffgradetypeid
            }
            return x;
        }))]
        setStaffGradeType(sGrade);
    }
    async function GetStaffGrademannual(id) {
        try {
            var Param = { mststaffgradedesignationid: 0 }
            var url = "/StaffGradeType/BindStaffGradeType";
            let _response121 = await Api.BindGrid(Param, url);
            _response121 = _response121.filter(function (el) {
                return el.Role == id;
            });
            var sGrade = [...new Set(_response121.map(q => q.Staffgradetypecode))]
            setStaffGrade(sGrade);
        } catch {
        }
    }
    async function GetStaffGradeTypemannual(id) {
        var Param = { mststaffgradedesignationid: 0 }
        var url = "/StaffGradeType/BindStaffGradeType";
        let _response1 = await Api.BindGrid(Param, url)
        _response1 = _response1.filter(function (el) {
            return el.Staffgradetypecode == id;
        });
        var sGrade = [...new Set(_response1.map(q => {
            var x = {
                Automaticbaseedongradetype: q.Automaticbaseedongradetype,
                Mststaffgradetypeid: q.Mststaffgradetypeid
            }
            return x;
        }))]
        setStaffGradeType(sGrade);
    }
    async function GetPlandetail() {
        var url = "/DropDown/GetEmployerdetail";
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setPlan(_response)
    }
    async function GetAmount(e) {
        setselectedPlan(e.target.value)
        var id = (e.target.value)
        var url = "/DropDown/GetEmployeramount?id=" + id;
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setAmount(_response)
    }
    async function GetQualification() {
        var url = "/DropDown/GetQualification";
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setQualification(_response)
    }
    async function GetMenu(id) {
        var Param = { mstmenuid: id }
        var url = "/Menu/BindMenu";
        let _response = await Api.BindGrid(Param, url)
        setListMenu(_response);
    }
    async function GetRoleType(id) {
        var Param = { Mstroleid: id }
        var url = "/Role/BindRole";
        let _response1 = await Api.BindGrid(Param, url);
        setListMenu(JSON.parse(_response1[0].RoleList));
        await bindStaffGradeDdl(id)
    }
    async function GetDepartment() {
        var url = "/DropDown/GetDepartment";
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setDepartment(_response)
    }
    async function GetGradeType(id) {
        setselectedGrade(id)
        var url = "/DropDown/GetGradeType?GradeCode=" + id;
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        try {
            setgradetype(_response[0].Text.split(","))
        }
        catch { }
    }
    async function bindStaffGradeDdl(id) {
        var Param = { mststaffgradedesignationid: 0 }
        var url = "/StaffGradeType/BindStaffGradeType";
        try {
            let _response1 = await Api.BindGrid(Param, url);
            _response1 = _response1.filter(function (el) {
                return el.Role == id;
            });
            var sGrade = [...new Set(_response1.map(q => q.Staffgradetypecode))]
            setStaffGrade(sGrade);
        } catch {
        }
    }
    async function GetGradeTypesManual(ID) {
        var Param = { Staffgradetypecode: ID }
        var url = "/StaffGradeType/BindStaffGradeType";
        let _response = await Api.BindGrid(Param, url)
        setStaffGradeType(_response);
    }
    async function getSelectedStaffGradeTypeDdl(e) {
        setselectedGradeType(e.target.value);
        var Param = { Mststaffgradetypeid: e.target.value }
        var url = "/StaffGradeType/BindStaffGradeType";
        let _response = await Api.BindGrid(Param, url);
        setStaffDesignation(_response[0].Designation);
    }
    async function Getofficemployeename(e) {
        setSelectOFficeEmpCode(e);
        var Param = { Mstofficetypecreationid: e }
        var url = "/DropDown/GetInchargeNmae?id=" + e;
        let _response = await Api.BindGrid(Param, url);
        setOfficeinchargename(_response[0].Text);
    }
    const [agree, setAgree] = useState(false);
    async function IUDOperation(flag, id) {
        var Name = name;
        var Mobile = phone;
        var Pan = pannumber;
        var Qualify = selectedQualiify;
        var Email = email;
        var Aadhar = aadharnumber;
        var Address = address;
        var State = state;
        var Country = country;
        var City = city;
        var Pincode = pincode;
        var Acholder = acholder;
        var Accountno = accountno;
        var Ifsccode = ifsccode;
        var Branchname = branchname;
        var SelectedStaffGrade = selectedStaffGrade;
        var StaffGradeType = staffGradeType;
        var Department = selectdepartment;
        var Reportempcode = reportingempcode;
        var Bankname = bankname;
        var CompanyName = companyname;
        var Companynumber = companynumber;
        var Companyaddress = companyaddress;
        var CompanyGST = companygst;
        var SelectedPlan = selectedPlan;
        var Amount = amount;
        var Param =
        {
            "mstuserregid": ID,
            "msusertregcode": "",
            "name": name,
            "mobilenumber": Mobile,
            "pannumber": pannumber,
            "qualification": "1",
            "usertype": 0,
            "plandetails": 0,
            "amount": 0,
            "empbranch": "",
            "incharge": "",
            "emailid": email,
            "aadhaarnumber": aadharnumber,
            "rolecab": "Employer",
            "agGradetype": "",
            "agGrade": "",
            "agDesignation": "",
            "department": "",
            "agAgentcode": "",
            "officecode": "",
            "reportingempcode": "",
            "city": city,
            "state": state,
            "country": country,
            "address": address,
            "pincode": pincode,
            "accountholdername": "",
            "accountnumber": "",
            "ifsccode": "",
            "branchname": "",
            "bankname": "",
            "password": "",
            "companyname": CompanyName,
            "companyphoneNo": Companynumber,
            "companyaddress": Companyaddress,
            "companygst": CompanyGST,
            "confirmpassword": "",
            "rolevscab": "Employer",
            "active": "",
            "flag": "U",
            "searchField": "Employer",
            "subscribe": "",
            "plantype": "",
            "planperiod": "",
            "activestatus": "",
            "planamount": 0,
            "district": district,
        }
        var url = "/UserReg/IUDUserRegtable";
        let _response = await Api.IUD(Param, url);
        showAlert(_response);
        setTimeout(() => {
            navigate('/Employerdashboard')
        }, 2000)
    }
    const showAlert = (_response) => {
        Swal.fire({
            title: "Success",
            text: _response,
            icon: "success",
            confirmButtonText: "OK",
        });
    };
    async function Get(id) {
        var Param = { Mstuserregid: id }
        var url = "/UserReg/GetUserRegtable1";
        let _response = await Api.BindGrid(Param, url)
        setName(_response[0].Name)
        setPhonenum(_response[0].Mobilenumber)
        setPannum(_response[0].Pannumber)
        setselectedQualiify(_response[0].Qualification)
        setEmail(_response[0].Emailid)
        setAadhaarnum(_response[0].Aadhaarnumber)
        set_Usertype(_response[0].Rolecab)
        await GetStaffGrademannual(_response[0].Rolecab)
        setSelectedStaffGrade(_response[0].AgGrade)
        await GetGradeTypesManual(_response[0].AgGrade)
        await GetStaffGradeTypemannual(_response[0].AgGrade)
        setselectedGradeType(_response[0].AgGradetype)
        setStaffDesignation(_response[0].AgDesignation)
        setselectDepartment(_response[0].Department)
        setAgentcode(_response[0].AgAgentcode)
        setOfficecode(_response[0].Officecode)
        setSelectOFficeEmpCode(_response[0].Reportingempcode)
        Getofficemployeename(_response[0].Reportingempcode)
        setCity(_response[0].City)
        setCountry(_response[0].Country)
        setState(_response[0].State)
        setDistrict(_response[0].District)
        setAddress(_response[0].Address)
        setPincode(_response[0].Pincode)
        setAcholder(_response[0].Accountholdername)
        setAccountno(_response[0].Accountnumber)
        setIfsccode(_response[0].Ifsccode)
        setCompanyname(_response[0].Companyname)
        setCompanynumber(_response[0].CompanyphoneNo)
        setCompanyaddress(_response[0].Companyaddress)
        setCompanygst(_response[0].CompanyGst)
        setselectedPlan(_response[0].Plantype)
        setBranchname(_response[0].Branchname)
        setbankname(_response[0].Bankname)
        setIfsccode(_response[0].Ifsccode)
        set_Usertype(_response[0].Rolecab)
        setBranchname(_response[0].Branchname)
        setListMenu(JSON.parse(_response[0].Rolevscab))
    }
    const handleOnChange = (event, option, index, F) => {
        const values = [...ListMenu];
        if (event.target.checked) {
            if (F === "V") {
                values[index].View = 1;
            } if (F === "A") {
                values[index].Add = 1;
            } if (F === "E") {
                values[index].Edit = 1;
            } if (F === "D") {
                values[index].Delete = 1;
            } if (F === "P") {
                values[index].Print = 1;
            }
        }
        else {
            if (F === "V") {
                values[index].View = 0;
            } if (F === "A") {
                values[index].Add = 0;
            } if (F === "E") {
                values[index].Edit = 0;
            } if (F === "D") {
                values[index].Delete = 0;
            } if (F === "P") {
                values[index].Print = 0;
            }
        }
        setListMenu(values);
    };

    const [new_password, setnew_password] = useState("");
    const [confirm_password, setconfirm_password] = useState("");
    const [popup, setpopup] = useState(false);
    const [AppUserid, setAppUserid] = useState('');

    const openPopup = (id) => {
        setAppUserid(id)
        setpopup(true);
    }
    function closeModal2() {
        setpopup(false);
    }

    async function Active() {
        if (confirm_password != new_password) {
            toast.error('Password and Confirm Password Mismatch')
            return false;
        }
        var Param = {
            "mstuserregid": AppUserid.toString(),
            "flag": "employer",
            "confirmpassword": confirm_password
        };
        var url = "/UserReg/UserForgotPassword";
        let _response = await Api.IUD(Param, url);
        showAlert1(_response);
        Get(0)
        setpopup(false);
        navigate("/")
    }

    const showAlert1 = (_response) => {
        Swal.fire({
            title: "Success",
            text: _response,
            icon: "success",
            confirmButtonText: "OK",
        });
    };

    async function GetCityValue(value) {
        setDistrict(value)
        var Param = { districtName: value, cityName: "" }
        var url = "/CommanAPI/GetCity";
        let _response = await Api.BindGrid(Param, url)
        setCityList(_response)
    }


    return (
        <div>

            <div>
                <Toaster toastOptions={{ position: "top-right" }} />
                <EmployerBase />
                <div id="main" class="main">
                    <div class="pagetitle">
                        <nav>
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><Link to="/Employerdashboard">Home</Link></li>
                                <li class="breadcrumb-item">Employer Profile</li>
                            </ol>
                        </nav>
                    </div>

                    <section class="section profile">
                        <div class="row">
                            <div class="col-xl-4">
                                <div class="card">
                                    <div class="card-body profile-card pt-4 d-flex flex-column align-items-center">
                                        {/* <img src={Images.man} alt="pelago" className="rounded-circle" />  */}
                                        {/* <img src="assets/img/man.jpg" alt="Profile" class="rounded-circle"/> */}
                                        {/* <span class="rounded-circle "><i class="bi bi-book-fill jobicon"></i></span> */}
                                        <h2>{sessionStorage.getItem('username')}</h2>
                                        <h3>{sessionStorage.getItem('AccessType')}</h3>
                                        <div class="social-links mt-2">
                                            <a class="twitter"><i class="bi bi-twitter"></i></a>
                                            <a class="facebook"><i class="bi bi-facebook"></i></a>
                                            <a class="instagram"><i class="bi bi-instagram"></i></a>
                                            <a class="linkedin"><i class="bi bi-linkedin"></i></a>
                                        </div>
                                        <div class='mt-3'>
                                            <button className='btn btn-info' onClick={() => openPopup(sessionStorage.getItem('MUserID'))} style={{ color: '#fff' }}>Change Password</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-8">



                                <div class="card">
                                    <div class="card-body">
                                        <h5 class="card-title">Employer Profile</h5>
                                        <h5 class="card-title"> Company Details</h5>
                                        <div class="row g-3">
                                            <div class="col-md-6">
                                                <label for="code">User Code</label>
                                                <input type="text" class="form-control" disabled={true} placeholder="User code" value={usercode} onChange={(e) => setUserCode(e.target.value)} />
                                            </div>
                                            <div class="col-md-6">
                                                <label for="companyname">Company Name</label>
                                                <input type="text" class="form-control" disabled={true} placeholder="Company Name" value={companyname} onChange={(e) => setCompanyname(e.target.value)} />
                                            </div>
                                            <div class="col-md-6">
                                                <label for="companycontactnumber">Company Contact Number</label>
                                                <input type="number" class="form-control no-spinner" disabled={true} placeholder="Company Contact Number" value={companynumber} onChange={(e) => setCompanynumber(e.target.value)} />
                                            </div>
                                            <div class="col-md-6">
                                                <label for="gstnumber">GST Number</label>
                                                <input type="text" class="form-control" disabled={Flag == "D" || Flag == "null" ? true : false} placeholder="Company  GST" value={companygst} onChange={(e) => setCompanygst(e.target.value)} />
                                            </div>
                                            <div class="col-md-6">
                                                <label for="pannumber">Pan Number</label>
                                                <input type="text" class="form-control" disabled={Flag == "D" || Flag == "null" ? true : false} placeholder="Pan Number" value={pannumber} onChange={(e) => setPannum(e.target.value)} />
                                            </div>
                                            <div class="col-md-6">
                                                <label for="email">Email</label>
                                                <input type="email" class="form-control" disabled={true} placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                            </div>
                                            <div class="col-md-6">
                                                <label for="companyaddress">Company Address</label>
                                                <textarea class="form-control" disabled={Flag == "D" || Flag == "null" ? true : false} placeholder="Company Address" value={companyaddress} onChange={(e) => setCompanyaddress(e.target.value)} ></textarea>
                                            </div>
                                            <h5 class="card-title"> Personal Details</h5>
                                            <div class="col-md-6">
                                                <label for="name">Name</label>
                                                <input type="text" class="form-control" disabled={Flag == "D" || Flag == "null" ? true : false} placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
                                            </div>
                                            <div class="col-md-6">
                                                <label for="phonenumber">Phone Number</label>
                                                <input type="number" maxlength="15" minlength="10" class="form-control no-spinner" disabled={Flag == "D" || Flag == "null" ? true : false} placeholder="Phone Number" value={phone} onChange={(e) => setPhonenum(e.target.value)} />
                                            </div>
                                            <div class="col-md-6">
                                                <label for="aadhar">Aadhar Number</label>
                                                <input type="text" class="form-control no-spinner" disabled={Flag == "D" || Flag == "null" ? true : false} placeholder="Aadhar Number" value={aadharnumber} onChange={(e) => setAadhaarnum(e.target.value)} />
                                            </div>
                                            <div class="col-md-6">
                                                <label for="country">Country</label>
                                                <select id="inputState" class="form-select" disabled={Flag == "D" || Flag == "null" ? true : false} value={country} onChange={(e) => GetStateList(e.target.value)}>
                                                    <option selected>Country</option>
                                                    {CountryList.map((i, index) => (
                                                        <option value={i.country.toLowerCase()}>{i.country}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div class="col-md-6">
                                                <label for="state">State</label>
                                                <select id="inputState" class="form-select" disabled={Flag == "D" || Flag == "null" ? true : false} value={state} onChange={(e) => GetCityList(e.target.value)}>
                                                    <option selected >State</option>
                                                    {StateList.map((i, index) => (
                                                        <option value={i.name.toLowerCase()}>{i.name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div class="col-md-6">
                                                <label for="district">District</label>
                                                <select id="inputState" class="form-select" value={district} onChange={(e) => GetCityValue(e.target.value)}>
                                                    <option selected >District</option>
                                                    {districtlist &&
                                                        districtlist.map((i, index) => (
                                                            <option value={i.Text}>{i.Text}</option>
                                                        ))}
                                                </select>
                                            </div>
                                            <div class="col-md-6">
                                                <label for="city">City</label>
                                                <select id="inputState" class="form-select" disabled={Flag == "D" || Flag == "null" ? true : false} value={city} onChange={(e) => setCity(e.target.value)}>
                                                    <option >City</option>
                                                    {CityList.map((i, index) => (
                                                        <option value={i.CityName}>{i.CityName}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div class="col-md-6">
                                                <label for="pincode">Pincode</label>
                                                <input type="number" class="form-control no-spinner" placeholder="Pincode" disabled={Flag == "D" || Flag == "null" ? true : false} value={pincode} onChange={(e) => setPincode(e.target.value)} />
                                            </div>
                                            <div class="col-md-6">
                                                <label for="address">Address</label>
                                                <textarea class="form-control" placeholder="Address" value={address} disabled={Flag == "D" || Flag == "null" ? true : false} onChange={(e) => setAddress(e.target.value)} ></textarea>
                                            </div>

                                            <div class="text-center">
                                                <button type="button" class="btn btn-primary" onClick={IUDOperation}>Submit</button>
                                                <button type="reset" class="btn btn-info">
                                                    <Link to='/Employerdashboard'>
                                                        Back
                                                    </Link>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <Modal show={popup} className='model-popup-design' style={{ marginBottom: "100px" }}>
                <Modal.Header classname="Userlgnmodalhead">
                    <Modal.Title>
                        Change Password
                    </Modal.Title>
                    <button type="button" class="btn-close" data-dismis="modal" onClick={closeModal2} arial-label="Close"></button>
                    {/* <CloseButton variant="black" onClick={closeModal2} /> */}
                </Modal.Header>
                <div className='loginmodalbody' style={{ width: '785px' }}>
                    <Modal.Body className='testing-test' >
                        <form>
                            <h5>Do you want to Change Password?</h5>
                        </form>
                    </Modal.Body>
                </div>
                <Modal.Footer>
                    <div className='row'>
                        <div className='col-md-6'>
                            <label>New Password</label>
                            <input type='text' class='form-control' value={new_password} onChange={(e) => setnew_password(e.target.value)}></input>
                        </div>
                        <div className='col-md-6'>
                            <label>Confirm Password</label>
                            <input type='password' class='form-control' value={confirm_password} onChange={(e) => setconfirm_password(e.target.value)}></input>
                        </div>
                    </div>
                    <Button variant="secondary" onClick={() => Active()}>
                        Submit
                    </Button>
                    {/* <Button variant="secondary" onClick={() => Inactive()}>
                        Inactive
                    </Button> */}
                </Modal.Footer>
            </Modal>
        </div>

    )
}