import React, { useState, useEffect } from 'react'
import { Images } from '../../Images/Index';
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import $ from 'jquery';
import Api from '../Services/ApiCall';
import Swal from 'sweetalert2';

export default function JobseekerBase(Appliedjobs, Viewedjobs, JobseekerLedgerReport) {

  const [toggleSidebar, settoggleSidebar] = useState(false)
  const onclickToggleshow = (value) => {
    settoggleSidebar(value)
    if (value) {
      $('body').addClass('toggle-sidebar')
    }
    else {
      $('body').removeClass('toggle-sidebar')
    }
  }




  const [Verified, setVerified] = useState("");
  const [clickLogoutshow, setclickLogoutshow] = useState(false)
  const onclicklogoutshow = (showlogoutbtn) => {
    setclickLogoutshow(showlogoutbtn)
  }


  const [clickNotificationshow, setclickNotificationshow] = useState(false)
  const onclickNotificationshow = (showNotificationbtn) => {
    setclickNotificationshow(showNotificationbtn)
  }

  const [clickReportshow, setclickReportshow] = useState(false)
  const onclickReportshow = (showReportdropdown) => {
    setclickReportshow(showReportdropdown)
  }

  const [username, setusername] = useState("");
  useEffect(() => {
    setusername(sessionStorage.getItem("username"));
    // BindGrid();
  }, []);


  async function BindGrid() {
    var Param = { mstjobseekerid: sessionStorage.getItem("MUserID") }
    var url = "/JobSeekerTable/BindJobSeekerTable2";
    let _response = await Api.BindGrid(Param, url)
    var Verified = _response[0].Verified;
    sessionStorage.setItem("Verified", Verified)
    if (Verified != "Verified") {
      showAlert("Please Complete Your Profile, before apply the job");
    } else {
      navigate('/Jobs');
    }

  }

  const showAlert = (message) => {
    Swal.fire({
      title: "",
      text: message,
      icon: "warning",
      confirmButtonText: "OK",
    }).then((result) => {
      if (result.isConfirmed) {
        navigate('/Jobs');
      }
    });
  };

  const logout = () => {
    navigate('/')
  }
  const navigate = useNavigate();


  return (
    <>
      <body class={toggleSidebar ? "toggle-sidebar" : ""} >
        <div id="header" class="header fixed-top d-flex align-items-center">
          <div class="d-flex align-items-center justify-content-between">
            <a class="logo d-flex align-items-center">
              <img src={Images.mainlogo} alt="pelago" className="userlg_img" />
            </a>
            <i class="bi bi-list toggle-sidebar-btn" onClick={() => onclickToggleshow(!toggleSidebar)}></i>
          </div>
          <nav class="header-nav ms-auto" >
            <ul class="d-flex align-items-center">
              <li class="nav-item dropdown pe-3">
                <a style={{ cursor: 'pointer' }} class={clickNotificationshow ? "nav-link nav-profile d-flex align-items-center pe-0 show" : "nav-link nav-profile d-flex align-items-center pe-0 "} onClick={(Notification) => onclickNotificationshow(!clickNotificationshow)} data-bs-toggle="dropdown">
                  <li class="dropdown-header">
                    <a class="nav-link nav-icon" data-bs-toggle="dropdown" >
                      <i class="bi bi-bell"  ></i>
                      <span class="badge bg-primary badge-number">0</span>
                    </a>
                  </li>
                </a>
              </li>
              <li class="nav-item dropdown pe-3">
                <a style={{ cursor: 'pointer' }} class={clickLogoutshow ? "nav-link nav-profile d-flex align-items-center pe-0 show" : "nav-link nav-profile d-flex align-items-center pe-0 "} onClick={() => onclicklogoutshow(!clickLogoutshow)} data-bs-toggle="dropdown">
                  <i class="bi bi-person-circle"></i>
                  <span class="d-none d-md-block dropdown-toggle ps-2">{username}
                    {sessionStorage.getItem("Verified") === "Verified" && (
                      <i className="bi bi-check-circle text-success" style={{ marginLeft: '5px' }}></i>
                    )}
                  </span>
                </a>
                <ul id="Profiledropdown" class={clickLogoutshow ? "dropdown-menu dropdown-menu-end dropdown-menu-arrow profile show" : "dropdown-menu dropdown-menu-end dropdown-menu-arrow profile"} style={{ position: "absolute", inset: "0px 0px auto auto", margin: "0px", transform: "translate(-16px, 38px)" }}>
                  <li class="dropdown-header">
                    <h6></h6>
                    <span>
                      {sessionStorage.getItem("AccessType")}
                    </span>
                  </li>
                  <li>
                    <hr class="dropdown-divider" />
                  </li>
                  <li>
                    <Link to='/Myprofile' state={{ ID: sessionStorage.getItem("MUserID"), Flag: 'JobProf' }}>
                      <a class="dropdown-item d-flex align-items-center" href="" >
                        <i class="bi bi-person"></i>
                        <span>My Profile</span>
                      </a>
                    </Link>
                  </li>
                  <li>
                    <hr class="dropdown-divider" />
                  </li>
                  <li>
                    <a class="dropdown-item d-flex align-items-center" style={{ cursor: 'pointer' }}>
                      <i class="bi bi-box-arrow-right"></i>
                      <span onClick={logout}>Sign Out</span>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
        <div id="sidebar" class="sidebar">
          <ul class="sidebar-nav" id="sidebar-nav">
            <li class="nav-item">
              <a class="nav-link " href='/Jobseekerdashboard'>
                <i class="bi bi-grid"></i>
                <span>Home</span>
              </a>
            </li>
            <li class="nav-item" style={{cursor: "pointer"}}>
              <a class="nav-link " onClick={BindGrid}>
                <i class="bi bi-grid"></i>
                <span>Jobs</span>
              </a>
            </li>
            <li class="nav-item">
              <a class={clickReportshow ? "nav-link" : "nav-link collapsed"} data-bs-target="#components-nav" onClick={() => onclickReportshow(!clickReportshow)} data-bs-toggle="collapse" >
                <i class="bi bi-file-earmark-pdf"></i><span>Reports</span><i class="bi bi-chevron-down ms-auto"></i>
              </a>
              <ul id="components-nav"
                class={clickReportshow ? "nav-content collapse show" : "nav-content collapse"}
                data-bs-parent="#sidebar-nav">
                <ul>
                  <li>
                    <a href='/Appliedjobs'>
                      <i class="bi bi-circle"></i><span> Applied Jobs </span>
                    </a>
                  </li>
                  <li>
                    <a href='/Viewedjobs'>
                      <i class="bi bi-circle"></i><span> Viewed Jobs  </span>
                    </a>
                  </li>
                  <li class={JobseekerLedgerReport ? "list" : ""}>
                    <a href='/Jobseekerledgerreport'>
                      <i class="bi bi-circle"></i><span>Ledger Report </span>
                    </a>
                  </li>
                </ul>
              </ul>
            </li>
            <li class="nav-item">
              <Link class="nav-link " to={'/Rechargeform'}
                state={{ Code: JSON.parse(sessionStorage.getItem('userdetails'))[0]['Mstjobseekercode'] }}>
                <i class="bi bi-grid"></i>
                <span>Topup</span>
              </Link>
            </li>
            {sessionStorage.getItem("Verified") === "Verified" ?
              <li class="nav-item">
                <Link to={'/ManagerSupport'}
                  class="nav-link " state={{ ID: 0, Flag: 'JobCS' }}>
                  <i class="bi bi-telephone-x"></i>
                  <span>Manager Support</span>
                </Link>
              </li>
              : null}
            <li class="nav-item">
              <Link to={'/Customersupporttable'}
                class="nav-link " state={{ ID: 0, Flag: 'JobCS' }}>
                <i class="bi bi-telephone-x"></i>
                <span>Corporate Support</span>
              </Link>
            </li>

          </ul>
        </div>
      </body>

    </>
  )
}
