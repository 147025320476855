import React, { useState, useEffect } from 'react'
import Base from '../../Base';
import Api from '../../Services/ApiCall';
import { Link, useLocation, useNavigate } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import Swal from 'sweetalert2';


const style = {
    backGround: "red"
}

export default function Depositform() {

    const navigate = useNavigate();
    const location = useLocation();
    const { ID, Flag } = location.state;
    ////console.log('%%%', ID, Flag);

    useEffect(() => {
        // GetBankname()
        // GetAccountnumber()
        // GetBankdetails()
        if (ID != 0) {
            Get(ID);
        } else {
            GetBankname()
            GetAccountnumber()
            GetBankdetails()
        }

    }, []);


    async function GetBankname() {

        var url = "/DropDown/GetBankName";
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setBankname(_response)
    }

    async function GetAccountnumber(value) {

        setSelectaccountname(value)
        var id = (value)
        var url = "/DropDown/GetBankAccountNo?id=" + id;
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setAccountnumber(_response)

    }
    async function GetBankdetails(value) {

        setAccnumber(value)
        var id = (value)
        var url = "/DropDown/GetBankdetails?id=" + id;
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setBranchname(_response[0].Text)
        setIfsccode(_response[0].Text1)
        setAccountholdername(_response[0].Text2)


    }


    const [depositetype, setDepositetype] = useState("");
    const [selectbankname, setSelectaccountname] = useState("");
    const [accnumber, setAccnumber] = useState("");
    const [accountholdername, setAccountholdername] = useState("");
    const [branchname, setBranchname] = useState("");
    const [ifsccode, setIfsccode] = useState("");
    const [date, setDate] = useState("");
    const [amount, setAmount] = useState("");
    const [transactionnumber, setTransactionnumber] = useState("");
    const [chellan, setChellan] = useState("");
    const [remark, setRemark] = useState("");
    const [depositorid, setDepositorid] = useState(JSON.parse(sessionStorage.getItem('userdetails'))[0]['Msusertregcode'])
    //const[depositorid,setDepositorid]=useState("")

    const [accountnumber, setAccountnumber] = useState([]);
    const [bankname, setBankname] = useState("");



    async function IUDOperation(flag, id) {
        var Depositetype = depositetype;
        var Bankname = selectbankname;
        var Accountnumber = accnumber;
        var Accountholdername = accountholdername;
        var Branchname = branchname;
        var Ifsccode = ifsccode;
        var Date = date;
        var Amount = amount;
        var Transactionnumber = transactionnumber;
        var Chellan = chellan;
        var Remark = remark;
        var Depositorid = depositorid;


        if (Depositetype == "") {
            toast.error('Please Choose Deposit Type')
            return false;
        }
        if (Bankname == "") {
            toast.error('Please Choose Bank Name ')
            return false;
        }
        if (Accountnumber == "") {
            toast.error('Please Choose Account Number')
            return false;
        }
        if (Amount == "") {
            toast.error('Please enter Amount')
            return false;
        }
        if (Date == "") {
            toast.error('Please Choose  Date ')
            return false;
        }
        if (Transactionnumber == "") {
            toast.error('Please Enter Transaction Number ')
            return false;
        }
        if (Remark == "") {
            toast.error('Please Enter Remark ')
            return false;
        }
        if (Depositorid == "") {
            toast.error('Please Enter Depositorid ')
            return false;
        }
        //    if(Chellan==""){
        //        toast.error('Please Choose Chellan ')
        //        return false;
        // } 

        var Param = {
            "mstdepositid": ID,
            "mstdepostcode": "",
            "bankname": Bankname,
            "ifsccode": Ifsccode,
            "amount": Amount,
            "chellan": Chellan,
            "active": "Pending",
            "flag": Flag,
            "searchfield": "",
            "createdby": 0,
            "createddate": "2022-11-24T08:19:40.346Z",
            "accountname": Accountholdername,
            "deposittype": Depositetype,
            "depositaccountnumber": Accountnumber,
            "branchname": Branchname,
            "depositdate": Date,
            "transactionnumber": Transactionnumber,
            "remarks": Remark,
            "depositorid": Depositorid

        };

        var url = "/Deposit/IUDDepostit";
        let _response = await Api.IUD(Param, url);

        showAlert(_response);
        setTimeout(() => {
            navigate('/Deposittable')
        }, 2000)

    }
    const showAlert = (_response) => {
        Swal.fire({
            title: "Success",
            text: _response,
            icon: "success",
            confirmButtonText: "OK",
        });
    };

    async function Get(id) {
        try {
            var Param = { mstdepositid: id }
            var url = "/Deposit/BindDepostit";
            let _response = await Api.BindGrid(Param, url)
            //setBankname(_response[0].Bankname)
            // GetAccountnumber(_response[0].Bankname)

            var url1 = "/DropDown/GetBankAccountNo?id=" + _response[0].Bankname;
            let _response1 = await Api.BindDropdown(url1)
            setAccountnumber(_response1)
            setSelectaccountname(_response[0].Bankname)
            // setSelectaccountname(_response[0].MasterBankname)
            // setBankname(_response[0].MasterBankname)
            //  GetBankdetails(_response[0].Depositaccountnumber)
            var url2 = "/DropDown/GetBankdetails?id=" + _response[0].Depositaccountnumber;
            let _response2 = await Api.BindDropdown(url2)

            setBranchname(_response2[0].Text)
            setIfsccode(_response2[0].Text1)
            setAccountholdername(_response2[0].Text2)
            setAccnumber(_response[0].Depositaccountnumber)
            setAmount(_response[0].Amount)
            setDepositetype(_response[0].Deposittype)
            // setBankname(_response[0].Bankname)
            setBranchname(_response[0].Branchname)
            setIfsccode(_response[0].Ifsccode)
            setDate(_response[0].Depositdate)
            setTransactionnumber(_response[0].Transactionnumber)
            // setChellan(_response[0].Chellan)
            setRemark(_response[0].Remarks)
            setAccountholdername(_response[0].Accountname)
            setDepositorid(_response[0].Depositorid)
        }
        catch {

        }
    }

    return (
        <div>
            <Toaster toastOptions={{ position: "top-right" }} />
            <Base Deposit={true} />
            <div id="main" class="main">
                <div class="pagetitle">

                    <nav>
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><Link to="/dash">Home</Link></li>
                            <li class="breadcrumb-item">Bank/Cash</li>
                            <li class="breadcrumb-item active">Deposit</li>
                        </ol>
                    </nav>
                </div>

                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">Deposit Details</h5>


                        <div class="row g-3">

                            <div class="col-md-6">
                                <label for="deposit Type">Deposit Type</label>
                                <select id="inputState" class="form-select" disabled={Flag == "D" || Flag == "null" ? true : false} value={depositetype} onChange={(e) => setDepositetype(e.target.value)} >

                                    <option selected>Deposit Type</option>
                                    <option>Cash</option>
                                    <option>CDM</option>
                                    <option>IMVS/RTGS</option>
                                    <option>Online</option>

                                </select>
                            </div>

                            <div class="col-md-6">
                                <label for="Account Name">Bank Name</label>
                                <select id="inputState" class="form-select" value={selectbankname} onChange={(e) => GetAccountnumber(e.target.value)}>

                                    <option selected>Bank Name</option>
                                    {bankname &&
                                        bankname.map((i, index) => (
                                            <option value={i.id}>{i.Text}</option>
                                        ))}


                                </select>
                            </div>

                            <div class="col-md-6">
                                <label for="accountnumber">Deposit Account Number</label>
                                < select class="form-select" value={accnumber} onChange={(e) => GetBankdetails(e.target.value)} >
                                    <option selected>Account Number</option>
                                    {/* { Flag=="I"? (
                                  <> */}
                                    {accountnumber &&
                                        accountnumber.map((i, index) => (
                                            <option value={i.id}>{i.Text}</option>
                                        ))}
                                    {/* </>
                                 ) : (
                                            <option>{accountnumber}</option>
                                          
                                        )}|| */}


                                </select>
                            </div>




                            <div class="col-md-6">
                                <label for="accountholdername">Account Holder Name</label>
                                <input type="text" class="form-control" disabled={true} placeholder="Account Holder Name" value={accountholdername} onChange={(e) => setAccountholdername(e.target.value)} />
                            </div>

                            <div class="col-md-6">
                                <label for="bankname">Branch Name</label>
                                <input type="text" class="form-control" disabled={true} placeholder="Branch Name" value={branchname} onChange={(e) => setBranchname(e.target.value)} />
                            </div>

                            <div class="col-md-6">
                                <label for="branchcode">IFSC Code</label>
                                <input type="text" class="form-control" disabled={true} placeholder="IFSC Code" value={ifsccode} onChange={(e) => setIfsccode(e.target.value)} />
                            </div>
                            <div className=' col-md-6'>
                                <label for="name">Deposit Date</label>
                                <input type="date" max={new Date().toISOString().split("T")[0]} class="form-control" placeholder='Deposit Date' disabled={Flag == "D" || Flag == "null" ? true : false} value={date} onChange={(e) => setDate(e.target.value)} />
                            </div>

                            <div class="col-md-6">
                                <label for="amount">Amount</label>
                                <input type="number" class="form-control no-spinner" disabled={Flag == "D" || Flag == "null" ? true : false} value={amount} placeholder="Deposite Amount" onChange={(e) => setAmount(e.target.value)} />
                            </div>
                            <div class="col-md-6">
                                <label for="amount">Transaction Number</label>
                                <input type="Text" class="form-control" disabled={Flag == "D" || Flag == "null" ? true : false} placeholder="Transaction Number" value={transactionnumber} onChange={(e) => setTransactionnumber(e.target.value)} />
                            </div>
                            <div class="col-md-6">
                                <label for="depositorid">Depositor Id</label>
                                <input class="form-control" value={depositorid} onChange={(e) => setDepositorid(e.target.value)} />
                            </div>
                            <div class="col-md-6">
                                <label for="chellan">Chellan</label>
                                <input type="file" class="form-control " disabled={Flag == "D" || Flag == "null" ? true : false} value={chellan} onChange={(e) => setChellan(e.target.value)} />
                            </div>

                            <div class="col-md-6">
                                <label for="Remarks">Remarks</label>
                                <input type="Text" class="form-control " placeholder='Remarks' disabled={Flag == "D" || Flag == "null" ? true : false} value={remark} onChange={(e) => setRemark(e.target.value)} />
                            </div>


                            <div class="text-center">
                                <button type="button" class="btn btn-primary" onClick={IUDOperation}>Submit</button>
                                <button type="reset" class="btn btn-info">
                                    <Link to='/Deposittable'>
                                        Back
                                    </Link>
                                </button>
                            </div>
                        </div>

                    </div>
                </div>


            </div>

        </div>
    )
}