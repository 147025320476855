import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import Swal from 'sweetalert2';
import Base from '../Base';

export default function Testimonial() {
    const navigate = useNavigate();
    const [files, setFiles] = useState([]);  // For storing selected files

    const previewFile = (e) => {
        const selectedFiles = Array.from(e.target.files);
        setFiles(selectedFiles);  // Store all selected files
    };

    async function IUDOperation() {
        const formData = new FormData();

        // Check if there is only one file or multiple files
        if (files.length === 1) {
            // Single file upload
            formData.append('files', files[0]);
        } else if (files.length > 1) {
            // Multiple file upload
            files.forEach((file) => {
                formData.append('files', file);  // Append each file to 'files' key
            });
        }

        try {
            const response = await fetch("http://localhost:36835/api/Company/InsertDatainS3", {
                method: "POST",
                headers: {
                    "accept": "text/plain",
                },
                body: formData,
            });
            const result = await response.json();
            Swal.fire({
                title: "Success",
                text: result.message || "Files uploaded successfully",
                icon: "success",
                confirmButtonText: "OK",
            });
            setTimeout(() => {
                navigate('/testimonial');
            }, 2000);
        } catch (error) {
            console.error("Error in IUDOperation:", error);
            toast.error("Something went wrong. Please try again.");
        }
    }

    return (
        <>
            <Toaster toastOptions={{ position: "top-right" }} />
            <Base />
            <div id="main" class="main">
                <div className="card-body">
                    <h5 className="card-title">Testimonial Datas</h5>
                    <div className="row g-3">
                        <div className="col-md-12">
                            <label htmlFor="files">Upload Images/Videos</label>
                            <input type="file" className="form-control" multiple onChange={previewFile} />
                        </div>

                        <div className="col-md-12 text-center mt-4">
                            <button className="btn btn-primary" onClick={IUDOperation}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
