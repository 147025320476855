import React from 'react'
import Base from '../../Base';
import { Link, useLocation, useNavigate } from "react-router-dom";
import Api from '../../Services/ApiCall'
import toast, { Toaster } from 'react-hot-toast';
import { useEffect, useState } from 'react';
import '../../../App.css'
import Swal from 'sweetalert2';

export default function Jobseekerplancreationform() {

    const navigate = useNavigate();
    const location = useLocation();
    const { ID, Flag } = location.state;
    
    useEffect(() => {
        if (ID != 0) {
            Get(ID);
        }
    }, []);

    const [plantype, setPlantype] = useState("");
    const [partner_reg, setPartner_reg] = useState("");
    const [partner_plan, setPartner_plan] = useState("");
    const [jobseeker_reg, setJobseeker_reg] = useState("");
    const [employer_reg, setEmployer_reg] = useState("");
    const [wallet, setWallet] = useState("");
    const [coupon_percent, setCoupon_percent] = useState("");
    const [coupon, setCoupon] = useState("");

    async function IUDOperation(flag, id) {

        var Plantype = plantype;
        var Partner_reg = partner_reg;
        var Partner_plan = partner_plan;
        var Jobseeker_reg = jobseeker_reg;
        var Employer_reg = employer_reg;
        var Wallet = wallet;

        if (Plantype == "") {
            toast.error('Please enter  Plantype')
            return false;
        }
        if (Partner_reg == "") {
            toast.error('Please Select Partner Registration')
            return false;
        }
        if (Partner_plan == "") {
            toast.error('Please enter Partner Plan Activation')
            return false;
        }
        if (Jobseeker_reg == "") {
            toast.error('Please enter Partner Jobseeker Registration')
            return false;
        }
        if (Employer_reg == "") {
            toast.error('Please enter Employer Registration')
            return false;
        }
        // if (Wallet == "") {
        //     toast.error('Please enter Self Registered Jobseeker&Employer Wallet')
        //     return false;
        // }
        var Param = {
            "mstjobseekerplancreationid": ID,
            "jobseekerplancreationcode": Employer_reg,
            "plantype": Plantype,
            "plandetail": Partner_reg,
            "coupon": coupon,
            "coupon_Percent": coupon_percent,
            "amount": Partner_plan,
            "active": "",
            "searchField": Jobseeker_reg,
            "flag": Flag,
            "createdBy": 0,
            "createdDate": "2022-10-14T12:50:55.460Z"
        };
        var url = "/JobSeekerPlanCreation/IUDJobSeekerPlan";
        let _response = await Api.IUD(Param, url);
        showAlert(_response);
        setTimeout(() => {
            navigate('/Agentplancreationtable')
        }, 2000)

    }
    const showAlert = (_response) => {
        Swal.fire({
            title: "Success",
            text: _response,
            icon: "success",
            confirmButtonText: "OK",
        });
    };

    async function Get(id) {
        var Param = { mstjobseekerplancreationid: id }
        var url = "/JobSeekerPlanCreation/BindJobSeekerPlan";
        let _response = await Api.BindGrid(Param, url);
        setPlantype(_response[0].Plantype);
        setPartner_reg(_response[0].Plandetail);
        setPartner_plan(_response[0].Amount);
        setJobseeker_reg(_response[0].SearchField);
        setEmployer_reg(_response[0].Jobseekerplancreationcode);
        setCoupon(_response[0].Coupon);
        setCoupon_percent(_response[0].Coupon_Percent);
    }

    return (
        <div>
            <Toaster toastOptions={{ position: "top-right" }} />

            <Base Jobseekerplan={true} />
            <div id="main" class="main">
                <div class="pagetitle">
                    <nav>
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><Link to="/dash">Home</Link></li>
                            <li class="breadcrumb-item">Plan Creation</li>
                            <li class="breadcrumb-item active">Agentplan</li>
                        </ol>
                    </nav>
                </div>

                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">Agent Plan Creation</h5>


                        <div class="row g-3">
                            <div class="col-md-6">
                                <label for="plantype">Plan Type</label>
                                <select id="inputstate" class="form-select" disabled={Flag == "D" || Flag == "null" ? true : false} value={plantype} onChange={(e) => setPlantype(e.target.value)}>
                                    <option>Plan Type</option>
                                    <option>Diamond Partner</option>
                                    <option>Gold Partner</option>
                                    <option>Silver Partner</option>
                                </select>
                            </div>

                            

                            <div class="col-md-6">
                                <label for="amount">Partner Plan Activation Charge</label>
                                <input type="text" class="form-control no-spinner" disabled={Flag == "D" || Flag == "null" ? true : false} value={partner_plan} placeholder="Amount" onChange={(e) => setPartner_plan(e.target.value)} />
                            </div>

                            <div class="col-md-6">
                                <label for="plandetails">Partner Registration</label>
                                <input type="number" class="form-control no-spinner" disabled={Flag == "D" || Flag == "null" ? true : false} value={partner_reg} placeholder="Amount" onChange={(e) => setPartner_reg(e.target.value)} />
                            </div>

                            <div class="col-md-6">
                                <label for="plandetails">Jobseeker Registration</label>
                                <input type="number" class="form-control no-spinner" disabled={Flag == "D" || Flag == "null" ? true : false} value={jobseeker_reg} placeholder="Amount" onChange={(e) => setJobseeker_reg(e.target.value)} />
                            </div>

                            <div class="col-md-6">
                                <label for="plandetails">Employer Registration</label>
                                <input type="number" class="form-control no-spinner" disabled={Flag == "D" || Flag == "null" ? true : false} value={employer_reg} placeholder="Amount" onChange={(e) => setEmployer_reg(e.target.value)} />
                            </div>
                            <div class="col-md-6">
                                <label for="coupon">Coupon Code</label>
                                <input type="text" class="form-control no-spinner" disabled={Flag == "D" || Flag == "null" ? true : false} value={coupon} placeholder="Coupon Code" onChange={(e) => setCoupon(e.target.value)} />
                            </div>
                            <div class="col-md-6">
                                <label for="coupon">Coupon Percent</label>
                                <input type="text" class="form-control no-spinner" disabled={Flag == "D" || Flag == "null" ? true : false} value={coupon_percent} placeholder="Coupon Percent" onChange={(e) => setCoupon_percent(e.target.value)} />
                            </div>

                            {/* <div class="col-md-6">
                                <label for="plandetails">Self Registered Jobseeker&Employer Wallet</label>
                                <input type="text" class="form-control no-spinner" disabled={Flag == "D" || Flag == "null" ? true : false} value={wallet} placeholder="Amount" onChange={(e) => setWallet(e.target.value)} />
                            </div> */}



                            
                            <div class="text-center">
                                <button type="button" class="btn btn-primary" onClick={IUDOperation}>Submit</button>
                                <button type="reset" class="btn btn-info">
                                    <Link to='/Agentplancreationtable'>
                                        Back
                                    </Link>
                                </button>
                            </div>
                        </div>

                    </div>
                </div>


            </div>

        </div>
    )
}
