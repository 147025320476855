import React, { useState, useEffect } from 'react'
import Base from '../../Base'
import { Link, } from "react-router-dom";
import Api from '../../Services/ApiCall';
import toast, { Toaster } from 'react-hot-toast';
import $ from "jquery"; 
import '../../../App.css'

import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import {
    validPhoneNumberRegex,
    validPasswordRegex,
    validEmailRegex,
    validOnlyEmailRegex,
    maxLength,
    numberMethod,
} from '../../CommanRules'

export default function UserForm() {
    const navigate = useNavigate();
    const location = useLocation();
    const { ID, Flag } = location.state;
    ////console.log('%%%', ID, Flag);
    useEffect(() => {
        if (ID != 0) {
           // GetMenu(0)
            Get(ID)

            
            GetQualification()
            GetUserType()
        }
        else{
           GetMenu(0)  
		    GetQualification()
        GetUserType()
        }
            
 
       
    }, []);

    const [name, setName] = useState("");
    const [mobileno, setMobilenumber] = useState("");
    const [panno, setPannumber] = useState("");
     const [qualification, setQualification] = useState([]);
    const [selectedQualiify, setselectedQualiify] = useState([]);
   // //console.log(selectedQualiify,"selected")
    const [usertype, setUsertype] = useState([]);
    const [selectedUsertype, setselectedUsertype] = useState([]);
    ////console.log(selectedUsertype,"selecteduser")
    const [emailid, setEmailid] = useState("");
    const [aadharno, setAadharnumber] = useState("");
    const [ListMenu, setListMenu] = useState([]); 


    var dt = new Date();
    async function BindGrid(id) {
        var Param = {
            Id: id, "createdBy": 0,
            "createdDate": dt
        }
        var url = "/Demo";
        let _response = await Api.BindGrid(Param, url)
       // //console.log(_response)
    }
	
	 async function GetQualification() {
         
        var url = "/DropDown/GetQualification";
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setQualification(_response)
       // //console.log(_response,"qualify")
    }

    async function GetUserType() {
         
        var url = "/DropDown/GetRole?code=Employee";
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setUsertype(_response)
        ////console.log(_response,"GetRole")
    }

    async function IUDOperation() {
      //  //console.log("test")
        var Name = name;
        var Phone = mobileno;
        var Pannum = panno;
        var Qualification = selectedQualiify;
        var Usertype = selectedUsertype;
        var Email = emailid;
        var AadharNo = aadharno;

        if (Name == "") {
            toast.error('Please enter a Name')
            return false;
        }
        if (Phone == "") {
            toast.error('Please enter a Phone number')
            return false;
        }
      
        if (Pannum == "") {
            toast.error('Please enter a PAN number')
            return false;
        }
        if (Qualification == "") {
            toast.error('Please select Qualification')
            return false;
        }
        if (Usertype == "") {
            toast.error('Please select Usertype')
            return false;
        }
        if (Email == "") {
            toast.error('Please enter a Email id')
            return false;
        }
        else {
            if (!validOnlyEmailRegex.test(emailid)) {
                toast.error('Please enter a valid Email Id')
                return false;
            }
        }
        // if (AadharNo == "") {
        //     toast.error('Please enter a Aadhar number')
        //     return false;
        // }
        // else {
        //     if (!(AadharNo.length == 12)) {
        //         toast.error('Please enter valid Aadhar number')
        //         return false;
        //     }
        // }


        // var JsonUpload = [];
        // var menuID,MenuName, ViewPermiss, AddPermiss, expoPermiss, ModifyPermiss, DelePermiss;
        // for (var i = 0; i < ListMenu.length; i++) {
        //     debugger
        //     menuID = ListMenu[i].Mstmenuid;
        //     MenuName = ListMenu[i].Menuname;
        //     if ($('#View_' + menuID + '_').is(":checked")) {
        //         ViewPermiss = 1;
        //     }else if ($('#View_' + menuID + '_').is(":not(:checked)")) {
        //         ViewPermiss = 0;
        //     }
        //     if ($('#Add_' + menuID + '_').is(":checked")) {
        //         AddPermiss = 1;
        //     }
        //     else if ($('#Add_' + menuID + '_').is(":not(:checked)")) {
        //         AddPermiss = 0;
        //     }
        //     if ($('#Print_' + menuID + '_').is(":checked")) {
        //         expoPermiss = 1;
        //     }
        //     else if ($('#Print_' + menuID + '_').is(":not(:checked)")) {
        //         expoPermiss = 0;
        //     }
        //     if ($('#Edit_' + menuID + '_').is(":checked")) {
        //         ModifyPermiss = 1;
        //     }
        //     else if ($('#Edit_' + menuID + '_').is(":not(:checked)")) {
        //         ModifyPermiss = 0;
        //     }
        //     if ($('#Delete_' + menuID + '_').is(":checked")) {
        //         DelePermiss = 1;
        //     }
        //     else if ($('#Delete_' + menuID + '_').is(":not(:checked)")) {
        //         DelePermiss = 0;
        //     }

        //     var Up_rolevscap_object = {
        //         "MenuID": menuID,
        //         "Menuname": MenuName,
        //         "View": ViewPermiss,
        //         "Add": AddPermiss,
        //         "Edit": ModifyPermiss,
        //         "Delete": DelePermiss,
        //         "Print": expoPermiss
        //     } 
        //     JsonUpload.push(Up_rolevscap_object);
        // }
        var myJSON = JSON.stringify(ListMenu);
        var Param = {
            "name": Name,
            "mobilenumber": Phone,
            "pannumber": Pannum,
            "qualification": selectedQualiify,
            "password": "",
            "usertype": selectedUsertype,
            "email": Email,"searchField":myJSON,
            "mstuserid": ID, 
            "flag": Flag
        };
        var url = "/user/iuduser";
      //  //console.log(Param)
        let _response = await Api.IUD(Param, url);
        showAlert(_response)
        navigate('/rolebase')
        // //console.log(_response, "+++++++++++++");

    }
    const showAlert = (_response) => {
        Swal.fire({
            title: "Success",
            text: _response,
            icon: "success",
            confirmButtonText: "OK",
        });
    };


    async function Get(id) {

        var Param = { mstuserid: id }
        var url = "/User/BindUser";
        let _response = await Api.BindGrid(Param, url)
        // //console.log("getrrrrrrr",_response)

        //   //console.log(_response.AadharNo,"+++++++++++++");
        setName(_response[0].Name);
        setMobilenumber(_response[0].Mobilenumber
        );
        setPannumber(_response[0].Pannumber
        );
        setEmailid(_response[0].Email
        );
        setselectedUsertype(_response[0].Usertype
        );
        setselectedQualiify(_response[0].Qualification
        );
        setAadharnumber(_response[0].aadharno);
        setListMenu(JSON.parse(_response[0].RoleVSCab))
      //  //console.log("_resptgftyfonse",JSON.parse(_response[0].RoleVSCab));
    }

    async function GetMenu(id) {
        var Param = { mstmenuid: id }
        var url = "/Menu/BindMenu";
        let _response = await Api.BindGrid(Param, url)
       // //console.log("get", _response)

   
        setListMenu(_response);

       // //console.log(_response);
    }


    useEffect(() => {

        document.getElementsByClassName("work")
       // //console.log("test")

    }, []);

    const handleOnChange = (event, option, index,F) => {
        
        const values = [...ListMenu];
        if(event.target.checked){
            if(F ==="V"){
                values[index].View = 1;
            }if(F ==="A"){
                values[index].Add = 1;
            }if(F ==="E"){
                values[index].Edit = 1;
            }if(F ==="D"){
                values[index].Delete = 1;
            }if(F ==="P"){
                values[index].Print = 1;
            }
        }
        else{
            if(F ==="V"){
                values[index].View = 0;
            }if(F ==="A"){
                values[index].Add = 0;
            }if(F ==="E"){
                values[index].Edit = 0;
            }if(F ==="D"){
                values[index].Delete = 0;
            }if(F ==="P"){
                values[index].Print = 0;
            }
        }
        setListMenu(values);
       // //console.log(ListMenu)
     };

    return (


        <div>
            <Toaster toastOptions={{ position: "top-right" }} />
            <Base />
            <div id="main" class="main">
                <div class="pagetitle">
                    <h1>Tables</h1>
                    <nav>
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><Link to="/dash">Home</Link></li>
                            <li class="breadcrumb-item">User</li>
                            <li class="breadcrumb-item active">Form</li>
                        </ol>
                    </nav>
                </div>

                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">User Registration</h5>


                        <div class="row g-3">
                            <div class="col-md-6">
                            <label for="name">Name</label>
                                <input type="text" class="form-control" disabled={Flag == "D" || Flag == "null" ? true : false} value={name} placeholder="Name" onChange={(e) => setName(e.target.value)} />
                            </div>
                            <div class="col-md-6">
                            <label for="mobilenumber">Mobile Number</label>
                                <input type="number" class="form-control no-spinner"disabled={Flag == "D" || Flag == "null" ? true : false} value={mobileno} placeholder="Mobile Number" onChange={(e) => setMobilenumber(e.target.value)} />
                            </div>
                            <div class="col-6">
                            <label for="pannumber">Pan Number</label>
                                <input type="text" class="form-control"disabled={Flag == "D" || Flag == "null" ? true : false} value={panno} placeholder="Pan Number" onChange={(e) => setPannumber(e.target.value)} />
                            </div>
                           <div class="col-md-6">
                           <label for="qualification">Qualification</label>
                            <select id="inputState" class="form-select"disabled={Flag == "D" || Flag == "null" ? true : false} value={selectedQualiify} onChange={(e) => setselectedQualiify(e.target.value)}  >
                            <option selected>Qualification</option>
                                {qualification.map((i,index) =>(
                                    <option value={i.id}>{i.Text}</option>
                                ))}
                            </select>
                        </div>
                        
                        <div class="col-md-6">
                        <label for="usertype">User Type</label>
                            <select id="inputState" class="form-select" disabled={Flag == "D" || Flag == "null" ? true : false} value={selectedUsertype}  onChange={(e) => setselectedUsertype(e.target.value)}>
                                <option selected>User Type</option>
                                {usertype.map((i,index) =>(
                                    <option value={i.id}>{i.Text}</option>
                                ))}
                            </select>
                        </div>

                            <div class="col-6">
                            <label for="email">Email</label>
                                <input type="text" class="form-control"disabled={Flag == "D" || Flag == "null" ? true : false} value={emailid} placeholder="Email ID" onChange={(e) => setEmailid(e.target.value)} />
                            </div>

                            <div class="col-6">
                            <label for="aadharnumber">Aadhar Number</label>
                                <input type="text" class="form-control"disabled={Flag == "D" || Flag == "null" ? true : false} value={aadharno} placeholder="Aadhaar Number" onChange={(e) => setAadharnumber(e.target.value)} />
                            </div>

                            <div class="text-center">
                                <button type="button" class="btn btn-primary" onClick={IUDOperation}>Submit</button>
                                <button type="reset" class="btn btn-info">
                                    <Link to='/rolebase'>
                                        Back
                                    </Link>
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
                <table class="table table-striped">
                    <thead>
                        <tr>
                            
                            <th scope="col">Menu Name</th>
                            <th scope="col">Rights</th>


                        </tr>
                    </thead>
                    <tbody>
                        {ID==0?<>
                            {ListMenu.map((i, index) => (
                            <tr>
                                <td>{i.Menuname}</td>
                                <td> 
                                    <input class="form-check-input"   type="checkbox" onChange={(e) => handleOnChange(e, i, index,"V")} checked={i.View === 1} value="" id={"View_"+i.Mstmenuid+"_"} />View 
                                    <input class="form-check-input"  type="checkbox" onChange={(e) => handleOnChange(e, i, index,"A")} checked={i.Add === 1} value="" id={"Add_"+i.Mstmenuid+"_"} />Add
                                    <input class="form-check-input"  type="checkbox"  onChange={(e) => handleOnChange(e, i, index,"E")} checked={i.Edit === 1}value="" id={"Edit_"+i.Mstmenuid+"_"} />Edit
                                    <input class="form-check-input"   type="checkbox"  onChange={(e) => handleOnChange(e, i, index,"P")} checked={i.Print === 1}value="" id={"Print_"+i.Mstmenuid+"_"} />Print
                                    <input class="form-check-input"  type="checkbox" onChange={(e) => handleOnChange(e, i, index,"D")} checked={i.Delete === 1} value="" id={"Delete_"+i.Mstmenuid+"_"}/> Delete
                                </td>
                            </tr>

                        ))}
                        </>
                       
                        :
                        <>
                            {ListMenu.map((i, index) => (
                            <tr>
                                <td>{i.Menuname}</td>
                                <td> 
                                    <input class="form-check-input"   type="checkbox" onChange={(e) => handleOnChange(e, i, index,"V")} checked={i.View === 1} value="" id={"View_"+i.Mstmenuid+"_"} />View 
                                    <input class="form-check-input"  type="checkbox" onChange={(e) => handleOnChange(e, i, index,"A")} checked={i.Add === 1} value="" id={"Add_"+i.Mstmenuid+"_"} />Add
                                    <input class="form-check-input"  type="checkbox"  onChange={(e) => handleOnChange(e, i, index,"E")} checked={i.Edit === 1}value="" id={"Edit_"+i.Mstmenuid+"_"} />Edit
                                    <input class="form-check-input"   type="checkbox"  onChange={(e) => handleOnChange(e, i, index,"P")} checked={i.Print === 1}value="" id={"Print_"+i.Mstmenuid+"_"} />Print
                                    <input class="form-check-input"  type="checkbox" onChange={(e) => handleOnChange(e, i, index,"D")} checked={i.Delete === 1} value="" id={"Delete_"+i.Mstmenuid+"_"}/> Delete
                                </td>
                            </tr>

                        ))}
                        </>}
                    </tbody >
                </table >



            </div >

        </div >
    )
}
//         <div>
//              <Toaster toastOptions={{ position: "top-right" }} />
//             <Base />
//             <div id="main" class="main">
//                 <div class="pagetitle">
//                     <h1>Tables</h1>
//                     <nav>
//                         <ol class="breadcrumb">
//                             <li class="breadcrumb-item"><Link to="/dash">Home</Link></li>
//                             <li class="breadcrumb-item">User</li>
//                             <li class="breadcrumb-item active">Form</li>
//                         </ol>
//                     </nav>
//                 </div>

//                 <div class="card">
//                     <div class="card-body">
//                         <h5 class="card-title">User Registration</h5>


//                         <div class="row g-3">
//                             <div class="col-md-6">
//                                 <input type="text" class="form-control" value={name} placeholder="Name" onChange={(e) => setName(e.target.value)}  />
//                             </div>
//                             <div class="col-md-6">
//                                 <input type="number" class="form-control" value={mobileno} placeholder="Mobile Number" onChange={(e) => setMobilenumber(e.target.value)} />
//                             </div>
//                             <div class="col-6">
//                                 <input type="text" class="form-control" value={panno} placeholder="Pan Number" onChange={(e) => setPannumber(e.target.value)}  />
//                             </div>
//                             <div class="col-md-6">
//                                 <select id="inputState" class="form-select" value={qualification} onChange={(e) => setQualification(e.target.value)}  >
//                                     <option selected>Qualification</option>
//                                     <option>12th</option>
//                                     <option>UG</option>
//                                     <option>PG</option>
//                                 </select>
//                             </div>
//                             <div class="col-md-6">
//                                 <select id="inputState" class="form-select" value={usertype} onChange={(e)=> setUsertype(e.target.value)}>
//                                     <option selected>User Type</option>
//                                     <option>Admin</option>
//                                     <option>Employee</option>
//                                     <option>Agent</option>
//                                     <option>Job Seeker</option>
//                                     <option>Employer</option>

//                                 </select>
//                             </div>

//                             <div class="col-6">
//                                 <input type="text" class="form-control" value={emailid} placeholder="Email ID" onChange={(e) => setEmailid(e.target.value)}  />
//                             </div>

//                             <div class="col-6">
//                                 <input type="text" class="form-control" value={aadharno} placeholder="Aadhaar Number" onChange={(e) => setAadharnumber(e.target.value)} />
//                             </div>

//                             <div class="text-center">
//                                 <button type="submit" class="btn btn-primary"  onClick={IUDOperation}>Submit</button>
//                                 <button type="reset" class="btn btn-info">
//                                     <Link to='/Role_table'>
//                                         Back
//                                     </Link>
//                                 </button>
//                             </div>
//                         </div>

//                     </div>
//                 </div>
//                 <table class="table table-striped">
//                     <thead>
//                         <tr>
//                             <th scope="col">
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
//                                 Select All</th>
//                             <th scope="col">Menu Name</th>
//                             <th scope="col">Rights</th>


//                         </tr>
//                     </thead>
//                     <tbody>
//                         <tr>
//                             <th scope="row">
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
//                             </th>
//                             <td>Company Profile</td>
//                             <td>

//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />Add
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />Edit
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />Print
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> Delete
//                             </td>

//                         </tr>
//                         <tr>
//                             <th scope="row">
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
//                             </th>
//                             <td>Employee Creation</td>
//                             <td>
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />Add
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />Edit
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />  Print
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> Delete
//                             </td>

//                         </tr>
//                         <tr>
//                             <th scope="row">
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
//                             </th>
//                             <td>Employer Creation</td>
//                             <td>
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />Add
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />Edit
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />  Print
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> Delete
//                             </td>

//                         </tr>
//                         <tr>
//                             <th scope="row">
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
//                             </th>
//                             <td>Job Seeker Creation</td>
//                             <td>
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />Add
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />Edit
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />  Print
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> Delete
//                             </td>

//                         </tr>

//                         <tr>
//                             <th scope="row">
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
//                             </th>
//                             <td>Agent Creation</td>
//                             <td>
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />Add
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />Edit
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />  Print
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> Delete
//                             </td>

//                         </tr>
//                         <tr>
//                             <th scope="row">
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
//                             </th>
//                             <td>Job Seeker Creation</td>
//                             <td>
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />Add
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />Edit
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />  Print
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> Delete
//                             </td>

//                         </tr>


//                         <tr>
//                             <th scope="row">
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
//                             </th>
//                             <td>Staff Grade Type Creation</td>
//                             <td>
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />Add
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />Edit
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />  Print
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> Delete
//                             </td>

//                         </tr>
//                         <tr>
//                             <th scope="row">
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
//                             </th>
//                             <td>Staff Grade / Designation Creation</td>
//                             <td>
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />Add
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />Edit
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />  Print
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> Delete
//                             </td>

//                         </tr>
//                         <tr>
//                             <th scope="row">
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
//                             </th>
//                             <td>Office Type Creation</td>
//                             <td>
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />Add
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />Edit
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />  Print
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> Delete
//                             </td>

//                         </tr>
//                         <tr>
//                             <th scope="row">
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
//                             </th>
//                             <td>Office Grade Creation</td>
//                             <td>
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />Add
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />Edit
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />  Print
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> Delete
//                             </td>

//                         </tr>
//                         <tr>
//                             <th scope="row">
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
//                             </th>
//                             <td>Department Creation</td>
//                             <td>
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />Add
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />Edit
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />  Print
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> Delete
//                             </td>

//                         </tr>
//                         <tr>
//                             <th scope="row">
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
//                             </th>
//                             <td>Job Seeker (Candidate) Plan Creation</td>
//                             <td>
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />Add
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />Edit
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />  Print
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> Delete
//                             </td>

//                         </tr>
//                         <tr>
//                             <th scope="row">
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
//                             </th>
//                             <td>Employer Plan Creation</td>
//                             <td>
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />Add
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />Edit
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />  Print
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> Delete
//                             </td>

//                         </tr>
//                         <tr>
//                             <th scope="row">
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
//                             </th>
//                             <td>Branch Performance Report</td>
//                             <td>
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />Add
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />Edit
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />  Print
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> Delete
//                             </td>

//                         </tr>
//                         <tr>
//                             <th scope="row">
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
//                             </th>
//                             <td>Employee Performance Report</td>
//                             <td>
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />Add
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />Edit
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />  Print
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> Delete
//                             </td>

//                         </tr>
//                         <tr>
//                             <th scope="row">
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
//                             </th>
//                             <td>Agent / Broker Performance Report</td>
//                             <td>
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />Add
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />Edit
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />  Print
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> Delete
//                             </td>

//                         </tr>
//                         <tr>
//                             <th scope="row">
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
//                             </th>
//                             <td>Divisional Office Covering Dist List creation</td>
//                             <td>
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />Add
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />Edit
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />  Prin
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> Delete
//                             </td>

//                         </tr>
//                         <tr>
//                             <th scope="row">
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
//                             </th>
//                             <td>Bank Account</td>
//                             <td>
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />Add
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />Edit
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />  Print
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> Delete
//                             </td>

//                         </tr>

//                         <tr>
//                             <th scope="row">
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
//                             </th>
//                             <td>Receipts/Payments</td>
//                             <td>
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />Add
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />Edit
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />  Print
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> Delete
//                             </td>

//                         </tr>

//                         <tr>
//                             <th scope="row">
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
//                             </th>
//                             <td>Broker Commision  Creation</td>
//                             <td>
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />Add
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />Edit
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />  Print
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> Delete
//                             </td>

//                         </tr>

//                         <tr>
//                             <th scope="row">
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
//                             </th>
//                             <td>Agent Commision Creation</td>
//                             <td>
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />Add
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />Edit
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />  Print
//                                 <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> Delete
//                             </td>

//                         </tr>


//                     </tbody>
//                 </table>



//             </div>

//         </div>
//     )
// }
