import React, { useRef, useState, useEffect } from 'react';
import Base from './Base';
import { Link, useLocation, useNavigate } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import Api from './Services/ApiCall';
import jsPDF from 'jspdf';
import Swal from 'sweetalert2';
import css from '../App.css';
import { Images } from '../Images/Index';
import invoicebill from './invoicebill.css';
import $ from 'jquery';
import JobseekerBase from './Jobseeker/JobseekerBase';
import EmployerBase from './Employer/EmployerBase';
import {
  MDBCard,
  MDBCardBody,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBIcon,
  MDBTypography,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
} from "mdb-react-ui-kit";


export default function App() {
  const location = useLocation();
  const Amount = location.state; 

  // const Tax = Amount.Tax ? Amount.Tax : 0;
  const Tax = Amount.Tax ?? 0;
  const Tax_percent_amount = Amount.Tax_percent_amount
  const CGST = Tax_percent_amount / 2;
  const SGST = Tax_percent_amount / 2;
  const Finel_amount = Amount.Finel_amount




  const [setAmount] = useState("");
  const [setPlan] = useState("");
  const [setPlanperiod] = useState("");
  const [billingaddress, setBillingaddress] = useState("");
  const [paymenttype, setPaymenttype] = useState("");
  const [GSTnumber, setGstnumber] = useState("");

  useEffect(() => {
    CompanyGet()
  }, []);




  const [place, setLocation] = useState("");
  const [companyname, setCompanyname] = useState("");
  const [emailid, setEmailid] = useState("");
  const [contactnumber, setContactnumber] = useState("");
  const [website, setWebsite] = useState("");
  const [gstnumber, setGstNumber] = useState("");
  const [logo, setLogo] = useState("");
  const { base64Data } = logo;

  async function CompanyGet(id) {
    var Param = { Mstcompanyprofileid: 3 }
    var url = "/Company/BindCompany";
    let _response = await Api.BindGrid(Param, url)

    setCompanyname(_response[0].Companyname);
    setLocation(_response[0].Location);
    setEmailid(_response[0].Emailid);
    setContactnumber(_response[0].Phonenumber);
    setWebsite(_response[0].Website);
    setGstNumber(_response[0].SearchField);
    setLogo(JSON.parse(_response[0].Logo));
  }

  var Address = ""
  if (sessionStorage.getItem('AccessType') === "Agent" || sessionStorage.getItem('AccessType') === "Employee"
    || sessionStorage.getItem('AccessType') === "User") {
    Address = Amount.Address;
  }
  else {
    Address = JSON.parse(sessionStorage.getItem('userdetails'))[0]['Address'];

  }

  var OrderRef = ""
  if (sessionStorage.getItem('AccessType') === "Agent") {
    OrderRef = JSON.parse(sessionStorage.getItem('userdetails'))[0]['Msusertregcode'];
  }
  else {
    OrderRef = "";

  }
  var Dispatchfrom = ""
  if (sessionStorage.getItem('AccessType') === "Agent") {
    Dispatchfrom = JSON.parse(sessionStorage.getItem('userdetails'))[0]['Address'];
  }
  else {
    Dispatchfrom = companyname;

  }
  async function IUD() {

    // var Paymenttype=paymenttype;
    // var Billingaddress=billingaddress;
    // var Gstnumber=gstnumber;

    var Amount1 = Amount.Amount;
    var billnumber = Amount.Billnumber;


    var Param = {
      "mstinvoiceid": 0,
      "invoicecode": billnumber,
      "branchaddress": Dispatchfrom,
      "gstno": "Gstnumber",
      "billingto": Address,
      "shippingto": Address,
      "orderref": "OrderRef",
      "itemcode": "",
      "description": Amount.Plan,
      "hsn": "",
      "qty": "1",
      "uniteprice": Amount1,
      "tax": "",
      "total": Amount1,
      "active": "",
      "searchField": "",
      "createdBy": 0,
      "createdDate": "2023-01-26T14:12:23.698Z",
      "flag": "I"

    };
    var url = "/Invoice/IUDInvoice";
    let _response = await Api.IUD(Param, url);
    showAlert(_response)
    IUDOperation();
  }
  const showAlert = (_response) => {
    Swal.fire({
      title: "Success",
      text: _response,
      icon: "success",
      confirmButtonText: "OK",
    });
  };



  var d = new Date();

  const pdfRef = useRef(null);

  async function GetAddress(value) {
    var id = value;
    var url = "/DropDown/GetAddress?id=" + id;
    let _response = await Api.BindDropdown(url)
    let Arry_Title1 = [];
    setBillingaddress(_response)
  }
  async function IUDOperation() {
    const content = pdfRef.current;
    const pdf = new jsPDF('pt', 'mm', [1500, 1000]);
    pdf.html(content, {
      callback: function (pdf) {
        pdf.setFontSize('8');
        // pdf.scale('21')
        pdf.save('Invoice.pdf');
      }
    })
  }





  return (
    <div>
      {sessionStorage.getItem('AccessType') === "Employer" ?
        <EmployerBase />
        : null}
      {sessionStorage.getItem('AccessType') === "jobseeker" ?
        <JobseekerBase />
        : null}
      {sessionStorage.getItem('AccessType') === "User" || sessionStorage.getItem('AccessType') === "Agent" || sessionStorage.getItem('AccessType') === "Employee" ?
        <Base />
        : null}
      <div id="main" class="main">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Invoice Details</h5>


            <div class="text-center" style={{ marginRight: '100px' }}>
              <button type="button" class="btn btn-primary" onClick={IUD}>Download Invoice</button>
              {sessionStorage.getItem('AccessType') === "Employer" ?
                <button type="reset" class="btn btn-info">
                  <Link to='/Employerdashboard'>
                    Back
                  </Link>
                </button>
                : null}
              {sessionStorage.getItem('AccessType') === "jobseeker" ?
                <button type="reset" class="btn btn-info">
                  <Link to='/Jobseekerdashboard'>
                    Back
                  </Link>
                </button>
                : null}
              {sessionStorage.getItem('AccessType') === "User" || sessionStorage.getItem('AccessType') === "Agent" || sessionStorage.getItem('AccessType') === "Employee" ?
                <button type="reset" class="btn btn-info">
                  <Link to='/dash'>
                    Back
                  </Link>
                </button>
                : null}
            </div>

          </div>

          <div ref={pdfRef} style={{ width: '100%' }}>
            <MDBContainer className="py-5">
              <MDBCard className="p-4">
                <MDBCardBody>
                  <MDBContainer className="mb-2 mt-3">
                    <MDBRow className="d-flex align-items-baseline">
                      <MDBCol xl="6">
                        <img src={`data:image;base64,${base64Data}`}
                          style={{ width: '50%', marginLeft: '20%', marginTop: '20px' }} />
                        {/* <p style={{ color: "#7e8d9f", fontSize: "20px" }}>
                  Invoice &gt; &gt; <strong>ID: #123-123</strong>
                </p> */}
                        <p><span style={{ fontWeight: 'bold' }}>Dispatch From:</span>{companyname}</p>
                      </MDBCol>
                      <MDBCol xl="6" className="float-end">

                        {sessionStorage.getItem('AccessType') === "Agent" ?
                          <>
                            {/* <td colspan="3" style={{ width: "150px" , borderRight: '1px solid black'}}> */}
                            <div style={{ marginRight: '50%' }}><p style={{ fontWeight: '700' }}>Dispatch From:</p></div>
                            <p>{JSON.parse(sessionStorage.getItem('userdetails'))[0]['Address']}</p>
                            {/* </td> */}
                          </>
                          :
                          <>
                            {/* <td colspan="3" style={{ width: "150px" , borderRight: '1px solid black'}}> */}
                            <div style={{ marginRight: '50%' }}><p style={{ fontWeight: '700' }}>Dispatch From:</p></div>
                            <p>{companyname}</p>
                            <p>{place}</p>
                            <p>{contactnumber}</p>
                            {/* </td> */}
                          </>
                        }
                        {/* <MDBBtn
                  color="light"
                  ripple="dark"
                  className="text-capitalize border-0"
                >
                  <MDBIcon fas icon="print" color="primary" className="me-1" />
                  Print
                </MDBBtn>  */}
                        {/* <MDBBtn
                  color="light"
                  ripple="dark"
                  className="text-capitalize border-0 ms-2"
                >
                  <MDBIcon
                    far
                    icon="file-pdf"
                    color="danger"
                    className="me-1"
                  />
                  Export
                </MDBBtn> */}
                        {/* {/* <hr /> */}
                      </MDBCol>
                    </MDBRow>
                  </MDBContainer>
                  <MDBContainer>
                    <MDBCol md="12" className="text-center">
                      <MDBIcon
                        fab
                        icon="mdb"
                        size="4x"
                        className="ms-0 "
                        style={{ color: "#5d9fc5" }}
                      />
                      {/* <p className="pt-0">MDBootstrap.com</p> */}
                    </MDBCol>
                  </MDBContainer>
                  <MDBRow>
                    <MDBCol xl="8">
                      <MDBTypography listUnStyled>
                        {sessionStorage.getItem('AccessType') === "Agent" || sessionStorage.getItem('AccessType') === "Employee" || sessionStorage.getItem('AccessType') === "User" ?
                          <>
                            <li className="text-muted">
                              <span className="fw-bold ms-1">To:</span> <span style={{ color: "#5d9fc5" }}>{Amount.Name}</span>
                            </li>
                            <li className="text-muted">{Amount.Address}</li>
                            <li className="text-muted">{Amount.Code}</li>
                            <li className="text-muted">
                              <MDBIcon fas icon="phone-alt" /> {Amount.Mobilenumber}
                            </li>
                          </>
                          :
                          <>
                            <li className="text-muted">
                              To: <span style={{ color: "#5d9fc5" }}>{JSON.parse(sessionStorage.getItem('userdetails'))[0]['Name']}</span>
                            </li>
                            <li className="text-muted">{JSON.parse(sessionStorage.getItem('userdetails'))[0]['Address']}</li>
                            {sessionStorage.getItem('AccessType') == 'Employer' ?
                              <li className="text-muted">{JSON.parse(sessionStorage.getItem('userdetails'))[0]['Msusertregcode']}</li>
                              :
                              <li className="text-muted">{JSON.parse(sessionStorage.getItem('userdetails'))[0]['Mstjobseekercode']}</li>
                            }
                            <li className="text-muted">
                              <MDBIcon fas icon="phone-alt" /> {JSON.parse(sessionStorage.getItem('userdetails'))[0]['Mobilenumber']}
                            </li></>
                        }
                      </MDBTypography>
                    </MDBCol>
                    <MDBCol xl="4">
                      {/* <p className="text-muted">Invoice</p> */}
                      <MDBTypography listUnStyled>
                        <li className="text-muted">
                          <MDBIcon fas icon="circle" style={{ color: "#84B0CA" }} />
                          <span className="fw-bold ms-1">ID:</span>{Amount.Billnumber}
                        </li>
                        <li className="text-muted">
                          <MDBIcon fas icon="circle" style={{ color: "#84B0CA" }} />
                          <span className="fw-bold ms-1">Creation Date: </span>{d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear()}
                        </li>
                        <li className="text-muted">
                          <MDBIcon fas icon="circle" style={{ color: "#84B0CA" }} />
                          <span className="fw-bold ms-1">Status:</span>
                          <span className="badge bg-warning text-black fw-bold ms-1">
                            Paid
                          </span>
                        </li>
                      </MDBTypography>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="my-2 mx-1 justify-content-center">
                    <MDBTable striped borderless>
                      <MDBTableHead
                        className="text-white"
                        style={{ backgroundColor: "#84B0CA" }}
                      >
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Description</th>
                          <th scope="col">Qty</th>
                          <th scope="col">Unit Price</th>
                          <th scope="col">GST</th>
                          <th scope="col">Amount</th>
                        </tr>
                      </MDBTableHead>
                      <MDBTableBody>
                        <tr>
                          <th scope="row">1</th>
                          <td style={{ textAlign: "initial" }}>{Amount.Plan}</td>
                          <td style={{ textAlign: "initial" }}>1</td>
                          <td style={{ textAlign: "initial" }}>{Amount.Amount}</td>
                          <td style={{ textAlign: "initial" }}>{Tax_percent_amount}</td>
                          {/* <td style={{ textAlign: "initial" }}>{SGST}</td> */}
                          <td style={{ textAlign: "initial" }}>{Finel_amount}</td>
                        </tr>
                      </MDBTableBody>
                    </MDBTable>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol xl="8">
                      {/* <p className="ms-3">
                Add additional notes and payment information
              </p> */}
                    </MDBCol>
                    <MDBCol xl="3">
                      {/* <MDBTypography listUnStyled>
                <li className="text-muted ms-3">
                  <span class="text-black me-4">SubTotal</span>$1110
                </li>
                <li className="text-muted ms-3 mt-2">
                  <span class="text-black me-4">Tax(15%)</span>$111
                </li> */}
                      {/* </MDBTypography> */}
                      <p className="text-black float-start">
                        <span className="text-black me-3"> Amount:</span>
                        <span style={{ fontSize: "25px" }}>{Amount.Amount}</span>
                      </p>
                      <p className="text-black float-start">
                        <span className="text-black me-3"> CGST:</span>
                        <span style={{ fontSize: "25px" }}>{CGST}</span>
                      </p>
                      <p className="text-black float-start">
                        <span className="text-black me-3"> SGST:</span>
                        <span style={{ fontSize: "25px" }}>{SGST}</span>
                      </p>
                      <p className="text-black float-start">
                        <span className="text-black me-3"> Total Amount:</span>
                        <span style={{ fontSize: "25px" }}>{Finel_amount}</span>
                      </p>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol xl="8">
                      {/* <p>Thank you for </p> */}
                    </MDBCol>
                    <MDBCol xl="4">
                      {sessionStorage.getItem('AccessType') === "Agent" || sessionStorage.getItem('AccessType') === "Employee" || sessionStorage.getItem('AccessType') === "User" ?
                        <>
                          <div style={{ marginTop: '20%' }}>

                            <p>Authorized Signatory</p>
                          </div>

                          <div >
                            <p style={{ fontWeight: 'bold' }}>
                              <span>{sessionStorage.getItem('username')}</span></p>
                          </div>
                        </>
                        :
                        <p>Your Payment Is Successfull.Thanks for Subscribe</p>}
                    </MDBCol>
                  </MDBRow>
                </MDBCardBody>
              </MDBCard>
            </MDBContainer>
          </div>
        </div>
      </div>
    </div>
  );
}