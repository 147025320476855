import React, { useState, useEffect } from "react";
import Api from '../Services/ApiCall';
import Base from '../Base';
import { Link } from "react-router-dom";
import '../../App.css'
import { useTable, useSortBy, usePagination, useGlobalFilter } from "react-table";
import { Globalfilter } from "../Admin/Admin_form/Filter/Globalfilter";
import toast, { Toaster } from 'react-hot-toast';
import { Button, Modal } from 'react-bootstrap';

// Define your Table component here
function Table({ columns, data }) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state,
        setGlobalFilter,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize, },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 },
        },
        useGlobalFilter, useSortBy, usePagination
    );
    const { globalFilter } = state;

    return (
        <div>
            <Globalfilter className="form-control" filter={globalFilter} setFilter={setGlobalFilter} />
            <table className="table table-striped" {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}  >
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>{column.render("Header")}
                                    <span>
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? <i className="fa-fa-sort-asc"></i>
                                                : <i className="fa-fa-sort-desc"></i>
                                            : ''}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return (
                                        <td  {...cell.getCellProps()} className={` ${cell.column.className ?? ""}`}>
                                            {cell.render("Cell")}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <br></br>
            <div className="pagination">
                <nav className="paginate page_numbers" aria-label="Page navigation">
                </nav>
                <ul className="pagination first_last">
                    <li className="page-item"><a className="page-link"  onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                        {"First"}</a>
                    </li>{" "}
                    <li className="page-item"><a className="page-link"  onClick={() => previousPage()} disabled={!canPreviousPage}>
                        {"<"}
                    </a>
                    </li>{" "}
                    <li className="page-item"><a className="page-link"  onClick={() => nextPage()} disabled={!canNextPage}>
                        {">"}
                    </a>
                    </li>{" "}
                    <li className="page-item"><a className="page-link"  onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                        {"Last"}
                    </a>
                    </li>{" "}
                </ul>
                <span className="pageno">
                    Page{" "}
                    <strong>
                        {pageIndex + 1} of {pageOptions.length}
                    </strong>{" "}
                </span>
                <div className='showpagesize' >
                    Show:{" "}
                    <select
                        className='showpagecss'
                        value={pageSize}
                        onChange={e => {
                            setPageSize(Number(e.target.value));
                        }}
                    >
                        {[2, 10, 20, 30, 40, 50].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </div>
    );
}

export default function Email_edit_table() {
    const [listdata, setListData] = useState([]);
    const [_Usertype, set_Usertype] = useState("User"); // Default to "User" type

    useEffect(() => {
        // Fetch data when the component mounts
        fetchData(_Usertype);
    }, [_Usertype]); // Re-fetch data when _Usertype changes

    async function fetchData(userType) {
        // Based on userType, fetch data from the API
        try {
            let response;
            if (userType === "Jobseeker") {
                // Fetch Jobseeker data
                const Param = { mstjobseekerid: 0 };
                const url = "/JobSeekerTable/BindJobSeekerTable";
                response = await Api.BindGrid(Param, url);
            } else {
                const Param = { 
                    "mstuserregid": 0,
                    "msusertregcode": "",
                    "name": "",
                    "mobilenumber": "",
                    "pannumber": "",
                    "qualification": "",
                    "plandetails": 0,
                    "usertype": 0,
                    "amount": 0,
                    "empbranch": "",
                    "incharge": "",
                    "emailid": "",
                    "aadhaarnumber": "",
                    "rolecab": "",
                    "agGradetype": "",
                    "agGrade": "",
                    "agDesignation": "",
                    "department": "",
                    "agAgentcode": "",
                    "officecode": "",
                    "reportingempcode": "",
                    "city": "",
                    "state": "",
                    "country": "",
                    "address": "",
                    "pincode": "",
                    "accountholdername": "",
                    "accountnumber": "",
                    "ifsccode": "",
                    "branchname": "",
                    "bankname": "",
                    "password": "",
                    "companyname": "",
                    "companyphoneNo": "",
                    "companyaddress": "",
                    "companygst": "",
                    "confirmpassword": "",
                    "rolevscab": "",
                    "active": "",
                    "flag": "",
                    "searchField": "",
                    "createdBy": 0,
                    "createdDate": "2023-10-31T15:39:49.867Z",
                    "subscribe": "",
                    "plantype": "",
                    "planperiod": "",
                    "activestatus": "",
                    "planamount": 0,
                    "accessType": "",
                    "organizationtype": "",
                    "industrytype": "",
                    "designation": "",
                    "idproof": "",
                    "district": "",
                    "aadharImage": "",
                    "panImage": "",
                    "bankImage": ""
                  };
                const url = "/UserReg/GetAllUser";
                response = await Api.BindGrid(Param, url);
            }
            setListData(response);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    const columns = [
        {
            Header: "ID",
            Cell: (row) => {
                return <div>{Number(row.row.id) + 1}</div>;
            },
        },
        {
            Header: "Code",
            accessor: _Usertype === "Jobseeker" ? "Mstjobseekercode" : "Msusertregcode",
        },
        {
            Header: "Emailid",
            accessor: _Usertype === "Jobseeker" ? "Email" : "Emailid",
        },
        {
            Header: "Actions",
            Cell: (row) => {
                const idToUse = _Usertype === "Jobseeker" ? row.data[row.row.id].Mstjobseekerid : row.data[row.row.id].Mstuserregid;
                const usertype=_Usertype === "Jobseeker" ? "Jobseeker" : "User";

                return (
                    <div>
                        <nav>
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <Link
                                        to={'/Email_edit_form'}
                                        state={{ ID: idToUse, Flag: 'U' ,Usertype:usertype}}
                                    >
                                        <i class="bi bi-pencil-square" /> 
                                    </Link>
                                </li>
                            </ol>
                        </nav>
                    </div>
                );
            }
        },
    ];
    

    return (
        <div>
            <Base />
            <div id="main" className="main">
                <div className="pagetitle">
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/dash">Home</Link>
                            </li>
                            <li className="breadcrumb-item">Email Edit Table</li>
                        </ol>
                    </nav>
                </div>
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body alignleft">
                            <div className="divGroup col-md-12">
                                <input
                                    type="radio"
                                    id="User"
                                    value="User"
                                    checked={_Usertype === "User"}
                                    onChange={(e) => set_Usertype(e.target.value)}
                                />
                                <label htmlFor="User">User's</label>
                                <input
                                    type="radio"
                                    id="Jobseeker"
                                    value="Jobseeker"
                                    checked={_Usertype === "Jobseeker"}
                                    onChange={(e) => set_Usertype(e.target.value)}
                                />
                                <label htmlFor="Jobseeker">Jobseeker's</label>
                            </div>
                            <h5 className="card-title"> {_Usertype} </h5>
                            <Table columns={columns} data={listdata} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
