
import mainlogo from '../Images/mainlogo.jpg';
import dummyaadhar from '../Images/dummyaadhar.jpg';
import aadhar from '../Images/aadhar.jpg';
import backgroundimg from '../Images/background.jpg';
import logo from '../Images/logo.jpeg'
import upperlogo from '../Images/upperlogo.jpg';


export const Images = {
mainlogo,
dummyaadhar,
aadhar,
backgroundimg,
upperlogo,
logo
}