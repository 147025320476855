import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import GuestTable from './Components/Admincontrol/Guest';
import Guestform from './Components/Admincontrol/UserAccess/Guestform';
import Otherusers from './Components/Admincontrol/Otherusers';
import Jobseekerpreview from './Components/Agents/Agent_form/Jobseekerpreview';
import Companyprofiletable from './Components/Admin/Companyprofiletable';
import Staffgradetypetable from './Components/Admin/Staffgradetypetable';
import Staffgradedesignationtable from './Components/Admin/Staffgradedesignation';
import Officetypecreation from './Components/Admin/Officetypecreation';
import Officegradecreation from './Components/Admin/Officegradecreation';
import Departmentcreation from './Components/Admin/Departmentcreation';
import Jobseekerplancreation from './Components/Admin/Jobseekerplancreation';
import Employerplancreation from './Components/Admin/Employerplancreation';
import Brokercommision from './Components/Admin/Brokercommision';
import Agentcommision from './Components/Admin/Agentcommision';
import Bankaccount from './Components/Admin/Bankaccount';
import Branchperformance from './Components/Admin/Branchperformance';
import Employeeperformance from './Components/Admin/Employeeperformance';
import Divisionalofficedist from './Components/Admin/Divisionalofficedist';
import Agentbrokerperformance from './Components/Admin/Agentbrokerperformance';
import Agentregistration from './Components/Admin/Agentregistration';
import Employeecreation from './Components/Admin/Employeecreation';
import Depositetable from './Components/Admin/Deposittable';
import Employeecreationform from './Components/Admin/Admin_form/Employeecreationform';
import Agentregistrationform from './Components/Admin/Admin_form/Agentregistrationform';
import Companyprofileform from './Components/Admin/Admin_form/Companyprofileform';
import Staffgradetypeform from './Components/Admin/Admin_form/Staffgradetypeform';
import Staffgradedesignationform from './Components/Admin/Admin_form/Staffgradedesignationform';
import Officetypecreationform from './Components/Admin/Admin_form/Officetypecreationform';
import Officegradecreationform from './Components/Admin/Admin_form/Officegradecreationform';
import Departmentcreationform from './Components/Admin/Admin_form/Departmentcreationform';
import Jobseekerplancreationform from './Components/Admin/Admin_form/Jobseekerplancreationform';
import Employerplancreationform from './Components/Admin/Admin_form/Employerplancreationform';
import Brokercommisionform from './Components/Admin/Admin_form/Brokercommisionform';
import Agentcommisioncreationform from './Components/Admin/Admin_form/Agentcommisioncreationform';
import Bankaccountform from './Components/Admin/Admin_form/Bankaccountform';
import Branchperformanceform from './Components/Admin/Admin_form/Branchperformanceform';
import Employeeperformanceform from './Components/Admin/Admin_form/Employeeperformanceform';
import Divisionalofficedistlistform from './Components/Admin/Admin_form/Divisionalofficedistlistform';
import Agentbrokerperformanceform from './Components/Admin/Admin_form/Agentbrokerperformanceform';
import Depositform from './Components/Admin/Admin_form/Depositform'
import Jobtitleform from './Components/Agents/Agent_form/Jobtitleform'
import Jobtitletable from './Components/Agents/Jobtitletable';
import Editprofile from './Components/Admin/Admin_form/Editprofile';
import EmployerBase from './Components/Employer/EmployerBase';
import Postjobstable from './Components/Employer/Postjobstable';
import Appliedcandidate from './Components/Employer/Appliedcandidate';
import Viewedcandidate from './Components/Employer/Viewedcandidate';
import Jobsdescription from './Components/Jobsdescription';
import Appliedjobs from './Components/Jobseeker/Appliedjobs';
import Viewedjobs from './Components/Jobseeker/Viewedjobs';
import Invoice from './Components/Invoice';
import CandidateDescription from './Components/Employer/CandidateDescription';
import Payment from './Components/Payment';
import DeclareCommission from './Components/Agents/DeclareCommission';
import Declarecommissionform from './Components/Agents/Agent_form/Declarecommissionform';
import Employeelogin from './Components/Admin/Admin_form/Employeelogin';
import EmploeePaymentReport from './Components/EmployeePaymentReport';
import OnlinePaymentReport from './Components/OnlinePaymentReport';
import EmployeePerformanceReport from './Components/Admin/EmployeePerformanceReport';
import Employeeperformancereportform from './Components/Admin/Admin_form/Employeeperformancereportform';
import Termsandcondition from './Components/Termsandcondition';
import Jobseekerdetails from './Components/Jobseekerdetails';
import JobseekerSignup from './Components/JobseekerSignup/JobseekerIndex'
import Customersupportgrid from './Components/Admin/Customersupportgrid';
import Dashboard from './Components/Dashboard';
import ReceiptPayment_table from './Components/Admin/ReceiptPayment_table';
import EmployeeDASCreation_table from './Components/Admin/EmployeeDASCreation_table';
import EmployerCreation_table from './Components/Agents/EmployerCreation_table';
import Expenses_table from './Components/Admin/Expenses_table';
import JobSeeker_table from './Components/Agents/JobSeeker_table';
import Customersupporttable from './Components/Admin/Customersupporttable';
import Employerdashboard from './Components/Employerdashboard';
import Jobseekerdashboard from './Components/Jobseekerdashboard';
import Jobs from './Components/Jobs';
import Filterjobs from './Components/Filterjobs';
import Employmenttypetable from './Components/Agents/Employmenttypetable';
import Employmenttypeform from './Components/Agents/Agent_form/Employmenttypeform';
import Agentrequestform from './Components/Agents/Agent_form/Agentrequestform';
import Myprofile from './Components/Admin/Admin_form/Myprofile';
import Customersupportform from './Components/Admin/Admin_form/Customersupportform';
import ReceiptPaymentForm from './Components/Admin/Admin_form/ReceiptPaymentForm';
import EmployeeDASCreationForm from './Components/Admin/Admin_form/EmployeeDASCreationForm';
import EmployerCreationForm from './Components/Agents/Agent_form/EmployerCreationForm';
import ExpensesForm from './Components/Admin/Admin_form/ExpensesForm';
import JobSeekerForm from './Components/Agents/Agent_form/JobSeekerForm';
import Subscribeform from './Components/Agents/Agent_form/Subscribeform';
import Employersubscribeform from './Components/Agents/Agent_form/Employersubscribeform';
import Plannotification from './Components/Admin/Admin_form/Plannotification';
import Frontpage from './Components/Front_page/frontpage';
import Signin from './Components/Signin';
import Employersignupform from './Components/Employersignupform';
import Forgotpassword from './Components/Forgotpassword';
import ResumeForm from './Components/Resume/ResumeForm';
import Onlineemployersignup from './Components/Onlineemployersignup'
import Profile from './Components/Jobseeker/Profile';
import ProfileEmp from './Components/Employer/Profile';
import Updatejob from './Components/Employer/Updatejob';
import Role from './Components/Admincontrol/Role';
import UserForm from './Components/Admincontrol/UserAccess/UserForm';
import Email_edit_table from './Components/Admin/Email_edit_table';
import Email_edit_form from './Components/Admin/Admin_form/Email_edit_form'
import Register from './Components/Register';
import Rolegrid from './Components/Admincontrol/Rolegrid'
import Roleform from './Components/Admincontrol/UserAccess/Roleform'
import Tax from './Components/Admin/Tax'
import Tax_form from './Components/Admin/Admin_form/Tax_form';
import Reinvoice_grid from './Components/Reinvoice_grid';
import Reinvoice from './Components/reinvoice';
import Data from './data';
import GridExample from './Components/datatable';
import Employer_GridExample from './Components/Employer/Employer_data_table';
import OfficeCashReport from './Components/OfficeCashReport';
import TotalPayment from './Components/TotalPayment';
import Agent_Total_payment from './Components/Agent_totalpayment';
import Privacy_policy from './Components/Privacy_policy';
import Refund_policy from './Components/Refund_policy';
import AgentCreation from './Components/Admin/AgentCreation';
import AgentCreationform from '././Components/Admin/Admin_form/AgentCreationForm';
import AgentDashboard from './Components/Dashboard/AgentDashboard';
import BoJobseekerApproval from './Components/Jobseeker/BoJobseekerApproval';
import BoEmployerApproval from './Components/Employer/BoEmployerApproval';
import Jobseekerlists from './Components/Admin/Jobseekerlists';
import Master from './Components/Admin/Master';
import PayoutTable from './Components/Admin/Payout/PayoutTable';
import PayoutForm from './Components/Admin/Payout/PayoutForm';
import Employerledgerreporttable from './Components/Reports/Employerledgerreporttable';
import Jobseekerledgerreporttable from './Components/Reports/Jobseekerledgerreporttable';
import PreLoader from './Components/PreLoader';
import LiveChat from './Components/Livechat';
import ManagerSupport from './Components/Admin/ManagerSupport';
import AdvanceJobSearch from './Components/AdvanceJobSearch';
import ErrorPage from './Components/ErrorPage';
import Testimonial from './Components/Admin/testimonial';
import Bonusscreen from './Components/Bonusscreen';

function App() {

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 2000);
    return () => clearTimeout(timer);
  }, []);


  return (
    <div className="App">
      {loading ? (
        <PreLoader />
      ) : (
        <Router>
          <LiveChat />
          <Routes>
            <Route path='/userformadmin' element={<UserForm />}></Route>
            <Route path='/Jobseekerlists' element={<Jobseekerlists />}></Route>
            <Route path='/rolebase' element={<Role />}></Route>
            <Route path='/register' element={<Register />}></Route>
            <Route path='/Agent_Dashboard' element={<AgentDashboard />}></Route>
            <Route path="/Rolecreationgrid" element={<Rolegrid />}></Route>
            <Route path="/rolecreationform" element={<Roleform />}></Route>
            <Route path="/otherusers" element={< Otherusers />}></Route>
            <Route path="/TotalAgentPayments" element={< Agent_Total_payment />}></Route>
            <Route path="/Jobseekerpreview" element={<Jobseekerpreview />}></Route>
            <Route path="/Customersupportgrid" element={<Customersupportgrid />}></Route>
            <Route path="/OfficeCashReport" element={<OfficeCashReport />}></Route>
            <Route path="/TotalPayments" element={<TotalPayment />}></Route>
            <Route path='/Data_table' element={<GridExample />}></Route>
            <Route path='/Employer_Data_table' element={<Employer_GridExample />}></Route>
            <Route path='/Data' element={<Data />}></Route>
            <Route path='/guest_table' element={<GuestTable />}></Route>
            <Route path='/guestform' element={<Guestform />}></Route>
            <Route path='/tax' element={<Tax />}></Route>
            <Route path='/Taxform' element={<Tax_form />}></Route>
            <Route path='/profile' element={<Profile />}></Route>
            <Route path='/profileemp' element={<ProfileEmp />}></Route>
            <Route path='/updatejob' element={<Updatejob />}></Route>
            <Route path='/frontpage' element={<Frontpage />}></Route>
            <Route path='/AgentCreation' element={<AgentCreation />}></Route>
            <Route path="/companyprofiletable" element={<Companyprofiletable />}></Route>
            <Route path="/staffgradetypetable" element={< Staffgradetypetable />}></Route>
            <Route path="/staffgradedesignationtable" element={<Staffgradedesignationtable />}></Route>
            <Route path="/officetypecreationtable" element={<Officetypecreation />}></Route>
            <Route path="/officegradecreationtable" element={<Officegradecreation />}></Route>
            <Route path="/Departmentcreationtable" element={<Departmentcreation />}></Route>
            <Route path="/Agentplancreationtable" element={<Jobseekerplancreation />}></Route>
            <Route path="//employerplancreationtable" element={<Employerplancreation />}></Route>
            <Route path="brokercommisiontable" element={<Brokercommision />}></Route>
            <Route path="/agentcommisiontable" element={<Agentcommision />}></Route>
            <Route path="/bankaccounttable" element={<Bankaccount />}></Route>
            <Route path="/branchperformancetable" element={<Branchperformance />}></Route>
            <Route path="/employeeperformancetable" element={<Employeeperformance />}></Route>
            <Route path="/divisionalofficedistlisttable" element={< Divisionalofficedist />}></Route>
            <Route path="/agentbrokerperformancetable" element={<Agentbrokerperformance />}></Route>
            <Route path='/agentreg' element={<Agentregistration />}></Route>
            <Route path='/empcreationtable' element={<Employeecreation />}></Route>
            <Route path='/deposittable' element={<Depositetable />}></Route>
            <Route path='/Customersupporttable' element={<Customersupporttable />}></Route>
            <Route path='/Plannotification' element={<Plannotification />}></Route>
            <Route path='/Jobtitleform' element={<Jobtitleform />}></Route>
            <Route path='/Jobtitleform' element={<Jobtitleform />}></Route>
            <Route path='/Jobtitletable' element={<Jobtitletable />}></Route>
            <Route path='/Employerdashboard' element={<Employerdashboard />}></Route>
            <Route path='/Jobseekerdashboard' element={<Jobseekerdashboard />}></Route>
            <Route path='/Postjobstable' element={<Postjobstable />}></Route>
            <Route path='/Appliedcandidate' element={<Appliedcandidate />}></Route>
            <Route path='/Viewedcandidate' element={<Viewedcandidate />}></Route>
            <Route path='/Jobdescription' element={<Jobsdescription />}></Route>
            <Route path='/Appliedjobs' element={<Appliedjobs />}></Route>
            <Route path='/BoJobseekerApproval' element={<BoJobseekerApproval />}></Route>
            <Route path='/BoEmployerApproval' element={<BoEmployerApproval />}></Route>
            <Route path='/Viewedjobs' element={<Viewedjobs />}></Route>
            <Route path='/Invoice' element={<Invoice />}></Route>
            <Route path='/CandidateDescription' element={<CandidateDescription />}></Route>
            <Route path='/Payment' element={<Payment />}></Route>
            <Route path='/DeclareCommision' element={<DeclareCommission />}></Route>
            <Route path='/DeclareCommisionform' element={<Declarecommissionform />}></Route>
            <Route path='/Employeelogin' element={<Employeelogin />}></Route>
            <Route path='/EmploeePaymentReport' element={<EmploeePaymentReport />}></Route>
            <Route path='/OnlinePaymentReport' element={<OnlinePaymentReport />}></Route>
            <Route path='/EmployeePerformanceReport' element={<EmployeePerformanceReport />}></Route>
            <Route path='/Employeeperformancereportform' element={<Employeeperformancereportform />}></Route>
            <Route path='/Terms&condition' element={<Termsandcondition />}></Route>
            <Route path='/Privacy_policy' element={<Privacy_policy />}></Route>
            <Route path='/Refund_policy' element={<Refund_policy />}></Route>
            <Route path='/Jobseekerdetails' element={<Jobseekerdetails />}></Route>
            <Route path='/JobseekerSignup' element={<JobseekerSignup />}></Route>
            <Route path='/Email_edit_table' element={<Email_edit_table />}></Route>
            <Route path='/Email_edit_form' element={<Email_edit_form />}></Route>
            <Route path='/Filterjobs' element={<Filterjobs />}></Route>
            <Route path='/Jobs' element={<Jobs />}></Route>
            <Route path='/Masterconfiguration' element={<Master />}></Route>
            <Route path='/Filterjobs' elements={<Filterjobs />}></Route>
            <Route path='/Employmenttypetable' element={<Employmenttypetable />}></Route>
            <Route path='/Employmenttypeform' element={<Employmenttypeform />}></Route>
            <Route path='/Agentrequestform' element={<Agentrequestform />}></Route>
            <Route path='/Myprofile' element={<Myprofile />}></Route>
            <Route path='/Editprofile' element={<Editprofile />}></Route>
            <Route path='/Employerbase' element={<EmployerBase />}></Route>
            <Route path='/Customersupportform' element={<Customersupportform />}></Route>
            <Route path='/Employeecreationform' element={<Employeecreationform />}></Route>
            <Route path='/Agentregistrationform' element={<Agentregistrationform />}></Route>
            <Route path="/companyprofileform" element={<Companyprofileform />}></Route>
            <Route path="/staffgradetypeform" element={<Staffgradetypeform />}></Route>
            <Route path="/staffgradedesignationform" element={<Staffgradedesignationform />}></Route>
            <Route path="//officetypecreationform" element={<Officetypecreationform />}></Route>
            <Route path="/officegradecreationform" element={<Officegradecreationform />}></Route>
            <Route path="//Departmentcreationform" element={<Departmentcreationform />}></Route>
            <Route path="/plancreationform" element={<Jobseekerplancreationform />}></Route>
            <Route path="/employerplancreationform" element={<Employerplancreationform />}></Route>
            <Route path="/brokercommisioncreationform" element={<Brokercommisionform />}></Route>
            <Route path="/agentcommisioncreationform" element={<Agentcommisioncreationform />}></Route>
            <Route path="/bankaccountcreationform" element={<Bankaccountform />}></Route>
            <Route path="/branchperformanceform" element={<Branchperformanceform />}></Route>
            <Route path="/employeeperformanceform" element={<Employeeperformanceform />}></Route>
            <Route path="/divisionalofficedistlistform" element={<Divisionalofficedistlistform />}></Route>
            <Route path="/agentbrokerperformanceform" element={< Agentbrokerperformanceform />}></Route>
            <Route path="/depositform" element={< Depositform />}></Route>
            <Route path="/Subscribeform" element={< Subscribeform />}></Route>
            <Route path="/Rechargeform" element={< Employersubscribeform />}></Route>
            <Route path='/Signup' element={<Onlineemployersignup />}></Route>
            <Route path="/reinvoice_grid" element={<Reinvoice_grid />}></Route>
            <Route path="/reinvoice" element={<Reinvoice />}></Route>
            <Route exact path='/' element={<Signin />}></Route>
            <Route exact path='forgotpassword' element={<Forgotpassword />}></Route>
            <Route exact path="/dash" element={<Dashboard />}></Route>
            <Route path="/ReceiptPayment_table" element={<ReceiptPayment_table />}></Route>
            <Route path="/EmployeeDASCreation_table" element={<EmployeeDASCreation_table />}></Route>
            <Route path='/EmployerCreation_table' element={<EmployerCreation_table />}></Route>
            <Route path='/Expenses_table' element={<Expenses_table />}></Route>
            <Route path='/Jobseeker_table' element={<JobSeeker_table />}></Route>
            <Route path="/ReceiptPaymentForm" element={<ReceiptPaymentForm />}></Route>
            <Route path="/EmployeeDASCreationForm" element={<EmployeeDASCreationForm />}></Route>
            <Route path='/EmployerCreationForm' element={<EmployerCreationForm />}></Route>
            <Route path='/ExpensesForm' element={<ExpensesForm />}></Route>
            <Route path='/JobseekerForm' element={<JobSeekerForm />}></Route>
            <Route path='/resume' element={<ResumeForm />}></Route>
            <Route path='/AgentCreationform' element={<AgentCreationform />}></Route>
            <Route path='/PayoutTable' element={<PayoutTable />}></Route>
            <Route path='/PayoutForm' element={<PayoutForm />}></Route>
            <Route path='/Employerledgerreport' element={<Employerledgerreporttable />}></Route>
            <Route path='/Jobseekerledgerreport' element={<Jobseekerledgerreporttable />}></Route>
            <Route path='/ManagerSupport' element={<ManagerSupport />}></Route>
            <Route path='/AdvanceJobSearch' element={<AdvanceJobSearch />}></Route>
            <Route path='/error' element={<ErrorPage />}></Route>
            <Route path='/testimonial' element={<Testimonial />}></Route>
            <Route path='/Bonusscreen' element={<Bonusscreen />}></Route>
          </Routes>
        </Router>
      )}
    </div>
  );
}

export default App;