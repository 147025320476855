import React  ,{useState, useEffect}from 'react'
import Base from '../Base'
import { Link } from "react-router-dom";
import Api from '../Services/ApiCall';
export default function () {
    const [listdata, setlistdata] = useState([]);

    useEffect(() => {
        BindGrid(0)
    }, []);

    async function BindGrid(id) {
        var Param = {
            Mstagentregistrationid: id }
        var url = "/AgentRegistration/BindAgentRegis";
        try{

            let _response1 = await Api.BindGrid(Param, url)
            setlistdata(_response1)
        }catch{
        }
    }

  return (
    <div>
          <Base Agentcreate={true}/>
          <div id="main" class="main">
              <div class="pagetitle">
                  <h1>Tables</h1>
                  <nav>
                      <ol class="breadcrumb">
                          <li class="breadcrumb-item">
                            <Link to="/dash">Home</Link>
                            </li>
                          <li class="breadcrumb-item">Admin</li>
                      </ol>
                  </nav>
              </div>
          <div class="col-lg-12">

              <div class="card">
                  <div class="card-body">
                      <h5 class="card-title">Agent Registration Table</h5>
                          <button type="button" class="btn btn-primary Add">
                             
                              <Link to={'/Agentregistrationform'} state={{ ID: 0, Flag: 'I' }}>
                                  Add
                              </Link>
                          </button>
                      <table class="table table-striped">
                          <thead>
                               <tr>
                                  <th scope="col">SNo</th>
                                  <th scope="col">Name</th>
                                  <th scope="col">MobileNo</th>
                                  <th scope="col">EmailID</th> 
                                  <th scope="col">AadharNo</th>
                                  <th scope="col">PanNo</th>
                                  
                                  <th scope="col">Action</th>
                              </tr>
                          </thead>
                          <tbody>
                              {listdata.map((i, index) => (
                                  <tr key={index}>
                                      <th scope="row">{index+1}</th>
                               
                                      <td>{i.Name}</td>
                                      <td>{i.Mobilenumber}</td>
                                      <td>{i.Emailid}</td> 
                                      <td>{i.Aadhaarnumber}</td>
                                      <td>{i.Pannumber}</td>
                                  
                                  <td>
                                          <nav>

                                              <ol class="breadcrumb">
                                                  <li class="breadcrumb-item">
                                                      <Link to={'/Agentregistrationform'} state={{
                                                          ID: i.Mstagentregistrationid, Flag: 'U' }}>
                                                          <i class="bi bi-pencil-square" /> </Link> </li>
                                                  <li class="breadcrumb-item active">
                                                      <Link to={'/Agentregistrationform'} state={{
                                                          ID: i.Mstagentregistrationid, Flag: 'D' }}>
                                                          <i class="bi bi-trash" /></Link></li>
                                              </ol>
                                          </nav>
                                       
                                      </td>
                              </tr>
                              
                               ))}
                          </tbody>
                      </table>  

                  </div>
              </div>
</div>
    </div>

    </div>
  )
}
