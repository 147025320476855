import React, { useEffect, useState, Component } from 'react'
import Api from '../../Services/ApiCall'
import Base from '../../Base';
import Swal from 'sweetalert2';
import { Link, useLocation, useNavigate } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
// import { RollerShades } from '@mui/icons-material';
// import { getRoles } from '@testing-library/react';

export default function Staffgradetypeform() {
   
    const navigate= useNavigate();
    const[gradetype,setGradetype] =useState(""); 
    const [gradetype1, setGradetype1] = useState("");    
    const [designation, setdesignation] = useState(""); 
    const [usertype, setUsertype] = useState([]);
    const [selectedUsertype, setselectedUsertype] = useState("");
     const location = useLocation();
    
     const { ID,Flag } = location.state;
    // //console.log('%%%', ID, Flag);

    
     useEffect(() => {
        if (ID != 0) {
            // GetMenu(0)  
             GetUserType()
            Get(ID)
         
        }
        else { 
            //Get(0) 
            GetUserType()
        }
        

    }, []);


    
    async function GetUserType() {         
        var url = "/DropDown/GetRole?code=All";
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = []; 
        setUsertype(_response)
        ////console.log(_response,"GetRole")
    }

    async function IUDOperation(flag, id)
    {
       
       var Grade = gradetype;
       var Grade1 = gradetype1;
       var SelectUsertype=selectedUsertype;
       var Designation=designation;

       if(Grade ==""){
        toast.error('Please Enter grade Type')
        return false;
    }
    if(Grade1 ==""){
        toast.error('Please Enter Automatic base on grade type')
        return false;
    }
    if(Designation ==""){
        toast.error('Please Enter Designation')
        return false;
    }
    if(SelectUsertype ==""){
        toast.error('Please select Select User Type')
        return false;
    }
   
   
   var Param = 
   {
    "mststaffgradetypeid": ID,
    "staffgradetypecode": Grade,
    "selectgradetype": "",
    "automaticbaseedongradetype": Grade1, 
    "role" :SelectUsertype,
    "Designation":Designation,
    "flag": Flag,
  
    };

   var url = "/StaffGradeType/IUDStaffGradeType";
   let _response = await Api.IUD(Param, url);
   showAlert(_response);
   setTimeout(()=>{
    navigate('/staffgradetypetable')
   },2000)
  // //console.log(_response);

}
const showAlert = (_response) => {
    Swal.fire({
        title: "Success",
        text: _response,
        icon: "success",
        confirmButtonText: "OK",
    });
};

async function Get(id)
 {
   var Param = { Mststaffgradetypeid: ID }
   var url = "/StaffGradeType/BindStaffGradeType";
  
    let _response1 = await Api.BindGrid(Param, url)
  ////console.log("_response1",_response1)
    setGradetype(_response1[0].Staffgradetypecode);
   setGradetype1(_response1[0].Automaticbaseedongradetype);
   setselectedUsertype(_response1[0].Role);
   setdesignation(_response1[0].Designation);
//    setGradetype1(_response.Grade1/);

    ////console.log(_response1,"+++++++++++++");
}


  return (
    <div>
        <Toaster toastOptions={{ position: "top-right" }} />
         <Base  Staffgrade={true}/>
          <div id="main" class="main">
              <div class="pagetitle">
                  <h1>Tables</h1>
                  <nav>
                      <ol class="breadcrumb">
                          <li class="breadcrumb-item"><Link to="/dash">Home</Link></li>
                          <li class="breadcrumb-item">General Setting</li>
                          <li class="breadcrumb-item active">Staff Grade </li>
                      </ol>
                  </nav>
              </div>
             
              <div class="card">
                  <div class="card-body">
                      <h5 class="card-title">Staff Grade Type </h5>

              
                      <div class="row g-3">
                 
                          <div class="col-md-6">
                          <label for="grade">Grade</label>
                              <input type="text" class="form-control"  disabled={Flag == "D" ||  Flag=="U"||Flag == "null" ? true : false} placeholder="Grade Type"  value={gradetype} onChange={(e) => setGradetype(e.target.value)}/>
                          </div>
                          <div class="col-md-6">
                          <label for="gradetype">Grade Type</label>
                              <input type="text" class="form-control" disabled={Flag == "D" || Flag == "U" || Flag == "null" ? true : false} placeholder="Automatic Based on Grade type" value={gradetype1}  onChange={(e) => setGradetype1(e.target.value)}/>
                          </div>
                          <div class="col-md-6">
                          <label for="designation">Designation</label>
                              <input type="text" class="form-control"   disabled={Flag == "D" || Flag == "null" ? true : false}placeholder="Designation" value={designation} onChange={(e) => setdesignation(e.target.value)}/>
                          </div>
                         
                          <div class="col-md-6">
                          <label for="usertype">User Type</label>
                          <select id="inputState" class="form-select"   disabled={Flag == "D" || Flag == "null" ? true : false} value={selectedUsertype}  onChange={(e) => setselectedUsertype(e.target.value)}>
                                <option selected>User Type</option>
                                {usertype.map((i,index) =>(
                                    <option value={i.id}> {i.Text} </option>
                                ))}
                            </select>


                            {/* <select id="inputState" class="form-control"  value={usertype}  onChange={(e) => this.state.userType(e.target.value)}>
                                <option selected={(e) => e.target.value}>Uesr Type</option>
                                { usertype &&
                                usertype.map((i,index) =>(
                                    <option value={i.id}>{i.Text}</option>
                                ))}
                                
                            </select> */}
                        </div>

                         
                          <div class="text-center">
                              <button type="button" class="btn btn-primary" onClick={IUDOperation}>Submit</button>
                              <button type="reset" class="btn btn-info">
                              <Link to='/staffgradetypetable'>
                                Back
                                </Link>
                                </button>
                          </div>
                      </div>

                  </div>
              </div>

           
          </div>

    </div>
  )
}
