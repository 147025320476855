import React, { useState, useEffect } from 'react'
import Base from '../../Base';
import Api from '../../Services/ApiCall';
import { Link, useLocation, useNavigate } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import Swal from 'sweetalert2';

export default function Employerplancreationform() {

    const navigate = useNavigate();
    const location = useLocation();
    const { ID, Flag } = location.state;
    // //console.log('%%%', ID, Flag);

    useEffect(() => {
        if (ID != 0) {
            Get(ID);
        }
    }, []);

    const [plantype, setPlantype] = useState("");
    const [plandetail, setPlandetail] = useState("");
    const [amount, setAmount] = useState("");
    const [plandate, setPlandate] = useState("");
    const [tax, setTax] = useState("");



    async function IUDOperation(flag, id) {

        var Plantype = plantype;
        var Plandetail = plandetail;
        var Amount = amount;
        var Plandate = plandate;
        var Tax = tax;




        if (Plantype == "") {
            toast.error('Please enter  Plantype')
            return false;
        }


        if (Plandetail == "") {
            toast.error('Please enter Plandetail')
            return false;
        }
        if (Amount == "") {
            toast.error('Please enter Amount')
            return false;
        }

        var Param = {
            "mstemployerplancreationid": ID,
            "employerplancreationcode": Tax,
            "plantype": Plantype,
            "plandetail": Plandetail,
            "amount": Amount,
            "active": "",
            "searchField": Plandate,
            "flag": Flag,
        };
        var url = "/EmployerPlanCreation/IUDEmployerPlanCreation";
        let _response = await Api.IUD(Param, url);
        showAlert(_response);
        setTimeout(() => {
            navigate('/employerplancreationtable')
        }, 1000)
        // //console.log(_response);

    }
    const showAlert = (_response) => {
        Swal.fire({
            title: "Success",
            text: _response,
            icon: "success",
            confirmButtonText: "OK",
        });
    };


    async function Get(id) {
        var Param = { mstemployerplancreationid: id }
        var url = "/EmployerPlanCreation/BindEmployerPlanCreation";

        let _response = await Api.BindGrid(Param, url)


        setPlantype(_response[0].Plantype);
        await setPlandetail(_response[0].Plandetail);
        setAmount(_response[0].Amount);
        setPlandate(_response[0].SearchField);
        setTax(_response[0].Employerplancreationcode);
        ////console.log(_response);
    }




    return (
        <div>
            <Toaster toastOptions={{ position: "top-right" }} />
            <Base Employerplan={true} />
            <div id="main" class="main">
                <div class="pagetitle">
                    <nav>
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><Link to="/dash">Home</Link></li>
                            <li class="breadcrumb-item">Plan Configuration</li>
                            <li class="breadcrumb-item active">Employer & Jobseeker Plan</li>
                        </ol>
                    </nav>
                </div>
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">Employer & Jobseeker Plan Configuration</h5>
                        <div class="row g-3">
                            <div class="col-md-6">
                                <label for="planname">Plan Name</label>
                                <input type="text" class="form-control no-spinner" value={plantype} placeholder="Plan Name" onChange={(e) => setPlantype(e.target.value)} />
                            </div>
                            <div class="col-md-6">
                                <label for="plantype">Resume Download Amount</label>
                                <input type="number" class="form-control no-spinner" value={plandetail} placeholder="Resume Download Amount" onChange={(e) => setPlandetail(e.target.value)} />
                            </div>
                            <div class="col-md-6">
                                <label for="amount">Job Post Amount</label>
                                <input type="number" class="form-control no-spinner"  value={amount} placeholder="Job Post Amount" onChange={(e) => setAmount(e.target.value)} />
                            </div>
                            <div class="col-md-6">
                                <label for="apply">Job Apply Amount</label>
                                <input type="number" class="form-control no-spinner"  valuetax={tax} placeholder="Job Apply Amount" onChange={(e) => setTax(e.target.value)} />
                            </div>
                            <div class="text-center">
                                <button type="button" class="btn btn-primary" onClick={IUDOperation}>Submit</button>
                                <button type="reset" class="btn btn-info">
                                    <Link to='/employerplancreationtable'>
                                        Back
                                    </Link>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
