import React,{useState,useEffect} from 'react';
import Base from '../../Base';
import Api from '../../Services/ApiCall';
import Swal from 'sweetalert2';
import { Link , useLocation,useNavigate} from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';

export default function ReceiptPaymentForm() {
    const location = useLocation();
    const { ID,Flag } = location.state;
    const navigate = useNavigate();
    ////console.log('%%%', ID, Flag);
   
    useEffect(() => {
        if(ID!=0){
           
        Get(ID);
        }
     }, []);
    
    const [paymenttype, setPaymenttype] = useState("");
    const [amount, setAmount] = useState("");
    const [customerid, setCustomerid] = useState("");
    const [name, setName] = useState("");
    const [receiptdate, setReceiptdate] = useState("");
    const[paytype,setPaytype]=useState("");
    
   async function GetPhonenumber(e){

    var phone_number=e.target.value;
    setCustomerid(phone_number)
    if(phone_number.length>5){
    var Param ={Id:phone_number}
    var url = "/DropDown/GetJobEmpName?id="+phone_number;
    let _response = await Api.BindGrid(Param,url)
    setName(_response[0].Text1)
    }
   }


   async function IUDOperation(flag,id){
       var Paimenttype = paymenttype;
       var Amount= amount;
       var Paytype = paytype;
       var Receiptdate = receiptdate;
       var Name= name;
       var Customerid=customerid;
  
       if(Paimenttype ==""){
           toast.error('Please Choose Payment Type')
           return false;
       }  
      
       if(Customerid ==""){
           toast.error('Please enter Id')
           return false;
       } 
       if(Name ==""){
           toast.error('Please enter Name')
           return false;
       }
       if(Receiptdate ==""){
        toast.error('Please enter Transaction date')
        return false;
       }
       if(Paytype ==""){
        toast.error('Please Choose Pay Type ')
        return false;
       }
       if(Amount ==""){
        toast.error('Please Enter Amount ')
        return false;
       }
       var Param ={
        "mstreceipttableid": ID,
        "receipttablecode": "",
        "paytype": Paytype,
        "amount": Amount,
        "paymenttype": Paimenttype,
        "receiptdate": Receiptdate,
        "active": "",
        "searchField": "",
        "flag": Flag,
        "createdBy": 0,
        "createdDate": "2022-11-24T07:40:05.414Z",
        "customerid": Customerid,
        "name": Name
       };
       var url = "/ReceiptTable/IUDOfficeTypeCreation";
       let _response = await Api.IUD(Param,url);
       showAlert(_response)
       setTimeout(() => {
           navigate('/ReceiptPayment_table')
       }, 2000)
    }
       
    const showAlert = (_response) => {
        Swal.fire({
            title: "Success",
            text: _response,
            icon: "success",
            confirmButtonText: "OK",
        });
    };


async function Get(id){
    var Param ={Mstreceipttableid:id}
    var url = "/ReceiptTable/BindReceipt";
    ////console.log("Param",Param,"URL",url)
    let _response = await Api.BindGrid(Param,url) 
   
    setAmount(_response[0].Amount)
    setPaymenttype(_response[0].Paymenttype)
    setCustomerid(_response[0].Customerid)
    setName(_response[0].Name)
    setReceiptdate(_response[0].Receiptdate)
    setPaytype(_response[0].Paytype)
  
 }



  return (
      <div>
          <Toaster toastOptions={{ position: "top-right" }} />
        <Base Receipt={true} />
          <div id="main" class="main">
              <div class="pagetitle">
                  {/* <h1>Tables</h1> */}
                  <nav>
                      <ol class="breadcrumb">
                          <li class="breadcrumb-item"><Link to="/dash">Home</Link></li>
                          <li class="breadcrumb-item">Bank/Cash</li>
                          <li class="breadcrumb-item active">Receipt</li>
                      </ol>
                  </nav>
              </div>
             
              <div class="card">
                  <div class="card-body">
                      <h5 class="card-title">Receipt</h5>

              
                      <div class="row g-3">
                          <div class="col-md-6">
                          <label for="expenses">Payment Types</label>
                              <select id="inputState" class="form-select" value={paymenttype}  onChange={(e) => setPaymenttype(e.target.value)}>
                                  <option selected>Payment Type</option>
                                  <option>Cash</option>
                                  <option>Online</option>
                              </select>
                          </div>
                          <div class="col-md-6">
                          <label for="customerid">Phone Number</label>
                              <input type="number" class="form-control no-spinner"  value={customerid} placeholder="Phone number"  onChange={(e) => GetPhonenumber(e)}/>
                          </div>
                          <div class="col-md-6">
                          <label for="name">Name</label>
                              <input type="text" class="form-control"disabled={true} value={name} placeholder="Name"  onChange={(e) => setName(e.target.value)}/>
                          </div>
                          <div class="col-md-6">
                          <label for="date">Receipt Date</label>
                              <input type="date" max={new Date().toISOString().split("T")[0]} class="form-control" value={receiptdate} placeholder="Receipt Date"  onChange={(e) => setReceiptdate(e.target.value)}/>
                          </div>
                          <div class="col-6">
                          <label for="paytype">Pay Type </label>
                          <select id="inputState" class="form-select" value={paytype}  onChange={(e) => setPaytype(e.target.value)}>
                                  <option selected>Pay Type</option>
                                  <option>Advance</option>
                                  <option>Again As Bill</option>
                              </select>
                         </div>
                          <div class="col-md-6">
                          <label for="amount">Amount</label>
                              <input type="number" class="form-control no-spinner" value={amount} placeholder="Amount"  onChange={(e) => setAmount(e.target.value)}/>
                          </div>
                       
                         
                          <div class="text-center">
                              <button type="button" class="btn btn-primary" onClick={IUDOperation}>Submit</button>
                              <button type="reset" class="btn btn-info"><Link to="/ReceiptPayment_table">back</Link></button>
                          </div>
                      </div>

                  </div>
              </div>

           
          </div>
         
          
      
      
      </div>
  )
}
