import React, { useState, useEffect } from 'react'
import Base from './Base';
import { Link, useNavigate } from "react-router-dom";
import Api, { apiURL } from './Services/ApiCall';
import Swal from 'sweetalert2';
import { useLocation } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import { Button, Modal } from 'react-bootstrap';

export default function Bonusscreen() {


    const ID = 0;
    const Flag = "I";
    const [bonustype, setBonustype] = useState("");
    const [usertype, setUsertype] = useState("");
    const [user, setUser] = useState([]);
    const [usercode, setusercode] = useState("");
    const [descriptionList, setDescriptionList] = useState([]);
    const [amount, setAmount] = useState("");
    const [userText, setUserText] = useState("");
    const [popup, setpopup] = useState(false);
    const [remark, setRemark] = useState("");

    async function Getusertype(value) {
        setUsertype(value)
        var url = "/DropDown/GetUserWithType?usertype=" + value;
        let _response1 = await Api.BindDropdown(url);
        setUser(_response1);
    }

    const openPopup = () => {
        setpopup(true);
    }
    function closeModal2() {
        setpopup(false);
    }

    useEffect(() => {
        GetDescription()
        InvoiceGet()
    }, []);

    async function GetDescription() {
        var url = "/DropDown/GetMaster_name?mastername=Description";
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setDescriptionList(_response);
    }
    const [billnumber, setBillNumber] = useState("");
    async function InvoiceGet() {
        var id = 1;
        var url = "/DropDown/GetBillno?id=" + id;
        let _response = await Api.BindDropdown(url)
        setBillNumber(_response.Text1);
    }

    async function IUDOperation(Flag, ID) {
        var Amount = amount;

        if (Amount == "") {
            toast.error('Please enter Amount')
            return false;
        }
        if (usertype == "") {
            toast.error('Please enter usertype')
            return false;
        }
        if (remark == "") {
            toast.error('Please enter remark')
            return false;
        }
        if (amount == "") {
            toast.error('Please enter amount')
            return false;
        }
        var payload = {
            "mstpaymentid": 0,
            "paymentcode": billnumber,
            "paymentid": "Bonus",
            "paymenttype": "Bonus",
            "usertype": usertype,
            "userid": usercode,
            "approveusertype": sessionStorage.getItem("AccessType"),
            "approveuserid": sessionStorage.getItem("MUserID"),
            "plan": remark,
            "months": bonustype,
            "expiremonth": "",
            "amount": amount,
            "active": "",
            "searchField": userText,
            "createdBy": sessionStorage.getItem("MUserID"),
            "flag": "I"
        };

        var url = "/Payment/IUDPayment1";
        let _response = await Api.IUD(payload, url);
        showAlert(_response);
        closeModal2();
    }
    const showAlert = (_response) => {
        Swal.fire({
            title: "Success",
            text: _response,
            icon: "success",
            confirmButtonText: "OK",
        });
    };


    return (
        <div>
            <Toaster toastOptions={{ position: "top-right" }} />
            <Base />
            <div id="main" class="main">
                <div class="pagetitle">
                    <nav>
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><Link to="/dash">Home</Link></li>
                            <li class="breadcrumb-item">Registration</li>
                        </ol>
                    </nav>
                </div>

                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">Bonus Creation</h5>
                        <div class="row g-3">
                            <div class="col-md-6">
                                <label for="bonustype">Bonus Type</label>
                                <select class="form-select" id="bonustype" value={bonustype} onChange={(e) => setBonustype(e.target.value)}>
                                    <option value="">Select</option>
                                    <option value="Credit">Credit To / Bonus Given</option>
                                    <option value="Debit">Debit From / Collect Money</option>
                                </select>
                            </div>
                            <div class="col-md-6">
                                <label for="usertype">User Type</label>
                                <select class="form-select" id="usertype" value={usertype} onChange={(e) => Getusertype(e.target.value)}>
                                    <option value="">Select</option>
                                    <option value="Agent">Agent</option>
                                    <option value="Employer">Employer</option>
                                    <option value="Jobseeker">Jobseeker</option>
                                </select>
                            </div>

                            <div class="col-md-6">
                                <label for="officegrade">User Code</label>
                                <select id="inputState" class="form-select" value={usercode} onChange={(e) => {
                                    const selectedIndex = e.target.selectedIndex;
                                    const selectedOption = e.target.options[selectedIndex];
                                    setusercode(e.target.value);
                                    setUserText(selectedOption.text.split(" - ")[0]);
                                }}>
                                    <option selected>User Code</option>
                                    {user ? (
                                        user.map((i, index) => (
                                            <option value={i.id}>{i.Text} - {i.Text1}</option>
                                        ))) : (null)}
                                </select>
                            </div>

                            <div class="col-md-6">
                                <label for="amount">Amount</label>
                                <input type="number" class="form-control" id="Amount" value={amount} onChange={(e) => setAmount(e.target.value)} />
                            </div>

                            <div class="col-md-6">
                                <label for="Description">Description</label>
                                <select id="inputState" class="form-select" value={remark} onChange={(e) => setRemark(e.target.value)}>
                                    <option selected>Description</option>
                                    {descriptionList &&
                                        descriptionList.map((i, index) => (
                                            <option value={i.Text1}>{i.Text1}</option>
                                        ))}
                                </select>
                                {/* <textarea class="form-control"  value={remark} onChange={(e) => setRemark(e.target.value)}/> */}
                            </div>

                            <div class="text-center">
                                <button type="button" class="btn btn-primary" onClick={() => openPopup()}>Submit</button>
                                <button type="reset" class="btn btn-info">
                                    <Link to='/dash'>
                                        Back
                                    </Link>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <Modal show={popup} className='model-popup-design' style={{ marginBottom: "100px" }}>
                <Modal.Header classname="Userlgnmodalhead">
                    <Modal.Title>
                        Confirmation
                    </Modal.Title>
                    <button type="button" class="btn-close" data-dismis="modal" onClick={closeModal2} arial-label="Close"></button>
                </Modal.Header>
                <div className='loginmodalbody' style={{ width: '785px' }}>
                    <Modal.Body className='testing-test' >
                        <form>
                            <h5>Are you sure want to proceed?</h5>
                        </form>
                    </Modal.Body>
                </div>
                <Modal.Footer>
                    <Button variant="primary" onClick={IUDOperation}>
                        Yes
                    </Button>
                    <Button variant="secondary" onClick={() => closeModal2()}>
                        No
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
