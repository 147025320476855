import React, { useState, useEffect } from 'react'
import Base from '../../Base';
import Api from '../../Services/ApiCall';
import { Link, useLocation, useNavigate } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import Swal from 'sweetalert2';

export default function Tax_form() {
    const location = useLocation();
    const { ID, Flag } = location.state;


    useEffect(() => {

        if (ID != 0) {
            Get(ID)
        }
    }, []);
    const navigate = useNavigate();

    const [taxcode, setTaxcode] = useState("");
    const [taxamount, setTaxamount] = useState("");
    const [taxname, setTaxname] = useState("");
    const [taxpercent, setTaxpercent] = useState("");

    async function IUDOperation(flag, id) {
        
        var Param = {
            "mstnotificationid": ID,
            "notificationcode":taxcode,
            "title":taxname,
            "messages":taxpercent,
            "assignedBy":"taxamount",
            "active": "",
            "searchField": "",
            "flag": Flag,
                    }
        var url = "/Notification/IUDNotification";
        let _response = await Api.IUD(Param, url)
        showAlert(_response)
        setTimeout(() => {
            navigate('/tax')
        }, 2000)
    }
    const showAlert = (_response) => {
        Swal.fire({
            title: "Success",
            text: _response,
            icon: "success",
            confirmButtonText: "OK",
        });
    };


    async function Get(id) {
        var Param = { Mstnotificationid: id }
        var url = "/Notification/BindNotification";
        // //console.log("Param",Param,"URL",url)
        let _response = await Api.BindGrid(Param, url)

        setTaxcode(_response[0].Notificationcode)
        setTaxamount(_response[0].AssignedBy)
        setTaxname(_response[0].Title)
        setTaxpercent(_response[0].Messages)
        
    }

    return (
        <div>
            <Toaster toastOptions={{ position: "top-right" }} />
            <Base />
            <div id="main" class="main">
                <div class="pagetitle">

                    <nav>
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><Link to="/dash">Home</Link></li>
                            <li class="breadcrumb-item">Taxes</li>
                            <li class="breadcrumb-item active">Taxes Creation</li>
                        </ol>
                    </nav>
                </div>

                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">Taxes Creation</h5>


                        <div class="row g-3">
                            <div class="col-md-6">
                                <label for="hotaxcodeldername">Tax Code</label>
                                <input type="text" class="form-control" disabled={Flag == "D" || Flag == "null" ? true : false} value={taxcode} placeholder="Tax code" onChange={(e) => setTaxcode(e.target.value)} />
                            </div>
                            {/* <div class="col-6">
                                <label for="taxamount">Tax Amount</label>
                                <input type="text" class="form-control" disabled={Flag == "D" || Flag == "null" ? true : false} value={taxamount} placeholder="Tax amount" onChange={(e) => setTaxamount(e.target.value)} />
                            </div> */}
                            <div class="col-6">
                                <label for="taxname">Tax Name</label>
                                <input type="text" class="form-control" disabled={Flag == "D" || Flag == "null" ? true : false} value={taxname} placeholder="Tax name" onChange={(e) => setTaxname(e.target.value)} />
                            </div>
                            <div class="col-6">
                                <label for="texpercent">Tax Percent</label>
                                <input type="text" class="form-control" disabled={Flag == "D" || Flag == "null" ? true : false} value={taxpercent} placeholder="Tax percent" onChange={(e) => setTaxpercent(e.target.value)} />
                            </div>


                            <div class="text-center">
                                <button type="button" class="btn btn-primary" onClick={IUDOperation}>Submit</button>
                                <button type="reset" class="btn btn-info">
                                    <Link to='/tax'>
                                        Back
                                    </Link>
                                </button>
                            </div>
                        </div>

                    </div>
                </div>


            </div>
        </div>
    )
}
