import React from 'react'
import Base from '../../Base';
import { Link,useLocation,useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import Api from '../../Services/ApiCall'
import toast, { Toaster } from 'react-hot-toast';
import Swal from 'sweetalert2';

export default function Agentcommisioncreationform() {
    const navigate=useNavigate("");
    const location = useLocation();
    const { ID,Flag } = location.state;
    // //console.log('%%%', ID, Flag);

    useEffect(() => {
        if (ID != 0) {
            Get(ID);
        }
    }, []);



    const[creationdetail,setCreationdetail] =useState("");
    ////console.log(creationdetail,"uuuuuuuu")
    const [commission, setCommission] = useState("");
    const [packageupdate, setPackageupdate] = useState("");
    const [agentcommission, setAgent] = useState("");

    

    async function IUDOperation(flag, id)
    {
       
       var Creation = creationdetail;
       var Commission = commission;
       var Packageupdate = packageupdate;
       var Agent = agentcommission;
     

      

       if (Creation == "") {
           toast.error('Please select  Creation detail')
           return false;
       }
       

       if (Commission == "") {
           toast.error('Please select Commission')
           return false;
       }
       if (Packageupdate == "") {
        toast.error('Please select Packageupdate commission')
        return false;
    }
    if (Agent == "") {
        toast.error('Please select Agent Commission')
        return false;
    }
    

   
   var Param = { 
    "mstagentcommissioncreationid": ID,
    "agentcommissioncreationcode": "",
    "creationdetail": Creation,
    "commission": Commission,
    "packageupdatecommission": Packageupdate,
    "agentcommission": Agent,
    "active": "",
    "searchField": "",
    "flag": Flag,
    "maessage": "",
    "messageCode": "",
    "createdBy": 0,
    "createdDate": "2022-10-15T07:00:21.911Z"
   };
   var url = "/AgentCommission/IUDAgentCommission";
   let _response = await Api.IUD(Param, url);
  // //console.log(_response);
  showAlert(_response);
        setTimeout(() => {
            navigate('/agentcommisiontable')
        }, 2000)

}
const showAlert = (_response) => {
    Swal.fire({
        title: "Success",
        text: _response,
        icon: "success",
        confirmButtonText: "OK",
    });
};

async function Get(id) {
   var Param = { mstagentcommissioncreationid: id }
   var url = "/AgentCommission/BindAgentCommission";

   let _response = await Api.BindGrid(Param, url)

  
   setCreationdetail(_response[0].Creationdetail);
   setCommission(_response[0].Commission);
   setPackageupdate(_response[0].Packageupdatecommission);
   setAgent(_response[0].Agentcommission);
  // //console.log(_response);
}






  return (
    <div>
                <Toaster toastOptions={{ position: "top-right" }} />

         <Base Agentcommission={true}/>
          <div id="main" class="main">
              <div class="pagetitle">
                
                  <nav>
                      <ol class="breadcrumb">
                          <li class="breadcrumb-item"><Link to="/dash">Home</Link></li>
                          <li class="breadcrumb-item">Commision</li>
                          <li class="breadcrumb-item active">Agent Commission</li>
                      </ol>
                  </nav>
              </div>
             
              <div class="card">
                  <div class="card-body">
                      <h5 class="card-title">Agent Commision Creation</h5>

              
                      <div class="row g-3">
                      <div class="col-md-6">
                             <label for="creationdetails">Creation Details</label>
                              <input type="text" class="form-control" placeholder="Creation Details" disabled={Flag == "D" || Flag == "null" ? true : false} value={creationdetail} onChange={(e) => setCreationdetail(e.target.value)}></input>
                                  
                          </div>
                          <div class="col-md-6">
                            <label for="commission">Commission</label>
                              <input type="text" class="form-control"  placeholder=" commission" disabled={Flag == "D" || Flag == "null" ? true : false} value={commission} onChange={(e) => setCommission(e.target.value)}></input>
                                 
                            
                          </div>
                          <div class="col-md-6">
                          <label for="commission">Package Update Commission </label>
                              <input type="text" class="form-control"  placeholder="Package update Commission" disabled={Flag == "D" || Flag == "null" ? true : false} value={packageupdate} onChange={(e) => setPackageupdate(e.target.value)}></input>
                                 
                          </div>
                          <div class="col-md-6">
                          <label for="agentcommission">Agent Commission </label>
                              <input type="text" class="form-control"  placeholder="Agent Commission" disabled={Flag == "D" || Flag == "null" ? true : false} value={agentcommission} onChange={(e) => setAgent(e.target.value)}></input>
                                 
                          </div>
                          <div class="text-center">
                              <button type="button" class="btn btn-primary" onClick={IUDOperation}>Submit</button>
                              <button type="reset" class="btn btn-info">
                              <Link to='/agentcommisiontable'>
                                Back
                                </Link>
                                </button>
                          </div>
                      </div>

                  </div>
              </div>

           
          </div>


    </div>
  )
}
